import "./Login.css";
import "./Authenticate.css";

import React, { useEffect, useRef, useState, useContext } from "react";

import ArrowLeft from "../../assets/arrow-left.svg"
import { AxiosInstance } from "../../axios/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/Group 12@2x.png";
import Timer from "../../assets/stopwatch-fill.svg";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import { UserContext } from "../../context/UserDetails";

function Authenticate() {

  const { login } = useContext(UserContext);
  let navigate = useNavigate();
    const [context, setContext] = useState({
      otp: ""
    });
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);
  
  const textInputEmail = useRef(null);
  
   const [user, setUser] = useState([]);

   useEffect(() => {
     const userDetails = localStorage.getItem("user");
     if (userDetails) {
       setUser(JSON.parse(userDetails));
     } else {
       setUser("");
     }
   }, []);
  
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "input_otp") {
      setContext({ ...context, otp: value });
    }
    
  };
   
   const routeChange = () => {
      if (context.otp === "") {
        textInputEmail.current.focus();
      } else {
        let userData = {
          method: 1,
          otp: context.otp,
          email: user.email,//"pakeerathan.p@bharatclouds.com",
        };
        logiApi(userData);
      }
   };
  
   function logiApi(userData) {
     AxiosInstance.post("api/aam/user", userData)
       .then((res) => {
         console.log(res.data);
         if (res.data.status) {
            let from = `/dashboard`;
          //  navigate(path);
           Swal.fire({
             title: "Success",
             text: res.data.message,
             icon: "success",
             confirmButtonText: "Ok",
           }).then((result) => {
             if (result.isConfirmed) {
               login(localStorage.getItem("userDetails"), () => {
                 navigate(from, { replace: true });
               });
             }
           });
         }
         else { 
           alert(res.data.message);
         }
       })
       .catch((error) => {
         return error;
       });
   }
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);

    let userData = {
      method: 5,
      email: user.email, //user email
    };

    AxiosInstance.post("api/aam/user", userData)
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          let from = `/dashboard`;
          //  navigate(path);
          Swal.fire({
            title: "Success",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            
          });
        } else {
           Swal.fire({
             title: "Fail",
             text: res.data.message,
             icon: "fail",
             confirmButtonText: "Ok",
           }).then((result) => {});
        }
      })
      .catch((error) => {
        return error;
      });
  };
  
    return (
      // <div className="container">
      <div className="c_row">
        <div className="col-lg-4 col-md-12 left_banner">
          {/* <div className="left_banner"></div> */}
          <div className="right_img">
            <img className="right_img" src={Logo} alt="Banners" />
          </div>
          <div className="title_sec">
            <p className="title_banner">Start your </p>
            <p className="title_banner_sec">journey with us.</p>
            <p className="para_text">
              Explore automation solutions that can help you meet your goals.
              Automate your work and build something new.
            </p>
          </div>
        </div>
        <div className="col-lg-8 col-md-12">
          <div className="auth_parent">
            <div className="auth_parent_div">
              <a href="/login">
                <img src={ArrowLeft} />
              </a>
              <p className="auth_title">Authenticate Email Id</p>
            </div>
            <div className="auth_label_div">
              <p className="auth_label_email">{user.email}</p>
              <p className="auth_label_normal">
                The phone number authentication code has been sent to the phone
                number you provided. Please enter the code below.
              </p>
            </div>
            <div className="auth_div_labelText">
              <label for="fname" className="auth_labelText">
                Email Authentication Code
              </label>
              <div className="auth_sub_div">
                <input
                  ref={textInputEmail}
                  value={context.otp}
                  onChange={handleChange}
                  className="auth_InputText"
                  name="input_otp"
                  placeholder="Enter Email Authentication Code"
                />
                <div className="auth_sub_div_img">
                  {/* <FontAwesomeIcon icon={faStopwatch} size="2x" /> */}
                  <img src={Timer} />
                </div>
                <label>
                  {seconds > 0 || minutes > 0 ? (
                    <p>
                      {minutes < 10 ? `0${minutes}` : minutes}:
                      {seconds < 10 ? `0${seconds}` : seconds}
                    </p>
                  ) : (
                    <p>00:00</p>
                  )}
                </label>
              </div>
            </div>
            <div className="auth_sub_div">
              <p className="auth_sub_title">Didn't receive the code yet?</p>
              <button
                onClick={resendOTP}
                className="auth_sub_title_create"
                disabled={seconds > 0 || minutes > 0}
              >
                Resend code
              </button>
            </div>

            <div className="auth_submit_bg">
              <button className="auth_submit" onClick={routeChange}>
                Authenticate
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};
export default Authenticate;