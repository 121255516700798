import React, { useState,useEffect } from "react";

export const UserContext = React.createContext();


const UserDetailsContextProvider = (props) => {

  const [user, setUser] = useState([]);
    
  const userDetails = localStorage.getItem("userDetails");
  
  useEffect(() => {
    if (userDetails) {
      setUser(JSON.parse(userDetails));
    } else {
      setUser("");
    }
  }, []);

  const loginUser = (arg,callback) => {
    
    // localStorage.setItem("userDetails",  JSON.stringify(arg));
    localStorage.setItem("userDetails",arg);
    setUser(arg);
    callback();
  } 

  const logout = () => {
    localStorage.removeItem("userDetails");
    setUser("");
  }



return <UserContext.Provider value={{user:user,login:loginUser,logout:logout}}>
    
        {props.children}

     </UserContext.Provider>

}

export default UserDetailsContextProvider;
