import "./User.css";

import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AxiosInstance } from '../../axios';

export default function ViewUser() {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleEditView = (id) => {

        console.log(id,"------------");
        navigate(`/dashboard/EditUser/${id}`);

    }
    // console.log(keyword,"*********");
    // console.log(id, "*******");

    const [formData, setFormData] = useState({
        username: '',
        mobile_no: '',
        email: '',
        company_name: '',
        name: '',
        fullname: '',
        is_validated: ''

    });

    console.log(formData, "Menu_list");
    useEffect(() => {
        const bb = {
            "method": 9,
            "id": id
        };
        // console.log(bb,"bbbbbbbbbbbbbb");
        AxiosInstance.post("api/aam/user", bb)
            .then((res) => {
                // console.log(res);    
                if (res.data.status) {
                    setFormData({
                        username: res.data.data[0].username,
                        mobile_no: res.data.data[0].mobile_no,
                        email: res.data.data[0].email,
                        company_name: res.data.data[0].company_name,
                        name: res.data.data[0].name,
                        fullname: res.data.data[0].fullname,
                        is_validated: res.data.data[0].isActive
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id]);



    const navigateToPreviousScreen = () => {
        navigate(-1); // Navigate back by one step in the history
    };

    return (
        <>
            <div className="bodycontent">
                <div className="ur_div">
                    <p className="back_button" onClick={navigateToPreviousScreen}>
                        <span className="material-icons">keyboard_backspace</span>Back to
                        Users
                    </p>
                </div>
                <div className="cld_div justify-content-between">
                    <div><p className="topic_title">
                        {"View User"}
                    </p></div>
                    <div className="d-flex justify-content-end align-item-center">
                        <button
                            className="back_buttons  me-3" onClick={() => handleEditView(id)}>
                            <span className="material-icons me-2">mode_edit</span>
                            <Link to='javascript:void(0)'>Edit</Link>
                        </button>
                    </div>
                </div>
                <div className="ur_content">
                    <div className="row">
                        <div className="col-md-6 inp_grp">
                            <label for="input_name" className="ur_labelText">
                                User Name
                            </label>
                            <label className="ur_InputText">{formData.username}</label>
                        </div>
                        <div className="col-md-6 inp_grp">
                            <label for="input_web" className="ur_labelText">
                                Phone Number
                            </label>
                            <label className="ur_InputText">{formData.mobile_no}</label>
                        </div>
                        <div className="col-md-6 inp_grp">
                            <label for="input_web" className="ur_labelText">
                                Email id
                            </label>
                            <label className="ur_InputText">{formData.email}</label>
                        </div>
                        <div className="col-md-6 inp_grp">
                            <label for="input_web" className="ur_labelText">
                                Organisation Name
                            </label>
                            <label className="ur_InputText">{formData.company_name}</label>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 inp_grp">
                            <label for="input_des" className="ur_labelText">
                                Role
                            </label>
                            <label className="ur_InputText">{formData.name}</label>
                        </div>
                        <div className="col-md-6 inp_grp">
                            <label for="input_des" className="ur_labelText">
                                Added By
                            </label>
                            <label className="ur_InputText">{formData.fullname}</label>
                        </div>
                        <div className="col-md-6 inp_grp">
                            <label for="input_des" className="ur_labelText">
                                Status
                            </label>
                            <label className="ur_InputText">{(formData.is_validated === 1 ? "Active" : "Inactive")}</label>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
