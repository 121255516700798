import "./Login.css";
import "./ResetPassword.css";

import React, { useEffect, useRef, useState } from "react";

import { AxiosInstance } from "../../axios/index";
import Logo from "../../assets/Group 12@2x.png";
import img_eye from "../../assets/eye-fill.svg";
import img_eye_slash from "../../assets/eye-slash-fill.svg";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";

function ResetPassword() {
  const { id } = useParams();
  const [user, setUser] = useState([]);
  
  let navigate = useNavigate();
  const [context, setContext] = useState({
    new_pass: "",
    confirm_pass: "",
    eye_new: false,
    eye_confirm: false,
  });
  const textInputNewPass = useRef(null);
  const textInputConfirmPass = useRef(null);

   useEffect(() => {
     console.log("userid ", id);
     getUser({ method: 6, user_id: id });
   }, []);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "new_password") {
      setContext({ ...context, new_pass: value });
    } else if (name === "confirm_password") {
      setContext({ ...context, confirm_pass: value });
    }
  };

  const routeChange = () => {
    if (context.new_pass === "") {
      textInputNewPass.current.focus();
    } else if (context.confirm_pass === "") {
      textInputConfirmPass.current.focus();
    } else if (context.confirm_pass !== context.new_pass) {
      textInputConfirmPass.current.focus();
    } else {
      let userData = {
        method: 4,
        user_id: "pakeerathan.p@bharatclouds.com",
        password: context.new_pass,
      };
      logiApi(userData);
    }
  };

  // {
  //   "status": true,
  //   "message": "User data",
  //   "data": {
  //     "email": "jeevapriya.v@bharatclouds.com",
  //     "user_id": "6704e4045c4822e81a7b5f4a01e2de47"
  //   }
  // }

  function logiApi(userData) {
    AxiosInstance.post("api/aam/user", userData)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          let path = `/login`;
          navigate(path);
        } else {
          textInputNewPass.current.focus();
        }
      })
      .catch((error) => {
        return error;
      });
  }

   function getUser(userData) {
     AxiosInstance.post("api/aam/user", userData)
       .then((res) => {
         console.log(res);
         if (res.data.status) {
           setUser(res.data.data);
         } else {
           
         }
       })
       .catch((error) => {
         return error;
       });
   }
  const eyeChange = () => {
    if (context.eye_new) {
      setContext({ ...context, eye_new: false });
    } else {
      setContext({ ...context, eye_new: true });
    }
  };
  const eyeChangeConfirm = () => {
    if (context.eye_confirm) {
      setContext({ ...context, eye_confirm: false });
    } else {
      setContext({ ...context, eye_confirm: true });
    }
  };

  return (
    // <div className="container">
    <div className="c_row">
      <div className="col-lg-4 col-md-12 left_banner">
        {/* <div className="left_banner"></div> */}
        <div className="right_img">
          <img className="right_img" src={Logo} alt="Banners" />
        </div>
        <div className="title_sec">
          <p className="title_banner">Start your </p>
          <p className="title_banner_sec">journey with us.</p>
          <p className="para_text">
            Explore automation solutions that can help you meet your goals.
            Automate your work and build something new.
          </p>
        </div>
      </div>
      <div className="col-lg-8 col-md-12">
        <div className="rp_parent">
          <p className="rp_title">Reset password</p>
          <div className="rp_sub_div">
            <p className="rp_sub_title">{user !== undefined ? user.email: ""}</p>
          </div>
          <div className="row">
            <div className="">
              <label for="fname" className="rp_labelText">
                New Password
              </label>
              <input
                ref={textInputNewPass}
                value={context.new_pass}
                onChange={handleChange}
                type={context.eye_new ? "text" : "password"}
                className="rp_InputText"
                name="new_password"
                placeholder="Enter New Password"
              />
              {context.eye_new === true ? (
                <img
                  className="lg_password_eye"
                  src={img_eye_slash}
                  onClick={eyeChange}
                />
              ) : (
                <img
                  className="lg_password_eye"
                  src={img_eye}
                  onClick={eyeChange}
                />
              )}
            </div>
          </div>
          <div className="row">
            <div className="">
              <label for="lname" className="rp_labelText">
                Confirm Password
              </label>
              <input
                ref={textInputConfirmPass}
                value={context.confirm_pass}
                onChange={handleChange}
                type={context.eye_confirm ? "text" : "password"}
                className="rp_InputText"
                name="confirm_password"
                placeholder="Confirm Password"
              />
              {context.eye_confirm === true ? (
                <img
                  className="lg_password_eye"
                  src={img_eye_slash}
                  onClick={eyeChangeConfirm}
                />
              ) : (
                <img
                  className="lg_password_eye"
                  src={img_eye}
                  onClick={eyeChangeConfirm}
                />
              )}
            </div>
          </div>
          <div className="rp_submit_bg">
            <button className="rp_submit" onClick={routeChange}>
              Reset Password
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ResetPassword;