import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../axios";
import download from "../../assets/download.png";
import Rating from '../../utils/Rating';

export default function ViewScriptDetail() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [activeTab, setActiveTab] = useState("ScriptOverview");

  // Function to handle tab click
  const handleTabClick = (tabId) => {
    setActiveTab(tabId);
  };

  const [formData, setFormData] = useState({
    script_name: '',
    cloud: '',
    provisioner: '',
    category: '',
    sub_category: '',
    service_type: '',
    script_download_url: '',
    script_version: '',
    description: '',
    no_of_downloads: '',
    rating: ''

});

console.log(formData, "Menu_list");
useEffect(() => {
    const bb = {
        "method": 2,
        "id": id
    };
    // console.log(bb,"bbbbbbbbbbbbbb");
    AxiosInstance.post("api/aam/script", bb)
        .then((res) => {
            // console.log(res);    
            if (res.data.status) {
                setFormData({
                    script_name: res.data.data[0].script_name,
                    cloud: res.data.data[0].cloud,
                    provisioner: res.data.data[0].provisioner,
                    category: res.data.data[0].category,
                    sub_category: res.data.data[0].sub_category,
                    service_type: res.data.data[0].service_type,
                    script_download_url: res.data.data[0].script_download_url,
                    script_version: res.data.data[0].script_version,
                    description: res.data.data[0].description,
                    no_of_downloads: res.data.data[0].no_of_downloads,
                    rating: res.data.data[0].rating
                });
            }
        })
        .catch((error) => {
            console.error(error);
        });
}, [id]);


  // console.log(keyword);

  // keyword === 0 - cloud | 1 - Provisioner | 2- Category | 3 - SubCategory

  // const [formData, setFormData] = useState({
  //     name :'',
  //     version : '',
  //     desc:'',
  //     website : ''
  // });

  // useEffect(() => {
  //  const  bb = {
  //   "method":2,
  //   "id":id
  //   };

  // AxiosInstance.post("api/aam/master/code", bb)
  // .then((res) => {
  //   console.log(res);
  //   if (res.data.status) {
  //     setFormData({
  //         name: res.data.data[0].code_name,
  //         version: res.data.data[0].version,
  //         desc: res.data.data[0].description,
  //         website: res.data.data[0].website,
  //       });
  //   }r
  // })
  // .catch((error) => {
  //   return error;
  // });
  // }, [id]);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const handleEditView = (ids) => {
    console.log(ids);
    navigate(`/dashboard/addscript/${ids}`);
  };

  return (
    <div className="bodycontent">
      <div className="cld_div">
        <p className="back_button mt-2" onClick={navigateToPreviousScreen}>
          <span className="material-icons">keyboard_backspace</span>
          Back to script
        </p>
        <div className="d-flex justify-content-end align-item-center">
          <button
            className="back_buttons  me-3"
            onClick={() => handleEditView(id)}
          >
            <span className="material-icons me-2">mode_edit</span>
            <Link to="javascript:void(0)">Edit</Link>
          </button>
          <button className="back_buttonss  me-3">
            <img src={download} alt="download" />
            <Link to="javascript:void(0)" className="ms-2">
              Download
            </Link>
          </button>
          <button className="back_de  me-3">
            <span className="material-icons ms-2">exit_to_app</span>
            <Link to="javascript:void(0)" className="ms-2">
              Deploy
            </Link>
          </button>
        </div>
      </div>
      {/* <div className="cld_div justify-content-between">
      <h3 className="title_tag">View {keyword == 0  && "Cloud"}
        { keyword == 1 && "Provisioner"}
        { keyword == 2 && "Category"}
        { keyword == 3 && "SubCategory"}
        {keyword == 4 && "ServiceType"}</h3>
      
    </div> */}
      <div className="container-fluid mt-5 p-0">
        <div className="row">
          <div className="col-md-12">
            <div class="MainContent">
              <ul
                id="myTabs"
                class="nav nav-pills nav-justified"
                role="tablist"
                data-tabs="tabs"
              >
                <li
                  className={` ${
                    activeTab === "ScriptOverview" ? "Items" : "Itemss"
                  }`}
                >
                  <a
                    href="#ScriptOverview"
                    data-toggle="tab"
                    onClick={() => handleTabClick("ScriptOverview")}
                  >
                    Script Overview
                  </a>
                </li>
                <li
                  className={` ${
                    activeTab === "ReleaseNote" ? "Items" : "Itemss"
                  }`}
                >
                  <a
                    href="#ReleaseNote"
                    data-toggle="tab"
                    onClick={() => handleTabClick("ReleaseNote")}
                  >
                    Release Note
                  </a>
                </li>
                <li
                  className={` ${
                    activeTab === "VideoTutorial" ? "Items" : "Itemss"
                  }`}
                >
                  <a
                    href="#VideoTutorial"
                    data-toggle="tab"
                    onClick={() => handleTabClick("VideoTutorial")}
                  >
                    Video Tutorial
                  </a>
                </li>
                <li
                  className={` ${
                    activeTab === "FaqQuestion" ? "Items" : "Itemss"
                  }`}
                >
                  <a
                    href="#FaqQuestion"
                    data-toggle="tab"
                    onClick={() => handleTabClick("FaqQuestion")}
                  >
                    FAQ
                  </a>
                </li>
                <li
                  className={` ${
                    activeTab === "ForumQues" ? "Items" : "Itemss"
                  }`}
                >
                  <a
                    href="#ForumQues"
                    data-toggle="tab"
                    onClick={() => handleTabClick("ForumQues")}
                  >
                    Forum
                  </a>
                </li>
                <li
                  className={` ${activeTab === "Q&AQues" ? "Items" : "Itemss"}`}
                >
                  <a
                    href="#Q&AQues"
                    data-toggle="tab"
                    onClick={() => handleTabClick("Q&AQues")}
                  >
                    Q&A
                  </a>
                </li>
                <li
                  className={` ${
                    activeTab === "ReView" ? "Items_oo" : "Itemss"
                  }`}
                >
                  <a
                    href="#ReView"
                    data-toggle="tab"
                    onClick={() => handleTabClick("ReView")}
                  >
                    Reviews
                  </a>
                </li>
              </ul>
              <div class="tab-content">
                <div
                  role="tabpanel"
                  className={` tab-pane  ${
                    activeTab === "ScriptOverview" ? "active" : ""
                  }`}
                  id="ScriptOverview"
                >
                  <div className="container mt-4 ms-0">
                    <div className="row">
                      <div className="col-md-3">
                        <label className="ReviewLabels">Script Name</label>
                        <span className="ReviewLabel">{formData.script_name}</span>
                      </div>

                      <div className="col-md-3">
                        <label className="ReviewLabels">Cloud</label>
                        <span className="ReviewLabel">{formData.cloud}</span>
                      </div>
                      <div className="col-md-3">
                        <label className="ReviewLabels">Provisioner</label>
                        <span className="ReviewLabel">{formData.provisioner}</span>
                      </div>
                      <div className="col-md-3">
                        <label className="ReviewLabels">Category</label>
                        <span className="ReviewLabel">{formData.category}</span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-3">
                        <label className="ReviewLabels">Sub Category</label>
                        <span className="ReviewLabel">{formData.sub_category}</span>
                      </div>

                      <div className="col-md-3">
                        <label className="ReviewLabels">Service Type</label>
                        <span className="ReviewLabel">{formData.service_type}</span>
                      </div>
                      <div className="col-md-3">
                        <label className="ReviewLabels">Versions</label>
                        <span className="ReviewLabel">{formData.script_version}</span>
                      </div>
                      <div className="col-md-3">
                        <label className="ReviewLabels">
                          Number of Downloads
                        </label>
                        <span className="ReviewLabel">{formData.no_of_downloads}</span>
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-6">
                        <label className="ReviewLabels">Rating</label>
                        {/* <span className="ReviewLabel">★★★★★</span> */}
                        <Rating rating={formData.rating} />
                      </div>
                    </div>
                    <div className="row mt-3">
                      <div className="col-md-12">
                        <label className="ReviewLabels">Description</label>
                        <span className="ReviewLabel">
                          {/* Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results. */}
                          {formData.description}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  role="tabpanel"
                  className={` tab-pane  ${
                    activeTab === "ReleaseNote" ? "active" : ""
                  }`}
                  id="ReleaseNote"
                >
                  <div className="container mt-4 ms-0">
                    <div className="row">
                      <div className="col-md-12">
                        <label className="ReviewLabels">Introduction</label>
                        <p className="ReviewLabel mb-2 mt-2">
                          Always Automate 8.7 is now available.
                        </p>
                        <p className="ReviewLabel mb-2 mt-2">
                          Always Automate 8.7 is now available.
                        </p>
                        <p className="ReviewLabel mb-2 mt-2">
                          Always Automate 8.7 is now available.
                        </p>
                      </div>
                      <div className="col-md-12 mt-3">
                        <label className="ReviewLabels">New Features</label>
                        <ul className="bib mt-1 p-1">
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12 mt-3">
                        <label className="ReviewLabels">
                          Bugs and Enhancements
                        </label>
                        <ul className="bib mt-1 p-1">
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                        </ul>
                      </div>

                      <div className="col-md-12 mt-3">
                        <label className="ReviewLabels">Limitations</label>
                        <ul className="bib mt-1 p-1">
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                          <li className="ReviewLabel mb-4 mt-3">
                            <span className="material-icons">
                              fiber_manual_record
                            </span>{" "}
                            Enhance the Windows Agent file scanner to be more
                            comparable to competitive products. The aim is to
                            have the ability to enable scanning (indexing) items
                            within the Recycle Bin and also the hidden folders
                            that exist within the restrictions of the PCS file..
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="tabpanel"
                  className={` tab-pane  ${
                    activeTab === "VideoTutorial" ? "active" : ""
                  }`}
                  id="VideoTutorial"
                >
                  <div className="container-fluid mt-4 ms-0">
                    <div className="row">
                      <div className="col-md-4">
                        <img
                          className="viedoImg"
                          src="https://fiverr-res.cloudinary.com/images/t_main1,q_auto,f_auto,q_auto,f_auto/gigs2/282951334/original/a7a38c81ab293aaa215561e5b2fc0a3af717b816/make-view-boosting-youtube-thumbnail.png"
                          alt="image"
                        />
                      </div>
                      <div className="col-md-8">
                        <label className="ReviewLabels">
                          Tutorial of script
                        </label>
                        <p className="viedoPara mb-2 mt-2">
                          Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results..
                        </p>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
                <div
                  role="tabpanel"
                  className={` tab-pane  ${
                    activeTab === "FaqQuestion" ? "active" : ""
                  }`}
                  id="FaqQuestion"
                >
                  <div className="container-fluid mt-4 ms-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div class="input-group">
                          <div class="form-outline">
                            <input
                              type="text"
                              id="inputSearch"
                              class="inputSearch"
                              placeholder="Search here"
                            />
                          </div>
                          <button type="button" class="btn ">
                            <span className="material-icons">search</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <label className="title_faq">
                          Can I cancel my EMI after I've placed the order using
                          the Bajaj Finserv payment option?
                        </label>
                        <p className="viedoPara mb-2 mt-2">
                          Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results..
                        </p>
                      </div>
                      <div className="col-md-12 mt-4">
                        <label className="title_faq">
                          Can I cancel my EMI after I've placed the order using
                          the Bajaj Finserv payment option?
                        </label>
                        <p className="viedoPara mb-2 mt-2">
                          Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="tabpanel"
                  className={` tab-pane  ${
                    activeTab === "ForumQues" ? "active" : ""
                  }`}
                  id="ForumQues"
                >
                  <div className="container-fluid mt-4 ms-0">
                    <div className="row">
                      <div className="col-md-12 d-flex">
                        <img
                          src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                          className="avartar_img"
                          alt="avatar"
                        />
                        <div className="ms-4">
                          <span className="img_title">Pilvi Systems inc.</span>
                          <p>★★★★</p>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <p className="viedoPara mb-2 mt-2">
                          Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results..
                        </p>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
                <div
                  role="tabpanel"
                  className={` tab-pane  ${
                    activeTab === "Q&AQues" ? "active" : ""
                  }`}
                  id="Q&AQues"
                >
                  <div className="container-fluid mt-4 ms-0">
                    <div className="row">
                      <div className="col-md-12">
                        <div class="input-group">
                          <div class="form-outline">
                            <input
                              type="text"
                              id="inputSearch"
                              class="inputSearch"
                              placeholder="Search here"
                            />
                          </div>
                          <button type="button" class="btn ">
                            <span className="material-icons">search</span>
                          </button>
                        </div>
                      </div>
                      <div className="col-md-12 mt-4">
                        <label className="title_faq">
                          Can I cancel my EMI after I've placed the order using
                          the Bajaj Finserv payment option?
                        </label>
                        <p className="viedoPara mb-2 mt-2">
                          Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results..
                        </p>
                      </div>
                      <div className="col-md-12 mt-4">
                        <label className="title_faq">
                          Can I cancel my EMI after I've placed the order using
                          the Bajaj Finserv payment option?
                        </label>
                        <p className="viedoPara mb-2 mt-2">
                          Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results..
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  role="tabpanel"
                  className={` tab-pane  ${
                    activeTab === "ReView" ? "active" : ""
                  }`}
                  id="ReView"
                >
                 <div className="container-fluid mt-4 ms-0">
                    <div className="row">
                      <div className="col-md-12 d-flex">
                        <img
                          src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper-thumbnail.png"
                          className="avartar_img"
                          alt="avatar"
                        />
                        <div className="ms-4">
                          <span className="img_title">Pilvi Systems inc.</span>
                          <p>★★★★</p>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3">
                        <p className="viedoPara mb-2 mt-2">
                          Sample description with a customer-centric approach,
                          we strive to understand the unique needs and
                          challenges of each client. Our collaborative process
                          ensures that we create tailored automation solutions
                          that align with your business goals and objectives.
                          Whether it's automating data entry, optimizing supply
                          chain processes, or enhancing customer interactions,
                          we provide comprehensive and scalable automation
                          solutions that drive tangible results..
                        </p>
                      </div>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
