import "./SubCategory.css";

import React, { useEffect, useRef, useState } from "react";

import { AxiosInstance } from "../../axios/index";
import { Link } from "react-router-dom";
import Logo from "../../assets/Group 12@2x.png";
import SearchIcon from "@mui/icons-material/Search";
import add from "../../assets/add.png";
import tbl_view from "../../assets/table_view.svg";
import { useNavigate } from "react-router-dom";

function SubCategory() {
  let navigate = useNavigate();

  const [cloud_list, setcloud_list] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const [context, setContext] = useState({
    name: "",
    des: "",
    web: "",
    create_page: false,
    bt_name: "Add",
  });

  const textInputName = useRef(null);
  const textInputDes = useRef(null);
  const textInputWeb = useRef(null);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "input_name") {
      setContext({ ...context, name: value });
    } else if (name === "input_des") {
      setContext({ ...context, des: value });
    } else if (name === "input_web") {
      setContext({ ...context, web: value });
    }
  };

  const routeChange = () => {
    if (context.name === "") {
      textInputName.current.focus();
    } else if (context.des === "") {
      textInputDes.current.focus();
    } else if (context.web === "") {
      textInputWeb.current.focus();
    } else {
      let userData = {
        method: 0,
        cloud_name: context.name,
        cloud_des: context.des,
        cloud_web: context.web,
      };
      console.log("Passed Data: ", userData);
      logiApi(userData);
    }
  };
   const pageChange = () => {
     const path = "/dashboard/subcategory_detail";
     navigate(path);
   };

  function logiApi(userData) {
    AxiosInstance.post("api/aam/user", userData)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          localStorage.setItem("user", JSON.stringify(userData));
          let path = `/verify`;
          navigate(path);
        } else {
          textInputName.current.focus();
        }
      })
      .catch((error) => {
        return error;
      });
  }

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      let val = event.target.value;
      setSearchTerm(val); // Update the search term state
      getCloudList(); // Fetch user list based on the search term
    }
  };
  //sorting function
  const handleSort = (sortKey) => {
    const sortedList = [...cloud_list];
    sortedList.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });
  
    setcloud_list(sortedList);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };
  const handleFilter = (e, boxName) => {
    // if (boxName === "status") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     status: !prevState.status,
    //   }));
    // } else if (boxName === "category") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     category: !prevState.category,
    //   }));
    // }
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

useEffect(() => {
  getCloudList();
}, [currentPage, itemsPerPage]);

const handlePageCount = (event) => {
  const newItemsPerPage = parseInt(event.target.value);
  setItemsPerPage(newItemsPerPage);
  console.log(newItemsPerPage, "***************");
  setCurrentPage(1);
};

function getCloudList() {
  const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
  const data = {
    method: 0, //Mandatory
    group_name: "Sub Category",
    search:searchTerm
  };
  AxiosInstance.post("api/aam/master/code", data)
    .then((res) => {
      console.log(res);
      if (res.data.status) {
        const dataList = res.data.data.slice(startIndex, endIndex);
        setcloud_list(dataList);
        setTotalRecords(res.data.data.length);
      } else {
        setcloud_list(null);
      }
    })
    .catch((error) => {
      return error;
    });
}

const handleView = (id) => {
  console.log(id,"id");
// sub category view detail -- 3  keyword 
  navigate(`/dashboard/ViewDetail/${id}/${3}`);
}

  return (
    <>
      <div className="scgr_bodycontent">
        <div className="scgr_div">
          <p className="topic_title">SubCategory</p>
        </div>
        <div className="table_parent_div">
          <div className="row">
            <div className="col-md-8">
              <div className="scgr_table_serach">
                <input
                  type="text"
                  placeholder="Search by name"
                  onKeyDown={handleSearch}
                />
                <SearchIcon className="scgr_table_serach_img" />
              </div>
            </div>
            <div className="col-md-4 d-flex justify-content-end">
              <button className="add_new me-3" onClick={pageChange}>
                <img src={add} alt="add new" />
                <span>Add New</span>
              </button>
            </div>
          </div>
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12 table-responsive">
              <table className="table table-striped  table-hover" id="script">
                <thead>
                  <tr>
                    <th id="th">
                    SubCategory Name
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("code_name")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th" style={{ width: "60%" }}>
                      Description
                    </th>
                    <th id="th">Website</th>
                  </tr>
                </thead>
                <tbody>
                  {cloud_list &&
                    cloud_list.map((obj, index) => {
                      return (
                        <tr className="td_div_action">
                          <td>{obj.code_name}</td>
                          <td>{obj.description}</td>
                          <td>{obj.website}</td>
                          <td>
                          <div className="td_div_action_div">
                            <a href="javascript:void(0)" className="td_div_action_a">
                              <img onClick={() => handleView(obj.id)} src={tbl_view} />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12">
              <div className="pag w-100 d-flex justify-content-end">
                <span className="ipage">Items Per page:</span>
                <select
                name="pageCount"
                id="pageCount"
                defaultValue={itemsPerPage}
                onChange={handlePageCount}
                className="ms-2 select_drop"
              >
                {/* <option value="5">5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {totalRecords === 0
                  ? "No records found"
                  : `${Math.min(
                    currentPage * itemsPerPage - itemsPerPage + 1,
                    totalRecords
                  )} - ${Math.min(
                    currentPage * itemsPerPage,
                    totalRecords
                  )} of ${totalRecords}`}
              </span>
              <button className="prev_button ms-2 me-2" onClick={handlePrev} disabled={currentPage === 1}>
                <span class="material-icons">chevron_left</span>
              </button>
              <button className="prev_button" onClick={handleNext} disabled={currentPage === totalPages}>
                <span class="material-icons">chevron_right</span>
              </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default SubCategory;
