import "./User.css";

import React, { useEffect, useRef, useState } from "react";
import Footer from "../../hooks/Footer";
import Header from "../../hooks/Header";

import { AxiosInstance } from "../../axios/index";
import Logo from "../../assets/Group 12@2x.png";
import { useFetcher, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";

function AddUser() {
  let navigate = useNavigate();

  // const { id } = useParams();
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  let usertype = null;
  let tennet_id = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user.data && user.data.user_id) {
      userid = user.data.user_id;
    }
    if (user.data && user.data.user_type) {
      usertype = user.data.user_type;
    }
    if (user.data && user.data.tennet_id) {
      tennet_id = user.data.tennet_id;
    }
  } else {
    console.log("UserId Not available");
  }
  const [company_name, setcompany_name] = useState([]);
  const [company_names, setcompany_names] = useState([]);
  const [role, setname] = useState([]);

  const [context, setContext] = useState({
    username: "",
    email: "",
    mobile_no: "",
    company_name: "",
    role: "",
    is_validated: "",
    // create_page: true,
    // bt_name: "Add",
  });

  useEffect(() => {
    AxiosInstance.post("api/aam/account", {
      "method": 2
    })
      .then((res) => {
        let company_name = res.data.data.map((item, index) => (
          <option key={index} value={item.company_name}>
            {item.company_name}
          </option>
        ));
        setcompany_name(company_name);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    AxiosInstance.post("api/aam/account", {
      "method": 3,
      "tennet_id": tennet_id
    })
      .then((res) => {
        let company_name = res.data.data.map((item, index) => (
          <option key={index} value={item.company_name}>
            {item.company_name}
          </option>
        ));
        setcompany_names(company_name);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    AxiosInstance.post("api/aam/master/role", {
      "method": 5,
    })
      .then((res) => {
        let role = res.data.data.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        ));
        setname(role);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const textInputName = useRef(null);
  const textInputEmail = useRef(null);
  const textInputmobile_no = useRef(null);
  const textInputrole = useRef(null);
  const textInputcompany_name = useRef(null);
  const textInputcompany_names = useRef(null);
  const textInputis_validated = useRef(null);


  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "input_name") {
      setContext({ ...context, username: value });
    } else if (name === "input_mobileno") {
      setContext({ ...context, mobile_no: value });
    }
    else if (name === "input_email") {
      setContext({ ...context, email: value });
    }
    else if (name === "company_name") {
      setContext({ ...context, company_name: value });
    }
    else if (name === "name") {
      setContext({ ...context, role: value });
    }
    else if (name === "is_validated") {
      setContext({ ...context, is_validated: value });
    }
  };

  const handleChanges = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "input_name") {
      setContext({ ...context, username: value });
    } else if (name === "input_mobileno") {
      setContext({ ...context, mobile_no: value });
    }
    else if (name === "input_email") {
      setContext({ ...context, email: value });
    }
    else if (name === "company_names") {
      setContext({ ...context, company_name: value });
    }
    else if (name === "name") {
      setContext({ ...context, role: value });
    }
    else if (name === "is_validated") {
      setContext({ ...context, is_validated: value });
    }
  };
  const routeChange = () => {
    // if (context.username === "") {
    //   textInputName.current.focus();
    // } else if (context.email === "") {
    //   textInputEmail.current.focus();
    // }
    // else if (context.mobile_no === "") {
    //   textInputmobile_no.current.focus();
    // }
    // else if (context.role === "") {
    //   textInputrole.current.focus();
    // }
    // else if (context.company_name === "") {
    //   textInputcompany_name.current.focus();
    // }
    // else if (context.is_validated === "") {
    //   textInputis_validated.current.focus();
    // } else {
    let userData = {
      "method": 12, //Mandatory
      "username": context.username,
      "user_type": 1,
      "first_name": context.username,
      "email": context.email,
      "mobile_no": context.mobile_no,
      "company_name": context.company_name,
      "role": context.role,
      "is_validated": context.is_validated,
      "created_by": userid
    };

    console.log("Passed Data: ", context);
    saveCloud(userData);
    // }
  };

  function saveCloud(userData) {
    AxiosInstance.post("api/aam/user", userData)
      .then((res) => {
        if (res.data.status) {
          Swal.fire({
            title: "Success",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigateToPreviousScreen();
            }
          });
        } else {
          Swal.fire({
            title: "Fail",
            text: res.data.message,
            icon: "Fail",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              textInputName.current.focus();
            }
          });
        }
      })
      .catch((error) => {
        return error;
      });
  }

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      // updateStateData();
      // setSearchTerm(val);
      // if (val.length > 0) {
      //   filterSearch = [{ name: "" + val, type: "search" }];
      // } else {
      //   filterSearch = [];
      // }
      // loadChipdata();
    }
  };
  //sorting function
  const handleSort = (event, sortKey) => {
    // event.preventDefault();
    // let order = sortOrder === "asc" ? "desc" : "asc";
    // setSortOrder(order);
    // // setSortColumn(sortKey);
    // let sortedList = updateListData.sort((a, b) => {
    //   if (a[sortKey] < b[sortKey]) {
    //     return order === "asc" ? -1 : 1;
    //   }
    //   if (a[sortKey] > b[sortKey]) {
    //     return order === "asc" ? 1 : -1;
    //   }
    //   return 0;
    // });
    // setUpdateListData(sortedList);
    // setSortColumn(sortKey);
  };
  const handleFilter = (e, boxName) => {
    // if (boxName === "status") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     status: !prevState.status,
    //   }));
    // } else if (boxName === "category") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     category: !prevState.category,
    //   }));
    // }
  };
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  console.log(context);
  if (usertype === 3) {
    return (
      <>
        <div className="bodycontent">
          <div className="ur_div">
            <p className="back_button" onClick={navigateToPreviousScreen}>
              <span className="material-icons">keyboard_backspace</span>Back to
              Users
            </p>
          </div>
          <div className="ur_div">
            <div><p className="topic_title">Add Users</p></div>
          </div>
          <div className="ur_content">
            <div className="row">
              <div className="col-md-6 inp_grp">
                <label for="input_name" className="ur_labelText">
                  User Name
                </label>
                <input className="ur_InputText"
                  // value={context.username} 
                  ref={textInputName}
                  onChange={handleChange}
                  name="input_name"
                />
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Phone Number
                </label>
                <input className="ur_InputText"
                  // value={context.mobile_no}
                  ref={textInputmobile_no}
                  onChange={handleChange}
                  name="input_mobileno"
                />
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Email id
                </label>
                <input className="ur_InputText"
                  // value={context.email}
                  ref={textInputEmail}
                  onChange={handleChange}
                  name="input_email"
                />
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Organisation Name
                </label>
                <select
                  ref={textInputcompany_name}
                  value={setContext.company_name}
                  className="ur_InputText"
                  name="company_name"
                  onChange={handleChange}
                  id="company_name"
                >
                  <option value="" disabled>Please select</option>
                  {company_name}
                </select>
                {/* {error.company_name && (
                    <small className="error">{error.company_name}</small>
                  )} */}
                {/* <input className="ur_InputText" onChange={handleChange}/> */}
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 inp_grp">
                <label for="input_des" className="ur_labelText">
                  Role
                </label>
                <select
                  ref={textInputrole}
                  value={setContext.role}
                  className="ur_InputText"
                  name="name"
                  onChange={handleChange}
                  id="name"
                >
                  <option value="" disabled>Please select</option>
                  {role}
                </select>
                {/* <input className="ur_InputText" onChange={handleChange}/> */}
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_des" className="ur_labelText">
                  Status
                </label>
                <select
                  ref={textInputis_validated}
                  value={context.is_validated}
                  className="ur_InputText"
                  name="is_validated"
                  onChange={handleChange}
                  id="is_validated"
                >
                  <option value="" disabled>Please select</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                {/* <input className="ur_InputText" onChange={handleChange}/> */}
              </div>
            </div>
          </div>
          <div className="ur_submit_bg">
            <span class="ur_save_buttons material-icons">save</span>
            <button className="ur_save_button" onClick={routeChange}>
              Save
            </button>
          </div>
        </div>
      </>
    );
  }

  if (usertype === 2) {
    return (
      <>
        <div className="bodycontent">
          <div className="ur_div">
            <p className="back_button" onClick={navigateToPreviousScreen}>
              <span className="material-icons">keyboard_backspace</span>Back to
              Users
            </p>
          </div>
          <div className="ur_div">
            <div><p className="topic_title">Add Users</p></div>
          </div>
          <div className="ur_content">
            <div className="row">
              <div className="col-md-6 inp_grp">
                <label for="input_name" className="ur_labelText">
                  User Name
                </label>
                <input className="ur_InputText"
                  // value={context.username} 
                  ref={textInputName}
                  onChange={handleChanges}
                  name="input_name"
                />
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Phone Number
                </label>
                <input className="ur_InputText"
                  // value={context.mobile_no}
                  ref={textInputmobile_no}
                  onChange={handleChanges}
                  name="input_mobileno"
                />
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Email id
                </label>
                <input className="ur_InputText"
                  // value={context.email}
                  ref={textInputEmail}
                  onChange={handleChanges}
                  name="input_email"
                />
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Organisation Name
                </label>
                <select
                  ref={textInputcompany_names}
                   value={setContext.company_name}
                  // value={company_names}
                  className="ur_InputText"
                  name="company_names"
                  onChange={handleChanges}
                  id="company_name"
                >
                <option></option>{company_names}</select>

              </div>
            </div>
            <div className="row">
              <div className="col-md-6 inp_grp">
                <label for="input_des" className="ur_labelText">
                  Role
                </label>
                <select
                  ref={textInputrole}
                  value={setContext.role}
                  className="ur_InputText"
                  name="name"
                  onChange={handleChanges}
                  id="name"
                >
                  <option value="" disabled>Please select</option>
                  {role}
                </select>
                {/* <input className="ur_InputText" onChange={handleChange}/> */}
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_des" className="ur_labelText">
                  Status
                </label>
                <select
                  ref={textInputis_validated}
                  value={context.is_validated}
                  className="ur_InputText"
                  name="is_validated"
                  onChange={handleChanges}
                  id="is_validated"
                >
                  <option value="" disabled>Please select</option>
                  <option value="1">Active</option>
                  <option value="0">Inactive</option>
                </select>
                {/* <input className="ur_InputText" onChange={handleChange}/> */}
              </div>
            </div>
          </div>
          <div className="ur_submit_bg">
            <span class="ur_save_buttons material-icons">save</span>
            <button className="ur_save_button" onClick={routeChange}>
              Save
            </button>
          </div>
        </div>
      </>
    );
  }
}
export default AddUser;
