import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react'

import { AxiosInstance } from '../../axios';

export default function ViewDetail() {

  const { id, keyword } = useParams();

  const navigate = useNavigate();

  console.log(keyword);

  // keyword === 0 - cloud | 1 - Provisioner | 2- Category | 3 - SubCategory

  const [formData, setFormData] = useState({
    name: '',
    version: '',
    desc: '',
    website: ''
  });

  useEffect(() => {
    const bb = {
      "method": 2,
      "id": id
    };

    AxiosInstance.post("api/aam/master/code", bb)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          setFormData({
            name: res.data.data[0].code_name,
            version: res.data.data[0].version,
            desc: res.data.data[0].description,
            website: res.data.data[0].website,
          });
        }
      })
      .catch((error) => {
        return error;
      });
  }, [id]);



  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  const handleEditView = (ids) => {

    console.log(ids);

    if (keyword == 0) {
      navigate(`/dashboard/cloud_detail/${ids}`);
    } else if (keyword == 1) {
      navigate(`/dashboard/provisioner_detail/${ids}`);
    }
    else if (keyword == 2) {
      navigate(`/dashboard/category_detail/${ids}`);
    }
    else if (keyword == 3) {
      navigate(`/dashboard/subcategory_detail/${ids}`);
    }
    else if (keyword == 4) {
      navigate(`/dashboard/ServiceType_detail/${ids}`);
    }
  }
  console.log(formData);

  return (
    <div className="bodycontent">
      <div className="cld_div">
        <p className="back_button" onClick={navigateToPreviousScreen}>
          <span className="material-icons">keyboard_backspace</span>
          {keyword == 0 && "Back to Cloud"}
          {keyword == 1 && "Back to Provisioner"}
          {keyword == 2 && "Back to Category"}
          {keyword == 3 && "Back to SubCategory"}
          {keyword == 4 && "Back to ServiceType"}
        </p>

      </div>
      <div className="cld_div justify-content-between">
        <h3 className="title_tag">View {keyword == 0 && "Cloud"}
          {keyword == 1 && "Provisioner"}
          {keyword == 2 && "Category"}
          {keyword == 3 && "SubCategory"}
          {keyword == 4 && "ServiceType"}</h3>
        <div className="d-flex justify-content-end align-item-center">
          <button
            className="back_buttons  me-3" onClick={() => handleEditView(id)}>
            <span className="material-icons me-2">mode_edit</span>
            <Link to='javascript:void(0)'>Edit</Link>
          </button>
        </div>
      </div>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-md-3">
            <div className="inp_grp">
              <label className="ReviewLabels">
                {keyword == 0 && "Cloud"}
                {keyword == 1 && "Provisioner"}
                {keyword == 2 && "Category"}
                {keyword == 3 && "SubCategory"}
                {keyword == 4 && "ServiceType"} Name
              </label>
              <span className='ReviewLabel'>{formData.name != "" && formData.name != null ? formData.name : "_"}</span>
            </div>
          </div>
          <div className="col-md-3">
            <div className="inp_grp">
              <label className="ReviewLabels">
                Website
              </label>
              <span className='ReviewLabel'>{formData.website != "" && formData.website != null ? formData.website : "_"}</span>
            </div>
          </div>
          {(
            keyword !== 0 || keyword !== 3
          ) && (
              <div className="col-md-3">
                <div className="inp_grp">
                  <label className="ReviewLabels">
                    Version
                  </label>
                  <span className='ReviewLabel'>{formData.version != "" && formData.version != null ? formData.version : "_"}</span>
                </div>
              </div>
            )}


        </div>
        <div className="row mt-4">
          <div className="col-md-12">
            <div className="inp_grp">
              <label className="ReviewLabels">
                Description
              </label>
              <span className='ReviewLabel'>{formData.desc != "" && formData.desc != null ? formData.desc : "_"}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
