import "./Rbac.css";

import React, { useEffect, useRef, useState } from "react";

import { AxiosInstance } from "../../axios/index";
import SearchIcon from "@mui/icons-material/Search";
import add from "../../assets/add.png";
import tbl_view from "../../assets/table_view.svg";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

function Rbac() {
  let navigate = useNavigate();

  let Users = null;
  const userJSON = localStorage.getItem("userDetails");
  if (userJSON) {
    // setUser(JSON.parse(userJSON));
    Users = JSON.parse(userJSON);
  } else {
    console.log("User Id cannot available in localstorge");
    // setUser({});
    Users = null;
  }
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const [user_list, setuser_list] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const [updateChipData, setUpdateChipData] = useState([]);
  const [fullname, setFullname] = useState([]);
  const [fullnameon, setFullnameon] = useState([]);
  const [status, setStatus] = useState([]);
  const [statuson, setStatuson] = useState([]);

  const [isOpen, setIsOpen] = useState({
    fullname: false,
    status : false,

  });

  function loadListdata() {
   
    let tempfullnameon = [];
    fullnameon.map((obj) => {
      tempfullnameon.push(obj.id);
    });
    let tempstatuson = [];
    statuson.map((obj) => {
      tempstatuson.push(obj.id);
    });
    let statusFilter = {
      method: 0,
      search: searchTerm,
      fullname: fullnameon.length > 0 ? tempfullnameon : "",
      status: statuson.length > 0 ? tempstatuson : "",

    };
    AxiosInstance.post("api/aam/master/role", statusFilter)
      .then((res) => {
        console.log("success ", res);
        if (res.data.status === true) {
          const fiata = res.data.data;
          setuser_list(fiata);
        } else if (res.data.status === false) {
          setuser_list([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setuser_list([]);
        return error;
      });
  }

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  useEffect(() => {
    filterfullname = fullnameon;
  }, [fullnameon]);
  useEffect(() => {
    filterstatus = statuson;
  }, [statuson]);

  const handleFilter = (e, boxName) => {
    if (boxName === "fullname") {
      setIsOpen((prevState) => ({
        ...prevState,
        fullname: !prevState.fullname,
      }));
    }
    if (boxName === "status") {
      setIsOpen((prevState) => ({
        ...prevState,
        status: !prevState.status,
      }));
    }
  }

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    // console.log(name, "name........");
    // console.log(value, "value........");
    if (names === "fullname") {
      if (checked) {
        setFullnameon([...fullnameon, { id: value, name: name, fullname: "fullname" }]);
      } else {
        setFullnameon(fullnameon.filter((item) => item.id !== value));
      }
    }else if (names === "status") {
      if (checked) {
        setStatuson([...statuson, { id: value, name: name, status: "status" }]);
      } else {
        setStatuson(statuson.filter((item) => item.id !== value));
      }
    }
  }

  
  const handleClear = (name) => {
    updateStateData();
    if (name === "fullname") {
      setFullnameon([]);
      filterfullname = [];

      setIsOpen({
        ...isOpen,
        fullname: false,
      });
    }else if (name === "status") {
      setStatuson([]);
      filterstatus = [];

      setIsOpen({
        ...isOpen,
        status: false,
      });
    }
  }

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "fullname") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        fullname: false,
      });
    }else if (name === "status") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        status: false,
      });
    }
  }

  function loadChipdata() {
    let tempArr = [];  
    filterfullname.map((obj) => {
      tempArr.push(obj);
    });
    filterstatus.map((obj) => {
      tempArr.push(obj);
    });
    setUpdateChipData(tempArr);
  }

  let filterfullname = [];
  let filterstatus = [];
  function updateStateData() {
    filterfullname = fullnameon;
    filterstatus = statuson;
  }
  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.fullname === "fullname") {
      setFullnameon(fullnameon.filter((item) => item.id !== chipToDelete.id));
      filterfullname = fullnameon.filter((item) => item.id !== chipToDelete.id);
    }else if (chipToDelete.status === "status") {
      setStatuson(statuson.filter((item) => item.id !== chipToDelete.id));
      filterstatus = statuson.filter((item) => item.id !== chipToDelete.id);
    }
    loadChipdata();

  }
  const handleAllClear = () => {
    setuser_list([]);
  };
  const pageChange = () => {
    const path = "/dashboard/addrbac/0";
    navigate(path);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    getCloudList();
  }, [currentPage, itemsPerPage]);

  const handlePageCount = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    console.log(newItemsPerPage, "***************");
    setCurrentPage(1);
  };

  function getCloudList() {
    let tempfullnameon = [];
    fullnameon.map((obj) => {
      tempfullnameon.push(obj.id);
    });
    let tempstatuson = [];
    statuson.map((obj) => {
      tempstatuson.push(obj.id);
    });
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const data = {
      method: 0,
      search: searchTerm,
      fullname: fullnameon.length > 0 ? tempfullnameon : "",      
     status:statuson.length > 0 ? tempstatuson : "",
    };
    AxiosInstance.post("api/aam/master/role", data)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          const dataList = res.data.data.slice(startIndex, endIndex);
          setuser_list(dataList);
          const uniquefullnameValues = new Set();
          dataList.forEach((item) => {
            uniquefullnameValues.add(item.fullname);
          });
          const uniquefullnameArray = Array.from(uniquefullnameValues);
          setFullname(uniquefullnameArray);

          const uniquestatusValues = new Set();
          dataList.forEach((item) => {
            uniquestatusValues.add(item.status);
          });
          const uniquestatusArray = Array.from(uniquestatusValues);
          setStatus(uniquestatusArray);
          setTotalRecords(res.data.data.length);
        } else {
          setuser_list(null);
        }
      })
      .catch((error) => {
        return error;
      });
  }


  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      let val = event.target.value;
      setSearchTerm(val); // Update the search term state
      getCloudList(); // Fetch user list based on the search term
    }
  };
  //sorting function
  const handleSort = (sortKey) => {
    const sortedList = [...user_list];
    sortedList.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

    setuser_list(sortedList);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };


  const handleView = (id) => {
    // clouds view detail -- 0  keyword
    // navigate(`/dashboard/ViewDetail/${id}/${0}`);
    const path = `/dashboard/rbac/${id}`;
    navigate(path);
  };
  return (
    <>
      <div className="bodycontent">
        <div className="rbac_div">
          <p className="topic_title">Roles</p>
        </div>
        <div className="table_parent_div">
          <div className="row">
            <div className="col-md-8">
              <div className="rbac_table_serach">
                <input
                  type="text"
                  placeholder="Search by Role name"
                  onKeyDown={handleSearch}
                />
                <SearchIcon className="rbac_table_serach_img" />
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-end">
              <button className="add_new me-3" onClick={pageChange}>
                <img src={add} alt="add new" />
                <span>Add New</span>
              </button>
            </div>
          </div>
          {updateChipData.length > 0 ? (
            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                  }}
                  component="ul"
                >
                  {updateChipData.map((data, index) => {
                    // console.log(data, " - data - ", index);
                    let icon;
                    let key = "" + data.name;
                    return (
                      <ListItem key={data}>
                        <Chip
                          icon={icon}
                          label={key}
                          onDelete={handleDelete(data, index)}
                        />
                      </ListItem>
                    );
                  })}

                  <ListItem>
                    {/* <Chip
                      label="clear all filter"
                      onClick={handleAllClear}
                    /> */}
                    <a className="allclear" href="./RBAC" onClick={handleAllClear}>
                      clear all filter
                    </a>
                  </ListItem>
                </Paper>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12 table-responsive">
              <table className="table table-striped  table-hover" id="script">
                <thead>
                  <tr>
                    <th id="th">
                      Role Name
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("name")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th" style={{ width: "30%" }}>
                      Descriptions
                    </th>
                    <th id="th">
                      Added By
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("fullname")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${fullnameon.length > 0 ? "active_icons" : ""}`}
                        onClick={(e) => handleFilter(e, "fullname")}
                      >
                        filter_list
                      </span>
                      {isOpen.fullname === true && (
                        <div className="filter-popup">
                          <div className="row mt-2">
                            {fullname.map((el) => (
                              <div className="col-md-12">
                                <div class="filter-option">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name={el}
                                    value={el}
                                    id={el}
                                    style={{ cursor: "pointer" }}
                                    checked={fullnameon.some((element) => {
                                      if (element.name === el) {
                                        // console.log(el,"elements");
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "fullname")
                                    }

                                  />
                                  <label
                                    class="form-check-label YellowText"
                                    for="defaultCheck1"
                                  >
                                    {el}
                                  </label>
                                </div>
                              </div>
                            ))}

                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <span
                                className="clbutton "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("fullname")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8">
                              <button
                                className="apbutton"
                                disabled={fullnameon.length === 0 ? true : false}
                                onClick={() => handleApplyFilter("fullname")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>

                    <th id="th">
                      Added On
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("created_date")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th">
                      Status
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("status")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${statuson.length > 0 ? "active_icons" : ""}`}
                        onClick={(e) => handleFilter(e, "status")}
                      >
                        filter_list
                      </span>
                      {isOpen.status === true && (
                        <div className="filter-popup">
                          <div className="row mt-2">
                            {status.map((el) => (
                              <div className="col-md-12">
                                <div class="filter-option">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name={el}
                                    value={el}
                                    id={el}
                                    style={{ cursor: "pointer" }}
                                    checked={statuson.some((element) => {
                                      if (element.name == el) {
                                        // console.log(el,"elements");
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "status")
                                    }

                                  />
                                  <label
                                    class="form-check-label YellowText"
                                    for="defaultCheck1"
                                  >
                                    {el}
                                  </label>
                                </div>
                              </div>
                            ))}

                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <span
                                className="clbutton "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("status")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8">
                              <button
                                className="apbutton"
                                disabled={statuson.length === 0 ? true : false}
                                onClick={() => handleApplyFilter("status")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>

                  </tr>
                </thead>
                <tbody>
                  {user_list &&
                    user_list.map((obj, index) => {
                      return (
                        <tr className="td_div_action">
                          <td>{obj.name}</td>
                          <td>{obj.description}</td>
                          <td>{obj.fullname}</td>
                          <td>{obj.created_date}</td>
                          <td><button className={(obj.status === 1 ? "rbac_btn_act" : "rbac_btn_inact")}>{(obj.status === 1 ? "Active" : "Inactive")}</button></td>
                          <td>
                            <div className="td_div_action_div">
                              <a
                                href="javascript:void(0)"
                                className="td_div_action_a"
                              >
                                <img
                                  onClick={() => handleView(obj.id)}
                                  src={tbl_view}
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12">
              <div className="pag w-100 d-flex justify-content-end">
                <span className="ipage">Items Per page:</span>
                <select
                  name="pageCount"
                  id="pageCount"
                  defaultValue={itemsPerPage}
                  onChange={handlePageCount}
                  className="ms-2 select_drop"
                >
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span className="pagenumber ms-2">
                  {totalRecords === 0
                    ? "No records found"
                    : `${Math.min(
                      currentPage * itemsPerPage - itemsPerPage + 1,
                      totalRecords
                    )} - ${Math.min(
                      currentPage * itemsPerPage,
                      totalRecords
                    )} of ${totalRecords}`}
                </span>
                <button className="prev_button ms-2 me-2" onClick={handlePrev} disabled={currentPage === 1}>
                  <span class="material-icons">chevron_left</span>
                </button>
                <button className="prev_button" onClick={handleNext} disabled={currentPage === totalPages}>
                  <span class="material-icons">chevron_right</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default Rbac;
