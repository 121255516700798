import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../axios";
import Swal from "sweetalert2";



export default function AddServiceType() {

    const navigate = useNavigate();

    const {id} = useParams();
    
    const textname = useRef(null);
    const textwebsite = useRef(null);
    const textdesc = useRef(null);

    const [error, setError] = useState({});
    
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    // console.log(user , "ssafsafasfasf");
    if (user.data && user.data.user_id) {
      userid = user.data.user_id;
    }
  } else {
    console.log("UserId Not available");
  }
    const [formData, setFormData] = useState({
         name : "",
         web:"",
         des : "",
    })

    useEffect(() => {
        AxiosInstance.post(`api/aam/master/code`, {
            "method":2,
            "id":id
            })
            .then((res) => {
             
                if(res.data.status === true){
                    setFormData({
                          name : res.data.data[0].code_name,
                          des : res.data.data[0].description,
                          web: res.data.data[0].website
                      });
                }
            })
            .catch((error) => {
              return error;
            });
      }, [id]); // id come means it edit view 

    const validateFunction = (data,useref_validation) => {

        const errors = {};

        if (data.name == "") {
            errors.name = "Please enter  name.";
            textname.current.focus();
          }
        else if (data.web == "") {
            errors.web = "Please choose website.";
            textwebsite.current.focus();
          }
          else if (data.des == "") {
            errors.des = "Please choose description.";
            textdesc.current.focus();
          }
          
    return errors;
    }

const handleChange = (e) => {
    const {name , value} = e.target;
    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
}
const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
const handlesubmit = (e) => {

    e.preventDefault(); // Prevent the default form submission behavior
   
    const errors = validateFunction(formData);
    if (Object.keys(errors).length === 0) {
      try {
        if (id) { // edit means if otherwise else condition 

            const sss = {
              method: 1, //Mandatory
              id:id, //Mandatory
              code_group: "Service type",
              code_name: formData.name,
              version: "0.0.0",
              user_id: userid,
              description: formData.des,
              website: formData.web,
            };
            console.log(sss ,"sss"); 
            AxiosInstance.post("api/aam/master/code", sss)
            .then((res) => {
                if(res.data.status === true){
                  Swal.fire({
                      title: "Success",
                      text: res.data.message,
                      icon: "success",
                      confirmButtonText: "Ok",
                    }).then((result) => {
                      if (result.isConfirmed) {
                        navigateToPreviousScreen();
                      }
                    });
                }
            })
            .catch((error) => {
              return error;
            });
          
        } 
        
        else {

          console.log(userid,"userid");
          const fdata = {
            method: 1, //Mandatory
            id: 0, //Mandatory
            code_group: "Service type",
            code_name: formData.name,
            version: "0.0.0",
            user_id: userid,
            description: formData.des,
            website: formData.web,
          };
          console.log(fdata ,"fdata"); 
          AxiosInstance.post("api/aam/master/code", fdata)
          .then((res) => {
              if(res.data.status === true){
                Swal.fire({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    confirmButtonText: "Ok",
                  }).then((result) => {
                    if (result.isConfirmed) {
                      navigateToPreviousScreen();
                    }
                  });
              }
          })
          .catch((error) => {
            return error;
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
}

  return (
    <div className="bodycontent">
      <div className="cld_div">
        <p className="back_button" onClick={navigateToPreviousScreen}>
          <span className="material-icons">keyboard_backspace</span>Back to
          ServiceType
        </p>
      </div>
      <div className="cld_div">
        <h3 className="title_tag">Add New ServiceType</h3>
      </div>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="script_name" className="cld_labelText">
              Service Type Name
              </label>
              <input
                ref={textname}
                value={formData.name}
                onChange={handleChange}
                className="cld_InputText"
                name="name"
                id="name"
                placeholder="Service Type Name"
              />
              {error.name && (
                <small className="error">{error.name}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="cloud_name" className="cld_labelText">
                Website
              </label>
              <input
                ref={textwebsite}
                value={formData.web}
                onChange={handleChange}
                className="cld_InputText"
                name="web"
                id="web"
                placeholder="Website"
              />
              {error.web && (
                <small className="error">{error.web}</small>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="inp_grp">
              <label for="input_name" className="cld_labelText">
                Description
              </label>
              <textarea
                ref={textdesc}
                value={formData.des}
                onChange={handleChange}
                className="cld_Textarea"
                name="des"
                id="des"
                placeholder="Description"
                rows="4"
                cols="50"
              ></textarea>
              {error.des && <small className="error">{error.des}</small>}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 d-flex justify-content-end">
            <span className="save_buttons material-icons">save</span>
            <button className="save_button " onClick={handlesubmit}>
              <Link to="javascript:void(0)">Save</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
