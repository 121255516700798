import './Footer.css';

import Logo from "../assets/Group 12@2x.png";
import React from 'react'

// import { getUser, removeUserSession, getToken } from '../Utils/Common';
  
function Footer()
{  
        return (
          <div className="container-fluid" id="footer_hook">
            <div className="container">
              <div className="row">
                <div className="col-md-4 BorderFoot">
                  <i className="fa fa-envelope" id="emailText"></i>
                  <a href="mailto:info@pilvisystems.com" className="FooterText">
                    info@pilvisystems.com
                  </a>
                </div>
                <div className="col-md-4 BorderFoot">
                  <i className="fa fa-phone" id="emailText"></i>
                  <a href="tel:+1 469 277 7192" className="FooterText">
                    +1 469 277 7192,{" "}
                  </a>
                  <a href="tel:+1 925 405 5844" className="FooterText">
                    +1 925 405 5844
                  </a>
                </div>
                <div className="col-md-4 BorderFoots">
                  <i className="fa fa-map-marker" id="emailText"></i>
                  <a href="javascript:void(0)" className="FooterTexts">
                    106 N, Denton Tap Rd.,{" "}
                    <span className="LocationText">
                      Suite 210-175, Coppell, TX 75019
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 footer_five"></div>
            </div>
            <div className="row">
              <div className="col-md-4 text-start " id="footer_ones">
                <i className="fa fa-facebook socialIocns"></i>
                <i className="fa fa-instagram socialIocns"></i>
                <i className="fa fa-twitter socialIocns"></i>
                <i className="fa fa-whatsapp socialIocns"></i>
                <i className="fa fa-youtube-play socialIocns"></i>
              </div>
              <div className="col-md-8" id="footer_ones">
                <p id="copyRight_hook">
                  Copyright © 2023 Always Automate. All rights reserved /
                  Privacy / Terms of Use
                </p>
              </div>
            </div>
          </div>
        );
}  
export default Footer 