import "./Login.css";
import "./Verify.css";

import React, { useEffect, useRef, useState } from "react";

import ArrowLeft from "../../assets/arrow-left.svg"
import { AxiosInstance } from "../../axios/index";
import Logo from "../../assets/Group 12@2x.png";
import Timer from "../../assets/stopwatch-fill.svg";
import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";

function Verify()
{
  
  let navigate = useNavigate();
   const [context, setContext] = useState({ code: ""   });
  const textInputEmail = useRef(null);

  const [user, setUser] = useState([]);
  const [minutes, setMinutes] = useState(1);
  const [seconds, setSeconds] = useState(30);

  useEffect(() => {
    const userDetails = localStorage.getItem("user");
    if (userDetails) {
      setUser(JSON.parse(userDetails));
    } else {
      setUser("");
    }
  }, []);
  
  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "input_code") {
      setContext({ ...context, code: value });
    }
  };

  const routeChange = () => {
    if (context.code === "") {
      textInputEmail.current.focus();
    } else {
      let userData = {
        method: 1,
        otp: context.code,
        email: user.email,
      };
      logiApi(userData);
    }
  };

   function logiApi(userData) {
     AxiosInstance.post("api/aam/user", userData)
       .then((res) => {
         if (res.data.status) {
          let path = `/login`;
          navigate(path);
         } else {
           textInputEmail.current.focus();
         }
       })
       .catch((error) => {
         return error;
       });
   }
  
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const resendOTP = () => {
    setMinutes(1);
    setSeconds(30);

     let userData = {
       method: 5,
       email: user.email, //user email
     };

     AxiosInstance.post("api/aam/user", userData)
       .then((res) => {
         console.log(res.data);
         if (res.data.status) {
           let from = `/dashboard`;
           //  navigate(path);
           Swal.fire({
             title: "Success",
             text: res.data.message,
             icon: "success",
             confirmButtonText: "Ok",
           }).then((result) => {});
         } else {
           Swal.fire({
             title: "Fail",
             text: res.data.message,
             icon: "fail",
             confirmButtonText: "Ok",
           }).then((result) => {});
         }
       })
       .catch((error) => {
         return error;
       });
  };

  return (
    // <div className="container">
    <div className="c_row">
      <div className="col-lg-4 col-md-12 left_banner">
        {/* <div className="left_banner"></div> */}
        <div className="right_img">
          <img className="right_img" src={Logo} alt="Banners" />
        </div>
        <div className="title_sec">
          <p className="title_banner">Start your </p>
          <p className="title_banner_sec">journey with us.</p>
          <p className="para_text">
            Explore automation solutions that can help you meet your goals.
            Automate your work and build something new.
          </p>
        </div>
      </div>
      <div className="col-lg-8 col-md-12">
        <div className="vf_parent">
          <div className="vf_parent_div">
            <a href="/signup">
              <img src={ArrowLeft} />
            </a>
            <p className="vf_title">Verify Email Id</p>
          </div>
          <div className="vf_label_div">
            <p className="vf_label_normal">
              The email verification code has been sent to the email id you
              provided. Please enter the codes below in the respective fields.
            </p>
            <p className="vf_label_email">{user.email}</p>
          </div>
          <div className="vf_div_labelText">
            <label for="input_code" className="vf_labelText">
              Email Id Verification Code
            </label>
            <div className="vf_sub_div">
              <input
                ref={textInputEmail}
                value={context.code}
                onChange={handleChange}
                className="vf_InputText"
                name="input_code"
                id="input_code"
                placeholder="Enter Email Id Verification Code"
              />
              <div className="vf_sub_div_img">
                {/* <FontAwesomeIcon icon={faStopwatch} size="2x" /> */}
                <img src={Timer} />
              </div>
              <label>
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>00:00</p>
                )}
              </label>
            </div>
          </div>
          <div className="vf_sub_div">
            <p className="vf_sub_title">Didn't receive the code yet?</p>
            {/* <a href="/signup" className="vf_sub_title_create">
              Resend code
            </a> */}
            <button
              onClick={resendOTP}
              className="vf_sub_title_create"
              disabled={seconds > 0 || minutes > 0}
            >
              Resend code
            </button>
          </div>

          <div className="vf_submit_bg">
            <button className="vf_submit" onClick={routeChange}>
              Verify
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Verify;