import "./User.css";
import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AxiosInstance } from '../../axios';
import Swal from "sweetalert2";
export default function ViewUser() {
  const { id } = useParams();
  const navigate = useNavigate();
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;
  let usertype = null;
  let tennet_id = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    if (user.data && user.data.user_id) {
      userid = user.data.user_id;
    }
    if (user.data && user.data.user_type) {
      usertype = user.data.user_type;
    }
    if (user.data && user.data.tennet_id) {
      tennet_id = user.data.tennet_id;
    }
  } else {
    console.log("UserId Not available");
  }


  const [formData, setFormData] = useState({
    username: "",
    mobile_no: "",
    email: "",
    company_name: "",
    name: "",
    fullname: "",
    is_validated: ""

  })
  const [error, setError] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setError({
      ...error,
      [name]: "",
    });
  }


  const [company_name, setcompany_name] = useState([]);
  const [company_names, setcompany_names] = useState([]);
  const [name, setname] = useState([]);
  const [is_validated, setis_validated] = useState([]);


  const textusername = useRef(null);
  const textmobile_no = useRef(null);
  const textemail = useRef(null);
  const textcompany_name = useRef(null);
  const textInputcompany_names = useRef(null);
  const textname = useRef(null);
  const textis_validated = useRef(null);

  const validateFunction = (data, useref_validation) => {

    const errors = {};
    if (data.username == "") {
      errors.username = "Please enter username name.";
      textusername.current.focus();
    }
    else if (data.mobile_no == "") {
      errors.mobile_no = "Please enter mobile number.";
      textmobile_no.current.focus();
    }
    else if (data.email == "") {
      errors.email = "Please enter email.";
      textemail.current.focus();
    }
    else if (data.company_name == "") {
      errors.company_name = "Please choose company_name.";
      textcompany_name.current.focus();
    }
    else if (data.name == "") {
      errors.name = "Please choose role.";
      textname.current.focus();
    }
    else if (data.is_validated == "") {
      errors.is_validated = "Please choose status.";
      textis_validated.current.focus();
    }
    return errors;

  }





  // console.log(formData, "user list");
  useEffect(() => {
    const bb = {
      "method": 9,
      "id": id
    };
    AxiosInstance.post("api/aam/user", bb)
      .then((res) => {
        if (res.data.status) {
          setFormData({
            username: res.data.data[0].username,
            mobile_no: res.data.data[0].mobile_no,
            email: res.data.data[0].email,
            company_name: res.data.data[0].company_name,
            name: res.data.data[0].name,
            fullname: res.data.data[0].fullname,
            is_validated: res.data.data[0].is_validated
          });
        }
      })
      .catch((error) => {
        console.error(error);
      });
  }, [id]);

  useEffect(() => {
    AxiosInstance.post("api/aam/account", {
      "method": 3,
      "tennet_id": tennet_id
    })
      .then((res) => {
        let company_name = res.data.data.map((item, index) => (
          <option key={index} value={item.company_name}>
            {item.company_name}
          </option>
        ));
        setcompany_names(company_name);
      })
      .catch((error) => {
        return error;
      });
  }, []);


  useEffect(() => {
    AxiosInstance.post("api/aam/account", {
      "method": 2
    })
      .then((res) => {
        let company_name = res.data.data.map((item, index) => (
          <option key={index} value={item.company_name}>
            {item.company_name}
          </option>
        ));
        setcompany_name(company_name);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    AxiosInstance.post("api/aam/master/role", {
      "method": 5,
    })
      .then((res) => {
        let name = res.data.data.map((item, index) => (
          <option key={index} value={item.name}>
            {item.name}
          </option>
        ));
        setname(name);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  useEffect(() => {
    AxiosInstance.post("api/aam/user", {
      "method": 7,
      "user_id": userid,
      "search": "",
      "company_name": "",
      "filter_role": "",
      "is_validated": ""
    })
      .then((res) => {
        let is_validated = res.data.data.map((item, index) => (
          <option key={index} value={item.is_validated}>
            {item.is_validated}
          </option>
        ));
        setis_validated(is_validated);
      })
      .catch((error) => {
        return error;
      });
  }, []);

  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
  const navigateTopresentscreen = () => {
    navigate(); // Navigate back by one step in the history
  };
  const handlesubmit = (e) => {

    e.preventDefault(); // Prevent the default form submission behavior

    const errors = validateFunction(formData);
    if (Object.keys(errors).length === 0) {
      try {
        // if (id) { // edit means if otherwise else condition
        const ssd = {
          "method": 13,
          "id": id,
          "username": formData.username,
          "mobile_no": formData.mobile_no,
          "email": formData.email,
          "company_name": formData.company_name,
          "name": formData.name,
          "is_validated": formData.is_validated,
          "updated_by": userid
        }
        console.log(ssd, "***********")
        AxiosInstance.post("api/aam/user", ssd)
          .then((res) => {
            if (res.data.status === true) {
              Swal.fire({
                title: "Success",
                text: res.data.message,
                icon: "success",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  navigateToPreviousScreen();
                }
              });
            }
            else {
              Swal.fire({
                title: "Fail",
                text: res.data.message,
                icon: "Fail",
                confirmButtonText: "Ok",
              }).then((result) => {
                if (result.isConfirmed) {
                  textemail.current.focus();
                }
              });
            }
          })
          .catch((error) => {
            return error;
          });
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
  }

  if (usertype === 3) {
    return (
      <>
        <div className="bodycontent">
          <div className="ur_div">
            <p className="back_button" onClick={navigateToPreviousScreen}>
              <span className="material-icons">keyboard_backspace</span>Back to
              Users
            </p>
          </div>
          <div className="ur_div">
            <div><p className="topic_title">Edit User</p></div>
          </div>
          <div className="ur_content">
            <div className="row">
              <div className="col-md-6 inp_grp">
                <label for="username" className="ur_labelText" >
                  User Name
                </label>
                <input className="ur_InputText"
                  ref={textusername}
                  name="username"
                  id="username"
                  onChange={handleChange}
                  value={formData.username}
                  placeholder="user Name" />
                {error.username && (
                  <small className="error">{error.username}</small>
                )}
              </div>
              <div className="col-md-6 inp_grp">
                <label for="mobile_no" className="ur_labelText">
                  Phone Number
                </label>
                <input className="ur_InputText"
                  value={formData.mobile_no}
                  ref={textmobile_no}
                  name="mobile_no"
                  id="mobile_no"
                  onChange={handleChange}
                  placeholder="phone number" />
                {error.mobile_no && (
                  <small className="error">{error.mobile_no}</small>
                )}
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Email id
                </label>
                {/* <input className="ur_InputText" value={formData.email} /> */}
                <input className="ur_InputText"
                  ref={textemail}
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={formData.email}
                  placeholder="Email" />
                {error.email && (
                  <small className="error">{error.email}</small>
                )}
              </div>
              <div className="col-md-6">
                <div className="inp_grp">
                  <label for="company_name" className="cld_labelText">
                    Organisation name
                  </label>
                  <select
                    ref={textcompany_name}
                    value={formData.company_name}
                    className="cld_InputText"
                    placeholder="Cloud"
                    name="company_name"
                    onChange={handleChange}
                    id="company_name"
                  >
                    <option value="" disabled>Please select</option>
                    {company_name}
                  </select>
                  {error.company_name && (
                    <small className="error">{error.company_name}</small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="inp_grp">
                  <label for="name" className="cld_labelText">
                    Role
                  </label>
                  <select
                    ref={textname}
                    value={formData.name}
                    className="cld_InputText"
                    placeholder="Cloud"
                    name="name"
                    onChange={handleChange}
                    id="name"
                  >
                    <option value="" disabled>Please select</option>
                    {name}
                  </select>
                  {error.name && (
                    <small className="error">{error.name}</small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="inp_grp">
                  <label for="is_validated" className="cld_labelText">
                    Status
                  </label>
                  <select
                    ref={textis_validated}
                    value={formData.is_validated}
                    className="cld_InputText"
                    placeholder="Cloud"
                    name="is_validated"
                    onChange={handleChange}
                    id="is_validated"
                  >
                    <option value="" disabled>Please select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  {error.is_validated && (
                    <small className="error">{error.is_validated}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ur_submit_bg">
                <span class="ur_save_buttons material-icons">save</span>
                <button className="ur_save_button" onClick={handlesubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
  else if (usertype === 2) {
    return (
      <>
        <div className="bodycontent">
          <div className="ur_div">
            <p className="back_button" onClick={navigateToPreviousScreen}>
              <span className="material-icons">keyboard_backspace</span>Back to
              Users
            </p>
          </div>
          <div className="ur_div">
            <div><p className="topic_title">Edit Users</p></div>
          </div>
          <div className="ur_content">
            <div className="row">
              <div className="col-md-6 inp_grp">
                <label for="input_name" className="ur_labelText">
                  User Name
                </label>
                <input className="ur_InputText"
                  ref={textusername}
                  name="username"
                  id="username"
                  onChange={handleChange}
                  value={formData.username}
                  placeholder="user Name" />
                {error.username && (
                  <small className="error">{error.username}</small>
                )}
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Phone Number
                </label>
                <input className="ur_InputText"
                  value={formData.mobile_no}
                  ref={textmobile_no}
                  name="mobile_no"
                  id="mobile_no"
                  onChange={handleChange}
                  placeholder="phone number" />
                {error.mobile_no && (
                  <small className="error">{error.mobile_no}</small>
                )}
              </div>
              <div className="col-md-6 inp_grp">
                <label for="input_web" className="ur_labelText">
                  Email id
                </label>
                <input className="ur_InputText"
                  ref={textemail}
                  name="email"
                  id="email"
                  onChange={handleChange}
                  value={formData.email}
                  placeholder="Email" />
                {error.email && (
                  <small className="error">{error.email}</small>
                )}
              </div>
              <div className="col-md-6">
                <div className="inp_grp">
                  <label for="company_name" className="cld_labelText">
                    Organisation name
                  </label>
                  <select
                    ref={textInputcompany_names}
                    value={formData.company_name}
                    // value={company_names}
                    className="ur_InputText"
                    name="company_names"
                    onChange={handleChange}
                    id="company_name"
                  >
                    <option></option>{company_names}</select>
                </div>
              </div>
              <div className="col-md-6">
                <div className="inp_grp">
                  <label for="name" className="cld_labelText">
                    Role
                  </label>
                  <select
                    ref={textname}
                    value={formData.name}
                    className="cld_InputText"
                    name="name"
                    onChange={handleChange}
                    id="name"
                  >
                    <option value="" disabled>Please select</option>
                    {name}
                  </select>
                  {error.name && (
                    <small className="error">{error.name}</small>
                  )}
                </div>
              </div>
              <div className="col-md-6">
                <div className="inp_grp">
                  <label for="is_validated" className="cld_labelText">
                    Status
                  </label>
                  <select
                    ref={textis_validated}
                    value={formData.is_validated}
                    className="cld_InputText"
                    name="is_validated"
                    onChange={handleChange}
                    id="is_validated"
                  >
                    <option value="" disabled>Please select</option>
                    <option value="1">Active</option>
                    <option value="0">Inactive</option>
                  </select>
                  {error.is_validated && (
                    <small className="error">{error.is_validated}</small>
                  )}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="ur_submit_bg">
                <span class="ur_save_buttons material-icons">save</span>
                <button className="ur_save_button" onClick={handlesubmit}>
                  Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }

}
