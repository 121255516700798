import "./Home.css";

import Footer from "../../layouts/Footer.js";
import Header from "../../layouts/Header.js";
import ReCAPTCHA from "react-google-recaptcha";
import React from "react";
import img_group49 from "../../assets/Group 49.png";
import img_ic from "../../assets/ic.png";
import img_project1 from "../../assets/project1.png";
import img_project2 from "../../assets/project2.png";
import img_project3 from "../../assets/project3.png";
import img_project4 from "../../assets/project4.png";

function Home() {
  return (
    <>
      <Header />
      <section className="container-fluid" id="sectionOne">
        <div className="row">
          <div className="col-md-12 p-0">
            <div
              id="carouselExampleDark"
              className="carousel carousel-dark slide"
              data-bs-ride="carousel"
            >
              <div className="carousel-indicators">
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-interval="100"
                  data-bs-slide-to="0"
                  className="active my-button"
                  aria-current="true"
                  aria-label="Slide 1"
                >
                  <span className="indicator-content">01</span>
                </button>
                <i className="fa fa-long-arrow-down icons"></i>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-interval="500"
                  data-bs-slide-to="1"
                  aria-label="Slide 2"
                >
                  <span className="indicator-content">02</span>
                </button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-interval="500"
                  data-bs-slide-to="2"
                  aria-label="Slide 3"
                >
                  <span className="indicator-content">03</span>
                </button>
                <button
                  type="button"
                  data-bs-target="#carouselExampleDark"
                  data-bs-interval="500"
                  data-bs-slide-to="3"
                  aria-label="Slide 4"
                >
                  <span className="indicator-content">04</span>
                </button>
              </div>
              <div className="carousel-inner">
                <div className="carousel-item active" data-bs-interval="10000">
                  <img
                    src="https://images.unsplash.com/photo-1617396900799-f4ec2b43c7ae?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1470&q=80"
                    id="sliderImg"
                    className="d-block w-100 h-100"
                    alt="..."
                  />
                  <div className="overlay"></div>
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className="sliderTitle">
                      STREAMLINE AND SIMPLIFY WITH ALWAYS AUTOMATE
                    </h5>
                    <p className="slidersubTitle">
                      Always Automate elevates users' workflows by providing
                      automation scripts that enhance their efficiency and
                      effectiveness. By automating manual processes, users can
                      experience smoother workflows, reduced errors, and
                      improved overall performance. It enables them to work with
                      greater precision and deliver exceptional results.
                    </p>
                  </div>
                </div>
                <div className="carousel-item" data-bs-interval="2000">
                  <img
                    src="https://images.unsplash.com/photo-1523961131990-5ea7c61b2107?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
                    className="d-block w-100"
                    id="sliderImg"
                    alt="..."
                  />
                  <div className="overlay"></div>
                  <div className="carousel-caption d-none d-md-block">
                    <h5 className="sliderTitle">
                      STREAMLINE AND SIMPLIFY WITH ALWAYS AUTOMATE
                    </h5>
                    <p className="slidersubTitle">
                      Always Automate elevates users' workflows by providing
                      automation scripts that enhance their efficiency and
                      effectiveness. By automating manual processes, users can
                      experience smoother workflows, reduced errors, and
                      improved overall performance. It enables them to work with
                      greater precision and deliver exceptional results.
                    </p>
                  </div>
                </div>
                <div className="carousel-item">
                  <img
                    src="https://images.unsplash.com/photo-1639762681057-408e52192e55?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1632&q=80"
                    className="d-block w-100"
                    id="sliderImg"
                    alt="..."
                  />
                  <div className="overlay"></div>
                  <div className="carousel-caption d-none  d-md-block">
                    <h5 className="sliderTitle">
                      STREAMLINE AND SIMPLIFY WITH ALWAYS AUTOMATE
                    </h5>
                    <p className="slidersubTitle">
                      Always Automate elevates users' workflows by providing
                      automation scripts that enhance their efficiency and
                      effectiveness. By automating manual processes, users can
                      experience smoother workflows, reduced errors, and
                      improved overall performance. It enables them to work with
                      greater precision and deliver exceptional results.
                    </p>
                  </div>
                </div>

                <div className="carousel-item">
                  <img
                    src="https://images.unsplash.com/photo-1597733336794-12d05021d510?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1374&q=80"
                    className="d-block w-100"
                    id="sliderImg"
                    alt="..."
                  />
                  <div className="overlay"></div>
                  <div className="carousel-caption d-none  d-md-block">
                    <h5 className="sliderTitle">
                      STREAMLINE AND SIMPLIFY WITH ALWAYS AUTOMATE
                    </h5>
                    <p className="slidersubTitle">
                      Always Automate elevates users' workflows by providing
                      automation scripts that enhance their efficiency and
                      effectiveness. By automating manual processes, users can
                      experience smoother workflows, reduced errors, and
                      improved overall performance. It enables them to work with
                      greater precision and deliver exceptional results.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid" id="sectionTwo">
        <div className="row" id="aboutUs">
          <div className="col-md-12">
            <p className="sec">Welcome to Always Automate!</p>
            <div id="cls">
              <div className="cls1">
                <p className="select">
                  At Always Automate, we are passionate about revolutionizing
                  the way businesses operate through cutting-edge automation
                  technologies. With a team of experienced experts in user
                  experience design, software engineering, and process
                  optimization, we are dedicated to delivering innovative
                  solutions that streamline workflows, enhance productivity, and
                  drive business growth.
                </p>
                <p className="select">
                  Our mission is to empower organizations of all sizes across
                  various industries to embrace the power of automation. By
                  leveraging advanced technologies such as artificial
                  intelligence, machine learning, and robotic process
                  automation, we enable businesses to automate repetitive and
                  time-consuming tasks, allowing their teams to focus on
                  strategic initiatives and value-added activities.
                </p>
                <p className="select">
                  With a customer-centric approach, we strive to understand the
                  unique needs and challenges of each client. Our collaborative
                  process ensures that we create tailored automation solutions
                  that align with your business goals and objectives. Whether
                  it's automating data entry, optimizing supply chain processes,
                  or enhancing customer interactions, we provide comprehensive
                  and scalable automation solutions that drive tangible results.
                </p>
                <p className="select">
                  At Always Automate, we believe in continuous innovation and
                  staying at the forefront of the automation landscape. Our team
                  of experts continuously explores emerging technologies,
                  industry trends, and user insights to deliver solutions that
                  are not only efficient but also intuitive and user-friendly.
                  We are committed to providing exceptional user experiences
                  that enable seamless adoption and maximize the benefits of
                  automation.
                </p>
                <p className="select">
                  Experience the power of automation with Always Automate and
                  unlock new levels of efficiency, productivity, and growth for
                  your business. Contact us today to learn how we can transform
                  your operations and drive success in the digital age.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid" id="sectionThree">
        <div className="row" id="productFeatures">
          <p className="pos_content_title">EFFORTLESSLY EFFICIENT</p>
          <div className="col-lg-6 pos_div">
            <p className="pos_content_para">
              Always Automate offers effortless efficiency through automation
              scripts. Users can automate routine tasks, reducing manual effort
              and improving productivity. By leveraging automation, users can
              accomplish more in less time, enabling them to work smarter, not
              harder.
            </p>
            <button className="button_bg">
              <span className="bt_span">Learn More</span>
              <img className="img_arr" src={img_ic} />
            </button>
          </div>
          <div className="col-lg-6 pos_div">
            <img src={img_project4} className="w-100" />
          </div>
        </div>
      </section>
      <section className="container-fluid" id="sectionThrees">
        <div className="row">
          <div className="col-lg-6 pos_div">
            <img src={img_project1} className="w-100" />
          </div>

          <div className="col-lg-6 pos_div">
            <p className="pos_content_title">UNLOCK YOUR POTENTIAL</p>
            <p className="pos_content_para">
              Always Automate unlocks users' potential by offering automation
              scripts that optimize their workflows. By automating mundane and
              repetitive tasks, users can channel their energy and creativity
              towards more strategic and innovative endeavors. This allows them
              to unleash their full potential and achieve greater results.
            </p>
            <button className="button_bg">
              <span className="bt_span">Learn More</span>
              <img className="img_arr" src={img_ic} />
            </button>
          </div>
        </div>
      </section>
      <section className="container-fluid" id="sectionThree">
        <div className="row">
          <p className="pos_content_title">ELEVATE YOUR WORKFLOW</p>
          <div className="col-lg-6 pos_div">
            <p className="pos_content_para">
              Always Automate elevates users' workflows by providing automation
              scripts that enhance their efficiency and effectiveness By
              automating manual processes, users can experience smoother
              workflows reduced errors, and improved overall performance. It
              enables them to work with greater precision and deliver
              exceptional results.
            </p>
            <button className="button_bg">
              <span className="bt_span">Learn More</span>
              <img className="img_arr" src={img_ic} />
            </button>
          </div>
          <div className="col-lg-6 pos_div">
            <img src={img_project3} className="w-100" />
          </div>
        </div>
      </section>
      <section className="container-fluid" id="sectionThrees">
        <div className="row">
          <div className="col-lg-6 pos_div">
            <img className="w-100" src={img_project2} />
          </div>
          <div className="col-lg-6 pos_div">
            <p className="pos_content_title">SIMPLIFY YOUR JOURNEY</p>
            <p className="pos_content_para">
              Always Automate simplifies users' journey by offering automation
              scripts that simplify complex tasks. Users can rely on automation
              to handle intricate processes, reducing the learning curve and
              making their journey smoother. It simplifies their experience,
              enabling them to achieve their goals faster and with greater ease.
            </p>
            <button className="button_bg">
              <span className="bt_span">Learn More</span>
              <img className="img_arr " src={img_ic} />
            </button>
          </div>
        </div>
      </section>
      <section
        className="bg_sec"
        style={{ backgroundColor: "white", height: "600px" }}
      >
        <img src={img_group49} className="seven1" />
      </section>
      <section className="container-fluid" id="sectionFive">
        <div className="row" id="pricing">
          <div className="col-lg-12 col-md-12 d-flex justify-content-center">
            <div className="switches-container">
              <input
                type="radio"
                id="switchMonthly"
                name="switchPlan"
                value="Monthly"
                checked="checked"
              />
              <input
                type="radio"
                id="switchYearly"
                name="switchPlan"
                value="Yearly"
              />
              <label for="switchMonthly">Monthly</label>
              <label for="switchYearly">Yearly</label>
              <div className="switch-wrapper">
                <div className="switch">
                  <div>Monthly</div>
                  <div>Yearly</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row priceTag">
          <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12">
            <div className="cardText">
              <div className="cardBody">
                <button className="Title">
                  <a href="#">BASIC</a>
                </button>
                <p className="subTitle">$20.00</p>
                <p className="sTitle">Per Member, Per Month</p>
              </div>

              <ul className="PriceCheckbox">
                <li>
                  <i className="fa fa-check-square me-2"></i>All limited links
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>Up to 10 blocks
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>Chat Support
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>API Integration
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
              </ul>
              <button className="btnprice">
                <a href="#">Choose Plan</a>
              </button>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12">
            <div className="cardText">
              <div className="cardBody">
                <button className="StTitle">
                  <a href="#">STANDARD</a>
                </button>
                <p className="subTitle">$99.00</p>
                <p className="sTitle">Per Member, Per Month</p>
              </div>

              <ul className="PriceCheckbox">
                <li>
                  <i className="fa fa-check-square me-2"></i>All limited links
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>Up to 10 blocks
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>Chat Support
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>API Integration
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
              </ul>
              <button className="btnprices">
                <a href="#">Choose Plan</a>
              </button>
            </div>
          </div>
          <div className="col-md-12 col-lg-4 col-xl-4 col-sm-12">
            <div className="cardText">
              <div className="cardBody">
                <button className="PtTitle">
                  <a href="#">PRO</a>
                </button>
                <p className="subTitle">$180.00</p>
                <p className="sTitle">Per Member, Per Month</p>
              </div>

              <ul className="PriceCheckbox">
                <li>
                  <i className="fa fa-check-square me-2"></i>All limited links
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>Up to 10 blocks
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>Chat Support
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
                <li>
                  <i className="fa fa-check-square me-2"></i>API Integration
                </li>
                <li>
                  <p className="borderSpan"></p>
                </li>
              </ul>
              <button className="btnprice">
                <a href="#">Choose Plan</a>
              </button>
            </div>
          </div>
        </div>
      </section>
      <section className="container-fluid" id="sectionFour">
        <div className="row" id="contactUs">
          <div className="col-md-12">
            <h5 className="c_title">Contact Us</h5>
            <h2 className="c_subTitle">We'd love to hear from you</h2>
            <p className="p_title">
              Need help with something? Want a demo? Get in touch with our
              friendly team and we'll get back to you in 2 hours.
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <form>
              <div className="row">
                <div className="col-md-4">
                  <label for="fname" className="labelText">
                    First Name
                  </label>
                  <input
                    className="InputText"
                    name="fname"
                    placeholder="First Name"
                  />
                </div>
                <div className="col-md-4">
                  <label for="lname" className="labelText">
                    Last Name
                  </label>
                  <input
                    className="InputText"
                    name="lname"
                    placeholder="Last Name"
                  />
                </div>
                <div className="col-md-4">
                  <label for="Orgname" className="labelText">
                    Organization Name
                  </label>
                  <input
                    className="InputText"
                    name="Orgname"
                    placeholder="Organization Name"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-4">
                  <label for="email" className="labelText">
                    Email ID
                  </label>
                  <input
                    className="InputText"
                    name="email"
                    placeholder="Email ID"
                  />
                </div>
                <div className="col-md-6">
                  <label for="pnum" className="labelText">
                    Phone Number
                  </label>
                  <select className="Inputselect">
                    <option value="US">US(+1)</option>
                  </select>
                  <input
                    className="InputTexts"
                    name="pnum"
                    placeholder="Phone Number"
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <label for="msg" className="labelText">
                    Message
                  </label>
                  <textarea
                    id="InputTextarea"
                    className="InputTextarea"
                    name="msg"
                    rows="4"
                    cols="50"
                    placeholder="Message"
                  ></textarea>
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 d-flex justify-content-center">
                  <ReCAPTCHA sitekey="6Lel4Z4UAAAAAOa8LO1Q9mqKRUiMYl_00o5mXJrR" />
                </div>
              </div>

              <div className="row mt-4">
                <div className="col-md-12 d-flex justify-content-center">
                  <button className="btnGet">
                    <a href="#">
                      Get in touch
                      <img className="img_arr " src={img_ic} />
                    </a>
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>

      <Footer />
    </>
  );
}
export default Home;
