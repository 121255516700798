import './Footer.css';

import Logo from "../assets/Group 12@2x.png";
import React from 'react'

// import { getUser, removeUserSession, getToken } from '../Utils/Common';
  
function Footer()
{  
        return (
          <div className="container-fluid" id="footer">
            <div className="row">
              <div className="col-lg-7 col-md-12 col-sm-12 " id="footer_one">
                <h4 className="footer_head_title ms-3">
                  Sign up to our newsletter
                </h4>
                <div className="footer_sub_heading ms-3 text-md-center text-lg-start">
                  Stay up to date with the latest news, announcements and
                  articles
                </div>
              </div>
              <div className="col-lg-3 col-md-12 col-sm-12 footer_two ">
                <input
                  type="email"
                  id="form5Example21"
                  className="form-control EMailForm"
                  placeholder="Enter your email"
                />
              </div>
              <div className="col-lg-2 col-md-12 col-sm-12  footer_two">
                <button
                  type="submit"
                  className="btn btn-outline-light footer_subscribe"
                >
                  Subscribe Now
                </button>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12" id="footer_four"></div>
            </div>
            <div className="row">
              <div className="col-md-6 " id="footer_three">
                <img src={Logo} alt="logo" id="footerLogo" />
              </div>
              <div className="col-md-6" id="footer_three">
                <ul id="ulTag">
                  <li>
                    <a href="#aboutUs">About us</a>
                  </li>
                  <li>
                    <a href="#">Product Features</a>
                  </li>
                  <li>
                    <a href="#pricing">Pricing</a>
                  </li>
                  <li>
                    <a href="#contactUs">Contact Us</a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="row ">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 footer_five"></div>
                </div>
                <div className="row">
                  <div className="col-md-4 BorderFoot">
                    <i className="fa fa-envelope" id="emailText"></i>
                    <a
                      href="mailto:info@pilvisystems.com"
                      className="FooterText"
                    >
                      info@pilvisystems.com
                    </a>
                  </div>
                  <div className="col-md-4 BorderFoot">
                    <i className="fa fa-phone" id="emailText"></i>
                    <a href="tel:+1 469 277 7192" className="FooterText">
                      +1 469 277 7192,{" "}
                    </a>
                    <a href="tel:+1 925 405 5844" className="FooterText">
                      +1 925 405 5844
                    </a>
                  </div>
                  <div className="col-md-4 BorderFoots">
                    <i className="fa fa-map-marker" id="emailText"></i>
                    <a href="javascript:void(0)" className="FooterTexts">
                      106 N, Denton Tap Rd.,{" "}
                      <span className="LocationText">
                        Suite 210-175, Coppell, TX 75019
                      </span>
                    </a>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12 footer_five"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-4 text-start " id="footer_ones">
                <i className="fa fa-facebook socialIocns"></i>
                <i className="fa fa-instagram socialIocns"></i>
                <i className="fa fa-twitter socialIocns"></i>
                <i className="fa fa-whatsapp socialIocns"></i>
                <i className="fa fa-youtube-play socialIocns"></i>
              </div>
              <div className="col-md-8" id="footer_ones">
                <p id="copyRight">
                  Copyright © 2023 Always Automate. All rights reserved /
                  Privacy / Terms of Use
                </p>
              </div>
            </div>
          </div>
        );
}  
export default Footer 