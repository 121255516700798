import "./Cloud.css";

import React, { useEffect, useRef, useState } from "react";
import Footer from "../../hooks/Footer";
import Header from "../../hooks/Header";

import { AxiosInstance } from "../../axios/index";
import Logo from "../../assets/Group 12@2x.png";
import { useNavigate, useParams } from "react-router-dom";
import Save from "@mui/icons-material/Save";
import add from "../../assets/add.png";
import { Link } from "react-router-dom";
import tbl_view from "../../assets/table_view.svg";
import Swal from "sweetalert2";

function AddSubCategory() 
{
  let navigate = useNavigate();
  const {id} = useParams();
  const [context, setContext] = useState({
    name: "",
    des: "",
    web: "",
    create_page: true,
    bt_name: "Add",
  });

  const textInputName = useRef(null);
  const textInputDes = useRef(null);
  const textInputWeb = useRef(null);

  const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "input_name") {
      setContext({ ...context, name: value });
    } else if (name === "input_des") {
      setContext({ ...context, des: value });
    } else if (name === "input_web") {
      setContext({ ...context, web: value });
    }
  };
  useEffect(() => {
    console.log(id,"dfdsgdgs");
        AxiosInstance.post('api/aam/master/code',{
          "method":2,
          "id":id
          })
            .then((res) => {
                if(res.data.status === true){
                  setContext({
                      name: res.data.data[0].code_name,
                      des: res.data.data[0].description,
                      web: res.data.data[0].website,
                      create_page: true,
                      bt_name: "Add",
                      });
                }
            })
            .catch((error) => {
              return error;
            });
      }, [id]); // id come means it edit view 

  const routeChange = () => {
    if (context.name === "") {
      textInputName.current.focus();
    } else if (context.des === "") {
      textInputDes.current.focus();
    } else if (context.web === "") {
      textInputWeb.current.focus();
    } else {
      let userData = {
        method: 1, //Mandatory

        id: (id !="" && id !=null) ? id : 0, //Mandatory

        code_group: "Sub Category",

        code_name: context.name,

        version: "0.0.0",

        user_id: "f3f6cdb76d907856275542f42c58ab24",
        description:context.des,
        website:context.web,

      };
      console.log("Passed Data: ", userData);
      saveCloud(userData);
    }
  };

  function saveCloud(userData) {
    AxiosInstance.post("api/aam/master/code", userData)
      .then((res) => {
        console.log(res);
        if (res.data.status) {
          Swal.fire({
            title: "Success",
            text: res.data.message,
            icon: "success",
            confirmButtonText: "Ok",
          }).then((result) => {
            if (result.isConfirmed) {
              navigateToPreviousScreen();
            }
          });
        } else {
          textInputName.current.focus();
        }
      })
      .catch((error) => {
        return error;
      });
  }

  useEffect(() => {
  }, []);

  

  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      // updateStateData();
      // setSearchTerm(val);
      // if (val.length > 0) {
      //   filterSearch = [{ name: "" + val, type: "search" }];
      // } else {
      //   filterSearch = [];
      // }
      // loadChipdata();
    }
  };
  //sorting function
  const handleSort = (event, sortKey) => {
    // event.preventDefault();
    // let order = sortOrder === "asc" ? "desc" : "asc";
    // setSortOrder(order);
    // // setSortColumn(sortKey);
    // let sortedList = updateListData.sort((a, b) => {
    //   if (a[sortKey] < b[sortKey]) {
    //     return order === "asc" ? -1 : 1;
    //   }
    //   if (a[sortKey] > b[sortKey]) {
    //     return order === "asc" ? 1 : -1;
    //   }
    //   return 0;
    // });
    // setUpdateListData(sortedList);
    // setSortColumn(sortKey);
  };
  const handleFilter = (e, boxName) => {
    // if (boxName === "status") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     status: !prevState.status,
    //   }));
    // } else if (boxName === "category") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     category: !prevState.category,
    //   }));
    // }
  };
  const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };

  return (
    <>
      <div className="bodycontent">
        <div className="cld_div">
          <p className="back_button" onClick={navigateToPreviousScreen}>
            <span className="material-icons">keyboard_backspace</span>Back to
            SubCategory
          </p>
        </div>
        <div className="cld_div">
          <p className="topic_title">SubCategory</p>
        </div>
        <div className="cld_content">
          <div className="row">
            <div className="col-md-6 inp_grp">
              <label for="input_name" className="cld_labelText">
                SubCategory Name
              </label>
              <input
                ref={textInputName}
                value={context.name}
                onChange={handleChange}
                className="cld_InputText"
                name="input_name"
                id="input_name"
                placeholder="Enter SubCategory name"
              />
            </div>
            <div className="col-md-6 inp_grp">
              <label for="input_web" className="cld_labelText">
                Website
              </label>
              <input
                ref={textInputWeb}
                value={context.web}
                onChange={handleChange}
                className="cld_InputText"
                name="input_web"
                id="input_web"
                placeholder="Enter website"
              />
            </div>
          </div>
          <div className="inp_grp">
            <label for="input_des" className="cld_labelText">
              Descriptions
            </label>
            <textarea
              ref={textInputDes}
              value={context.des}
              onChange={handleChange}
              className="cld_Textarea"
              name="input_des"
              id="input_des"
              placeholder="Enter descriptions"
              rows="4"
              cols="50"
            ></textarea>
          </div>
          <div className="cld_submit_bg">
            <button className="save_button" onClick={routeChange}>
              <span class="material-icons">Save</span>
            </button>
          </div>
        </div>
      </div>
    </>
  );
}
export default AddSubCategory;
