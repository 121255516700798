import "../Login/Login.css";
import "./Account.css";

import React, { useEffect, useRef, useState } from "react";

import { AxiosInstance } from "../../axios/index";
import Logo from "../../assets/Group 12@2x.png";
import img_eye from "../../assets/eye-fill.svg";
import img_eye_slash from "../../assets/eye-slash-fill.svg";
import { useNavigate } from "react-router-dom";

function Account() {

  let navigate = useNavigate();

  const [actions, setActions] = useState({
    org_type:"0"
  });
  const [context, setContext] = useState({
    name_first: "",
    name_middle: "",
    name_last: "",
    name_org: "",
    type_org: "0",
    web_org: "",
    country: "usa",
    phone: "",
    email: "",
    pass: "",
    cpass: "",
    remember: false,
    eye_new: false,
    eye_confirm: false,
  });

  const textInputFirst = useRef(null);
  const textInputMiddle = useRef(null);
  const textInputLast = useRef(null);
  const textInputOrg = useRef(null);
  const textInputWeb = useRef(null);
  const textInputPhone = useRef(null);
  const textInputEmail = useRef(null);
  const textInputPassword = useRef(null);
  const textInputCPassword = useRef(null);

 

  const eyeChange = () => {
    if (context.eye_new) {
      setContext({ ...context, eye_new: false });
    } else {
      setContext({ ...context, eye_new: true });
    }
  };
  const eyeChangeConfirm = () => {
    if (context.eye_confirm) {
      setContext({ ...context, eye_confirm: false });
    } else {
      setContext({ ...context, eye_confirm: true });
    }
  };

   const handleChange = (event) => {
     const { name, value, type, checked } = event.target;
     if (name === "input_first") {
       setContext({ ...context, name_first: value });
     } else if (name === "input_middle") {
       setContext({ ...context, name_middle: value });
     } else if (name === "input_last") {
       setContext({ ...context, name_last: value });
     }
     else if (name === "select_type_org")
     {
       if (value === "") {
         setActions({ ...actions, org_type: "0" });
         setContext({ ...context, name_org: "" });
         setContext({ ...context, web_org: "" });
       }
       else if (value === "0") {
         setActions({ ...actions, org_type: "0" });
         setContext({ ...context, name_org: "" });
         setContext({ ...context, web_org: "" });
       } else {
         setActions({ ...actions, org_type: value });
       }
       setContext({ ...context, type_org: value });
     } else if (name === "select_country") {
       setContext({ ...context, country: value });
     } else if (name === "input_org") {
       setContext({ ...context, name_org: value });
     } else if (name === "input_web") {
       setContext({ ...context, web_org: value });
     } else if (name === "input_phone") {
       setContext({ ...context, phone: value });
     } else if (name === "input_email") {
       setContext({ ...context, email: value });
     } else if (name === "input_password") {
       setContext({ ...context, pass: value });
     } else if (name === "input_cpassword") {
       setContext({ ...context, cpass: value });
     } else if (name === "remember") {
       setContext({ ...context, remember: checked });
     }
   };
  
  const routeChange = () => {
      if (context.name_first === "") {
        textInputFirst.current.focus();
      }
      // else if (context.name_middle === "") {
      //   textInputMiddle.current.focus();
      // }
      else if (context.name_last === "") {
        textInputLast.current.focus();
      } else if (context.type_org === ""){ 
        alert("Select Organization Type");
      }
      else if ((context.type_org === "1")&&(context.name_org === "")) {
        textInputOrg.current.focus();
      } else if ((context.type_org === "1")&&(context.web_org === "")) {
        textInputWeb.current.focus();
      } else if (context.phone === "") {
        textInputPhone.current.focus();
      }  else if (!/^\d+$/.test(context.phone)) {
        alert("Please enter a valid number.");
        textInputPhone.current.focus();
      } else if ((context.phone.length < 10)||(context.phone.length > 10)) {
        textInputPhone.current.focus();
        alert("Please enter a valid 10 digit");
      } else if (context.email === "") {
        textInputEmail.current.focus();
      } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(context.email)) {
        alert("Please enter a valid email address.");
        textInputEmail.current.focus();
      } else if (context.pass === "") {
        textInputPassword.current.focus();
      } else if (context.cpass === "") {
        textInputCPassword.current.focus();
      } else if (context.cpass !== context.pass) {
        textInputCPassword.current.focus();
      } else if (context.remember === false) {
        alert("Agree to the Terms of Use and Privacy Policy");
      } else {
        let userData = {
          method: 0,
          first_name: context.name_first,
          middle_name: context.name_middle,
          last_name: context.name_last,
          type: context.type_org,
          company_name:
            context.type_org === "0" ? "Individual" : context.name_org,
          domain: context.type_org === "0" ? "" : context.web_org,
          country: context.country,
          email: context.email,
          mobile_no: context.phone,
          password: context.pass,
          // created_by: "123",
        };
        console.log("Passed Data: ", userData);
         logiApi(userData);
      }
   };
  
   function logiApi(userData) {
     AxiosInstance.post("api/aam/user", userData)
       .then((res) => {
         console.log(res);
         if (res.data.status)
         {
          localStorage.setItem("user", JSON.stringify(userData));
           let path = `/verify`;
           navigate(path);
         }
         else {
           //textInputPassword.current.focus();
           alert(res.data.message);
         }
       })
       .catch((error) => {
         return error;
       });
   }
  
  return (
    // <div className="container">
    <div className="c_row">
      <div className="col-lg-4 col-md-12 left_banner">
        {/* <div className="left_banner"></div> */}
        <div className="right_img">
          <img className="right_img" src={Logo} alt="Banners" />
        </div>
        <div className="title_sec">
          <p className="title_banner">Start your </p>
          <p className="title_banner_sec">journey with us.</p>
          <p className="para_text">
            Explore automation solutions that can help you meet your goals.
            Automate your work and build something new.
          </p>
        </div>
      </div>
      <div className="col-lg-8 col-md-12">
        <div className="act_content">
          <p className="act_title">Create Your Account</p>
          <div className="act_sub_div">
            <p className="act_sub_title">Already have an account?</p>
            <a href="/login" className="act_sub_title_create">
              Log in now
            </a>
          </div>
          <div className="row row_top">
            <div className="col-md-4">
              <label for="input_first" className="act_labelText">
                First Name
              </label>
              <input
                ref={textInputFirst}
                value={context.name_first}
                onChange={handleChange}
                className="act_InputText"
                name="input_first"
                id="input_first"
                placeholder="Enter First Name"
              />
            </div>
            <div className="col-md-4">
              <label for="input_middle" className="act_labelText">
                Middle Name
              </label>
              <input
                ref={textInputMiddle}
                value={context.name_middle}
                onChange={handleChange}
                className="act_InputText"
                name="input_middle"
                id="input_middle"
                placeholder="Enter Middle Name"
              />
            </div>
            <div className="col-md-4">
              <label for="input_last" className="act_labelText">
                Last Name
              </label>
              <input
                ref={textInputLast}
                value={context.name_last}
                onChange={handleChange}
                className="act_InputText"
                name="input_last"
                id="input_last"
                placeholder="Enter Last Name"
              />
            </div>
          </div>
          <div className="row row_sub_top">
            <div className="col-md-6">
              <label for="select_type_org" className="act_labelText">
                Organization Type
              </label>
              <select
                required
                name="select_type_org"
                id="select_type_org"
                className="act_InputText"
                onChange={handleChange}
                value={context.type_org}
              >
                {/* <option value="" disabled selected hidden> */}
                <option value="">Select Organization Type</option>
                <option value="0">Individual</option>
                <option value="1">Organization</option>
              </select>
            </div>
            <div className="col-md-6">
              <label for="select_country" className="act_labelText">
                Country
              </label>
              <select
                required
                name="select_country"
                id="select_country"
                className="act_InputText"
                onChange={handleChange}
                value={context.country}
              >
                {/* <option value="" disabled selected hidden> */}
                <option value="">Select Country</option>
                <option value="usa">USA</option>
              </select>
            </div>
          </div>
          {actions.org_type === "1" ? (
            <div className="row row_sub_top">
              <div className="col-md-6">
                <label for="input_org" className="act_labelText">
                  Organization Name
                </label>
                <input
                  ref={textInputOrg}
                  value={context.name_org}
                  onChange={handleChange}
                  className="act_InputText"
                  name="input_org"
                  id="input_org"
                  placeholder="Enter Organization Name"
                />
              </div>
              <div className="col-md-6">
                <label for="input_web" className="act_labelText">
                  Website
                </label>
                <input
                  ref={textInputWeb}
                  value={context.web_org}
                  onChange={handleChange}
                  className="act_InputText"
                  name="input_web"
                  id="input_web"
                  placeholder="Enter Website"
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="row row_sub_top">
            <div className="col-md-6">
              <label for="input_phone" className="act_labelText">
                Phone Number
              </label>
              <select className="act_InputText_short">
                <option value="US">US(+1)</option>
              </select>
              <input
                ref={textInputPhone}
                value={context.phone}
                onChange={handleChange}
                className="act_InputText_Long"
                name="input_phone"
                id="input_phone"
                placeholder="Enter Phone Number"
              />
            </div>
            <div className="col-md-6">
              <label for="input_email" className="act_labelText">
                Email Id
              </label>
              <input
                ref={textInputEmail}
                value={context.email}
                onChange={handleChange}
                className="act_InputText"
                name="input_email"
                id="input_email"
                placeholder="Enter Email Id"
              />
            </div>
          </div>
          <div className="row row_sub_top">
            <div className="col-md-6">
              <label for="input_password" className="act_labelText">
                Password
              </label>
              <input
                ref={textInputPassword}
                value={context.pass}
                onChange={handleChange}
                type={context.eye_new ? "text" : "password"}
                className="act_InputText"
                name="input_password"
                id="input_password"
                placeholder="Enter Password"
              />
              {context.eye_new === true ? (
                <img
                  className="lg_password_eye"
                  src={img_eye_slash}
                  onClick={eyeChange}
                />
              ) : (
                <img
                  className="lg_password_eye"
                  src={img_eye}
                  onClick={eyeChange}
                />
              )}
            </div>
            <div className="col-md-6">
              <label for="input_cpassword" className="act_labelText">
                Confirm Password
              </label>
              <input
                ref={textInputCPassword}
                value={context.cpass}
                onChange={handleChange}
                type={context.eye_confirm ? "text" : "password"}
                className="act_InputText"
                name="input_cpassword"
                id="input_cpassword"
                placeholder="Re-Enter Password"
              />
              {context.eye_confirm === true ? (
                <img
                  className="lg_password_eye"
                  src={img_eye_slash}
                  onClick={eyeChangeConfirm}
                />
              ) : (
                <img
                  className="lg_password_eye"
                  src={img_eye}
                  onClick={eyeChangeConfirm}
                />
              )}
            </div>
          </div>
          <div className="act_sub_div">
            <input
              checked={context.remember}
              onChange={handleChange}
              type="checkbox"
              className="act_checkbox"
              id="remember"
              name="remember"
            />
            <p className="act_sub_title">I agree to the</p>
            <a href="/signup" className="act_sub_title_create">
              Terms of Use
            </a>
            <p style={{ marginLeft: "5px" }}>and</p>
            <a href="/signup" className="act_sub_title_create">
              Privacy Policy
            </a>
          </div>
          <div className="lg_submit_bg">
            <button className="lg_submit" onClick={routeChange}>
              Create Account
            </button>
          </div>
        </div>
      </div>
    </div>
    // </div>
  );
}
export default Account;
