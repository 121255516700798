import "./User.css";

import React, { useEffect, useRef, useState } from "react";

import { AxiosInstance } from "../../axios/index";
import SearchIcon from "@mui/icons-material/Search";
import add from "../../assets/add.png";
import tbl_view from "../../assets/table_view.svg";
import { useNavigate } from "react-router-dom";
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

function User() {
  let navigate = useNavigate();

  let Users = null;
  const userJSON = localStorage.getItem("userDetails");
  if (userJSON) {
    // setUser(JSON.parse(userJSON));
    Users = JSON.parse(userJSON);
  } else {
    console.log("User Id cannot available in localstorge");
    // setUser({});
    Users = null;
  }
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const [user_list, setuser_list] = useState(null);
  const [sortOrder, setSortOrder] = useState("asc");
  const [searchTerm, setSearchTerm] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const [updateChipData, setUpdateChipData] = useState([]);
  const [company_nameon, setCompany_nameon] = useState([]);
  const [company_name, setcompany_name] = useState([]);
  const [filter_roleon, setFilter_roleon] = useState([]);
  const [filter_role, setFilter_role] = useState([]);
  const [fullname, setFullname] = useState([]);
  const [fullnameon, setFullnameon] = useState([]);
  const [isActive, setIsActive] = useState([]);
  const [isActiveon, setIsActiveon] = useState([]);

  // const [showFilterPopup, setShowFilterPopup] = useState(false);

  const [isOpen, setIsOpen] = useState({
    company_name: false,
    filter_role: false,
    fullname: false,
    isActive: false,
  });

  function loadListdata() {
    let tempcompany_nameon = [];
    company_nameon.map((obj) => {
      tempcompany_nameon.push(obj.id);
    });
    let tempfilter_roleon = [];
    filter_roleon.map((obj) => {
      tempfilter_roleon.push(obj.id);
    });
    let tempfullnameon = [];
    fullnameon.map((obj) => {
      tempfullnameon.push(obj.id);
    });
    let tempisActiveon = [];
    isActiveon.map((obj) => {
      tempisActiveon.push(obj.id);
    });
    let statusFilter = {
      method: 7,
      user_id: Users.data.user_id,
      search: searchTerm,
      company_name: company_nameon.length > 0 ? tempcompany_nameon : "",
      filter_role: filter_roleon.length > 0 ? tempfilter_roleon : "",
      fullname: fullnameon.length > 0 ? tempfullnameon : "",
      isActive: isActiveon.length > 0 ? tempisActiveon : "",

    };
    AxiosInstance.post("api/aam/user", statusFilter)
      .then((res) => {
        console.log("success ", res);
        if (res.data.status === true) {
          const fiata = res.data.data;
          setuser_list(fiata);
        } else if (res.data.status === false) {
          setuser_list([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setuser_list([]);
        return error;
      });
  }

  let filtercompany_name = [];
  let filterfilter_role = [];
  let filterfullname = [];
  let filterisActive = [];
  function updateStateData() {
    filtercompany_name = company_nameon;
    filterfilter_role = filter_roleon;
    filterfullname = fullnameon;
    filterisActive = isActiveon;
  }

  useEffect(() => {
    loadListdata();
  }, [updateChipData]);

  useEffect(() => {
    filtercompany_name = company_nameon;
  }, [company_nameon]);
  useEffect(() => {
    filterfilter_role = filter_roleon;
  }, [filter_roleon]);
  useEffect(() => {
    filterfullname = fullnameon;
  }, [fullnameon]);
  useEffect(() => {
    filterisActive = isActiveon;
  }, [isActiveon]);

  function loadChipdata() {
    let tempArr = [];
    filtercompany_name.map((obj) => {
      tempArr.push(obj);
    });
    filterfilter_role.map((obj) => {
      tempArr.push(obj);
    });
    filterfullname.map((obj) => {
      tempArr.push(obj);
    });
    filterisActive.map((obj) => {
      tempArr.push(obj);
    });
    setUpdateChipData(tempArr);
  }

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    // console.log(name, "name........");
    // console.log(value, "value........");
    if (names === "company_name") {
      if (checked) {
        setCompany_nameon([...company_nameon, { id: value, name: name, company_name: "company_name" }]);
      } else {
        setCompany_nameon(company_nameon.filter((item) => item.id !== value));
      }
    }
    else if (names === "filter_role") {
      if (checked) {
        setFilter_roleon([...filter_roleon, { id: value, name: name, filter_role: "filter_role" }]);
      } else {
        setFilter_roleon(filter_roleon.filter((item) => item.id !== value));
      }
    }
    else if (names === "fullname") {
      if (checked) {
        setFullnameon([...fullnameon, { id: value, name: name, fullname: "fullname" }]);
      } else {
        setFullnameon(fullnameon.filter((item) => item.id !== value));
      }
    }
    else if (names === "isActive") {
      if (checked) {
        setIsActiveon([...isActiveon, { id: value, name: name, isActive: "isActive" }]);
      } else {
        setIsActiveon(isActiveon.filter((item) => item.id !== value));
      }
    }
  }

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "company_name") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        company_name: false,
      });
    } else if (name === "filter_role") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        filter_role: false,
      });
    }
    else if (name === "fullname") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        fullname: false,
      });
    }
    else if (name === "isActive") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        isActive: false,
      });
    }
  };

  const handleClear = (name) => {
    updateStateData();
    if (name === "company_name") {
      setCompany_nameon([]);
      filtercompany_name = [];

      setIsOpen({
        ...isOpen,
        company_name: false,
      });
    }
    else if (name === "filter_role") {
      setFilter_roleon([]);
      filterfilter_role = [];

      setIsOpen({
        ...isOpen,
        filter_role: false,
      });
    }
    else if (name === "fullname") {
      setFilter_roleon([]);
      filterfullname = [];

      setIsOpen({
        ...isOpen,
        fullname: false,
      });
    }
    else if (name === "isActive") {
      setFilter_roleon([]);
      filterisActive = [];

      setIsOpen({
        ...isOpen,
        isActive: false,
      });
    }
  }

  const handleFilter = (e, boxName) => {
    if (boxName === "company_name") {
      setIsOpen((prevState) => ({
        ...prevState,
        company_name: !prevState.company_name,
      }));
    }
    if (boxName === "filter_role") {
      setIsOpen((prevState) => ({
        ...prevState,
        filter_role: !prevState.filter_role,
      }));
      // console.log(filter_role,"filter_role");
    }
    if (boxName === "fullname") {
      setIsOpen((prevState) => ({
        ...prevState,
        fullname: !prevState.fullname,
      }));
    }
    if (boxName === "isActive") {
      setIsOpen((prevState) => ({
        ...prevState,
        isActive: !prevState.isActive,
      }));
    }
  }

  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.company_name === "company_name") {
      setCompany_nameon(company_nameon.filter((item) => item.id !== chipToDelete.id));
      filtercompany_name = company_nameon.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.filter_role === "filter_role") {
      setFilter_roleon(filter_roleon.filter((item) => item.id !== chipToDelete.id));
      filterfilter_role = filter_roleon.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.fullname === "fullname") {
      setFullnameon(fullnameon.filter((item) => item.id !== chipToDelete.id));
      filterfullname = fullnameon.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.isActive === "isActive") {
      setIsActiveon(isActiveon.filter((item) => item.id !== chipToDelete.id));
      filterisActive = isActiveon.filter((item) => item.id !== chipToDelete.id);
    }
    loadChipdata();

  }

  const handleAllClear = () => {
    setuser_list([]);
  };

  const pageChange = () => {
    const path = "/dashboard/AddUser";
    navigate(path);
  };

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  useEffect(() => {
    getCloudList();
  }, [currentPage, itemsPerPage]);

  const handlePageCount = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    console.log(newItemsPerPage, "***************");
    setCurrentPage(1);
  };

  function getCloudList() {
    let tempcompany_nameon = [];
    company_nameon.map((obj) => {
      tempcompany_nameon.push(obj.id);
    });
    let tempfilter_roleon = [];
    filter_roleon.map((obj) => {
      tempfilter_roleon.push(obj.id);
    });
    let tempfullnameon = [];
    fullnameon.map((obj) => {
      tempfullnameon.push(obj.id);
    });
    let tempisActiveon = [];
    isActiveon.map((obj) => {
      tempisActiveon.push(obj.id);
    });
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const data = {
      method: 7,
      user_id: Users.data.user_id,
      search: searchTerm,
      company_name: company_nameon.length > 0 ? tempcompany_nameon : "",
      filter_role: filter_roleon.length > 0 ? tempfilter_roleon : "",
      fullname: fullnameon.length > 0 ? tempfullnameon : "",
      isActive: isActiveon.length > 0 ? tempisActiveon : "",
    };
    AxiosInstance.post("api/aam/user", data)
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          const dataList = res.data.data.slice(startIndex, endIndex);
          setuser_list(dataList);
          const uniquecompany_nameValues = new Set();
          dataList.forEach((item) => {
            uniquecompany_nameValues.add(item.company_name);
          });
          const uniquecompany_nameArray = Array.from(uniquecompany_nameValues);
          setcompany_name(uniquecompany_nameArray);

          const uniquefilter_roleValues = new Set();
          dataList.forEach((item) => {
            if (item.filter_role !== null) {
              uniquefilter_roleValues.add(item.filter_role);
            }
          });
          const uniquefilter_roleArray = Array.from(uniquefilter_roleValues);
          setFilter_role(uniquefilter_roleArray);

          const uniquefullnameValues = new Set();
          dataList.forEach((item) => {
            uniquefullnameValues.add(item.fullname);
          });
          const uniquefullnameArray = Array.from(uniquefullnameValues);
          setFullname(uniquefullnameArray);

          const uniqueisActiveValues = new Set();
          dataList.forEach((item) => {
            uniqueisActiveValues.add(item.isActive);
          });
          const uniqueisActiveArray = Array.from(uniqueisActiveValues);
          setIsActive(uniqueisActiveArray);


          setTotalRecords(res.data.data.length);
        } else {
          setuser_list(null);
        }
      })
      .catch((error) => {
        return error;
      });
  }

  // handle search function
  const handleSearch = (event) => {

    if (event.key === "Enter") {
      let val = event.target.value;
      setSearchTerm(val); // Update the search term state
      getCloudList(); // Fetch user list based on the search term
    }
  };
  //sorting function
  const handleSort = (sortKey) => {
    const sortedList = [...user_list];
    sortedList.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return sortOrder === "asc" ? -1 : 1;
      }
      if (a[sortKey] > b[sortKey]) {
        return sortOrder === "asc" ? 1 : -1;
      }
      return 0;
    });

    setuser_list(sortedList);
    setSortOrder(sortOrder === "asc" ? "desc" : "asc");
  };




  const handleView = (id) => {
    // console.log(id, "id");

    // clouds view detail -- 0  keyword
    // navigate(`/dashboard/ViewDetail/${id}/${0}`);
    const path = `/dashboard/users/${id}`;
    navigate(path);
  };
  return (
    <>
      <div className="bodycontent">
        <div className="ur_div">
          <p className="topic_title">Users</p>
        </div>
        <div className="table_parent_div">
          <div className="row">
            <div className="col-md-8">
              <div className="ur_table_serach">
                <input
                  type="text"
                  placeholder="Search by User name"
                  onKeyDown={handleSearch}
                />
                <SearchIcon className="ur_table_serach_img" />
              </div>
            </div>

            <div className="col-md-4 d-flex justify-content-end">
              <button className="add_new me-3"
                style={{
                  display: Users && (Users.data.user_type === 2 || Users.data.user_type === 3) ? "" : "none",
                }}
                onClick={pageChange}>
                <img src={add} alt="add new" />
                <span>Add New</span>
              </button>
            </div>
          </div>
          {updateChipData.length > 0 ? (
            <div className="row mt-3">
              <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
                <Paper
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    flexWrap: "wrap",
                    listStyle: "none",
                    p: 0.5,
                    m: 0,
                  }}
                  component="ul"
                >
                  {updateChipData.map((data, index) => {
                    // console.log(data, " - data - ", index);
                    let icon;
                    let key = "" + data.name;
                    return (
                      <ListItem key={data}>
                        <Chip
                          icon={icon}
                          label={key}
                          onDelete={handleDelete(data, index)}
                        />
                      </ListItem>
                    );
                  })}

                  <ListItem>
                    {/* <Chip
                      label="clear all filter"
                      onClick={handleAllClear}
                    /> */}
                    <a className="allclear" href="./Users" onClick={handleAllClear}>
                      clear all filter
                    </a>
                  </ListItem>
                </Paper>
              </div>
            </div>
          ) : (
            <div></div>
          )}
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12 table-responsive">
              <table className="table table-striped  table-hover" id="script">
                <thead>
                  <tr>
                    {/* <th id="th">
                      id
                      <span
                        class="material-icons align-middle"
                        onClick={(e) => handleSort(e, "fullname")}
                      >
                        import_export
                      </span>
                    </th> */}
                    <th id="th">
                      Username
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("username")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th">
                      Phone number
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("mobile_no")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th">
                      Email id
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("email")}
                      >
                        import_export
                      </span>
                    </th>
                    <th id="th">
                      Organization name
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("company_name")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${company_nameon.length > 0 ? "active_icons" : ""}`}
                        onClick={(e) => handleFilter(e, "company_name")}
                      >
                        filter_list
                      </span>
                      {isOpen.company_name === true && (
                        <div className="filter-popup">
                          <div className="row mt-2">
                            {company_name.map((el) => (
                              <div className="col-md-12">
                                <div class="filter-option">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name={el}
                                    value={el}
                                    id={el}
                                    style={{ cursor: "pointer" }}
                                    checked={company_nameon.some((element) => {
                                      if (element.name === el) {
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "company_name")
                                    }

                                  />
                                  <label
                                    class="form-check-label YellowText"
                                    for="defaultCheck1"
                                  >
                                    {el}
                                  </label>
                                </div>
                              </div>
                            ))}

                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <span
                                className="clbutton "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("company_name")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8">
                              <button
                                className="apbutton"
                                disabled={company_nameon.length === 0 ? true : false}
                                onClick={() => handleApplyFilter("company_name")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      Role
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("filter_role")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${filter_roleon.length > 0 ? "active_icons" : ""}`}
                        onClick={(e) => handleFilter(e, "filter_role")}
                      >
                        filter_list
                      </span>
                      {isOpen.filter_role === true && (
                        <div className="filter-popup">
                          <div className="row mt-2">
                            {filter_role.map((el) => (
                              <div className="col-md-12">
                                <div class="filter-option">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name={el}
                                    value={el}
                                    id={el}
                                    style={{ cursor: "pointer" }}
                                    checked={filter_roleon.some((element) => {
                                      if (element.name === el) {
                                        // console.log(el,"elements");
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "filter_role")
                                    }

                                  />
                                  <label
                                    class="form-check-label YellowText"
                                    for="defaultCheck1"
                                  >
                                    {el}
                                  </label>
                                </div>
                              </div>
                            ))}

                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <span
                                className="clbutton "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("filter_role")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8">
                              <button
                                className="apbutton"
                                disabled={filter_roleon.length === 0 ? true : false}
                                onClick={() => handleApplyFilter("filter_role")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      Added by
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("fullname")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${fullnameon.length > 0 ? "active_icons" : ""}`}
                        onClick={(e) => handleFilter(e, "fullname")}
                      >
                        filter_list
                      </span>
                      {isOpen.fullname === true && (
                        <div className="filter-popup">
                          <div className="row mt-2">
                            {fullname.map((el) => (
                              <div className="col-md-12">
                                <div class="filter-option">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name={el}
                                    value={el}
                                    id={el}
                                    style={{ cursor: "pointer" }}
                                    checked={fullnameon.some((element) => {
                                      if (element.name === el) {
                                        // console.log(el,"elements");
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "fullname")
                                    }

                                  />
                                  <label
                                    class="form-check-label YellowText"
                                    for="defaultCheck1"
                                  >
                                    {el}
                                  </label>
                                </div>
                              </div>
                            ))}

                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <span
                                className="clbutton "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("fullname")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8">
                              <button
                                className="apbutton"
                                disabled={fullnameon.length === 0 ? true : false}
                                onClick={() => handleApplyFilter("fullname")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                    <th id="th">
                      Status
                      <span
                        class="material-icons align-middle"
                        onClick={() => handleSort("isActive")}
                      >
                        import_export
                      </span>
                      <span
                        class={` align-middle material-icons  ${isActiveon.length > 0 ? "active_icons" : ""}`}
                        onClick={(e) => handleFilter(e, "isActive")}
                      >
                        filter_list
                      </span>
                      {isOpen.isActive === true && (
                        <div className="filter-popup">
                          <div className="row mt-2">
                            {isActive.map((el) => (
                              <div className="col-md-12">
                                <div class="filter-option">
                                  <input
                                    class="form-check-input"
                                    type="checkbox"
                                    name={el}
                                    value={el}
                                    id={el}
                                    style={{ cursor: "pointer" }}
                                    checked={isActiveon.some((element) => {
                                      if (element.name == el) {
                                        // console.log(el,"elements");
                                        return true;
                                      }
                                      return false;
                                    })}
                                    onChange={(e) =>
                                      handleCheckboxChange(e, "isActive")
                                    }

                                  />
                                  <label
                                    class="form-check-label YellowText"
                                    for="defaultCheck1"
                                  >
                                    {el}
                                  </label>
                                </div>
                              </div>
                            ))}

                          </div>
                          <hr />
                          <div className="row mt-3">
                            <div className="col-md-4">
                              <span
                                className="clbutton "
                                style={{ cursor: "pointer" }}
                                onClick={() => handleClear("isActive")}
                              >
                                Clear
                              </span>
                            </div>
                            <div className="col-md-8">
                              <button
                                className="apbutton"
                                disabled={isActiveon.length === 0 ? true : false}
                                onClick={() => handleApplyFilter("isActive")}
                              >
                                Apply Filter
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {user_list &&
                    user_list.map((obj, index) => {
                      return (
                        <tr className="td_div_action">
                          <td>{obj.username}</td>
                          <td>{obj.mobile_no}</td>
                          <td>{obj.email}</td>
                          <td>{obj.company_name}</td>
                          <td>{obj.filter_role}</td>
                          <td>{obj.fullname}</td>
                          <td><button className={(obj.isActive === 1 ? "usr_btn_act" : "usr_btn_inact")}>{(obj.isActive === 1 ? "Active" : "Inactive")}</button></td>
                          <td>
                            <div className="td_div_action_div">
                              <a
                                href="javascript:void(0)"
                                className="td_div_action_a"
                              >
                                <img
                                  onClick={() => handleView(obj.id)}
                                  src={tbl_view}
                                />
                              </a>
                            </div>
                          </td>
                        </tr>
                      );
                    })}
                </tbody>
              </table>
            </div>
          </div>
          <div className="row mt-3 ms-1 me-1">
            <div className="col-md-12">
              <div className="pag w-100 d-flex justify-content-end">
                <span className="ipage">Items Per page:</span>
                <select
                  name="pageCount"
                  id="pageCount"
                  defaultValue={itemsPerPage}
                  onChange={handlePageCount}
                  className="ms-2 select_drop"
                >
                  {/* <option value="5">5</option> */}
                  <option value="10">10</option>
                  <option value="20">20</option>
                  <option value="50">50</option>
                  <option value="100">100</option>
                </select>
                <span className="pagenumber ms-2">
                  {totalRecords === 0
                    ? "No records found"
                    : `${Math.min(
                      currentPage * itemsPerPage - itemsPerPage + 1,
                      totalRecords
                    )} - ${Math.min(
                      currentPage * itemsPerPage,
                      totalRecords
                    )} of ${totalRecords}`}
                </span>
                <button className="prev_button ms-2 me-2" onClick={handlePrev} disabled={currentPage === 1}>
                  <span class="material-icons">chevron_left</span>
                </button>
                <button className="prev_button" onClick={handleNext} disabled={currentPage === totalPages}>
                  <span class="material-icons">chevron_right</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
export default User;
