import React from 'react';

const Rating = ({ rating }) => {
  const maxRating = 5; // The maximum rating value (you can adjust this)
  const fullStars = Math.floor(rating); // Number of full stars
  const hasHalfStar = rating - fullStars >= 0.5; // Check for half star
    // console.log(rating,"rating.....");
  const renderStars = () => {
    const stars = [];

    // Add full stars
    for (let i = 0; i < fullStars; i++) {
      stars.push(
        <span key={`star-${i}`} className="material-icons">
          star
        </span>
      );
    }

    // Add half star if applicable
    if (hasHalfStar) {
      stars.push(
        <span key={`star-half`} className="material-icons">
          star_half
        </span>
      );
    }

    // Add empty stars to fill the rating bar
    // const emptyStars = maxRating - fullStars - (hasHalfStar ? 1 : 0);
    // for (let i = 0; i < emptyStars; i++) {
    //   stars.push(
    //     <span key={`star-empty-${i}`} className="material-icons">
          
    //     </span>
    //   );
    // }

    return stars;
  };

  return <div className="rating">{renderStars()}</div>;
};

export default Rating;
