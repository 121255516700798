import './App.css';
import "material-icons/iconfont/material-icons.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle";
import "bootstrap/dist/css/bootstrap.min.css";

import { BrowserRouter, Route, Routes } from "react-router-dom";

import Account from './pages/Account/Account';
import AddCategory from "./pages/Admin/AddCategory";
import AddCloud from "./pages/Admin/AddCloud";
import AddProvisioner from "./pages/Admin/AddProvisioner";
import AddRbac from "./pages/RBAC/AddRbac";
import ListRbac from "./pages/RBAC/listRbac";
import ViewRbac from "./pages/RBAC/ViewRbac";
import AddServiceType from './pages/Admin/AddServiceType';
import AddSubCategory from "./pages/Admin/AddSubCategory";
import AddUser from "./pages/User/AddUser";
import EditUser from "./pages/User/EditUser";
import ViewUser from "./pages/User/ViewUser";
import Addscript from "./pages/Admin/Addscript";
import Authenticate from "./pages/Login/Authenticate";
import Category from "./pages/Admin/Category";
import Cloud from "./pages/Admin/Cloud";
import Dashboard from "./layouts/Dashboard";
import ForgotPassword from './pages/Login/ForgotPassword';
import Home from './pages/Home/Home';
import HomeLayout from "./layouts/HomeLayout";
import Login from './pages/Login/Login';
import Provisioner from "./pages/Admin/Provisioner";
import RBAC from "./pages/RBAC/Rbac";
import RequireAuth from "./context/auth";
import ResetPassword from "./pages/Login/ResetPassword";
import Script from "./pages/Admin/Script";
import ServiceType from './pages/Admin/ServiceType';
import SubCategory from "./pages/Admin/SubCategory";
import Terms from "./pages/Account/Terms";
import User from "./pages/User/User";
import UserDetailsContextProvider from "./context/UserDetails"
import Verify from "./pages/Login/Verify";
import ViewDetail from './pages/Admin/ViewDetail';
import ViewScriptDetail from './pages/Admin/ViewScriptDetail';

function App() {
  return (
    <div className="App">
      <UserDetailsContextProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/auth" element={<Authenticate />}></Route>
            <Route path="/forgotpassword" element={<ForgotPassword />}></Route>
            <Route
              path="/resetpassword/:id"
              element={<ResetPassword />}
            ></Route>
            <Route path="/signup" element={<Account />}></Route>
            <Route path="/terms" element={<Terms />}></Route>
            <Route path="/verify" element={<Verify />}></Route>
            <Route path="/" element={<Home />}></Route>
            <Route
              path="/dashboard"
              element={
                <RequireAuth>
                  <Dashboard />
                </RequireAuth>
              }
            >
              <Route index element={<HomeLayout />} />
              <Route
                path="cloud"
                element={
                  <RequireAuth>
                    <Cloud />
                  </RequireAuth>
                }
              ></Route>

              <Route
                path="ViewDetail/:id/:keyword"
                element={
                  <RequireAuth>
                    <ViewDetail />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="cloud_detail"
                element={
                  <RequireAuth>
                    <AddCloud />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="cloud_detail/:id"
                element={
                  <RequireAuth>
                    <AddCloud />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="script"
                element={
                  <RequireAuth>
                    <Script />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="addscript"
                element={
                  <RequireAuth>
                    <Addscript />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="addscript/:id"
                element={
                  <RequireAuth>
                    <Addscript />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="provisioner"
                element={
                  <RequireAuth>
                    <Provisioner />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="provisioner_detail"
                element={
                  <RequireAuth>
                    <AddProvisioner />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="provisioner_detail/:id"
                element={
                  <RequireAuth>
                    <AddProvisioner />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="category"
                element={
                  <RequireAuth>
                    <Category />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="category_detail"
                element={
                  <RequireAuth>
                    <AddCategory />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="category_detail/:id"
                element={
                  <RequireAuth>
                    <AddCategory />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="sub category"
                element={
                  <RequireAuth>
                    <SubCategory />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="subcategory_detail"
                element={
                  <RequireAuth>
                    <AddSubCategory />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="subcategory_detail/:id"
                element={
                  <RequireAuth>
                    <AddSubCategory />
                  </RequireAuth>
                }
              ></Route>

              <Route
                path="Service type"
                element={
                  <RequireAuth>
                    <ServiceType />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="ServiceType_detail"
                element={
                  <RequireAuth>
                    <AddServiceType />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="ServiceType_detail/:id"
                element={
                  <RequireAuth>
                    <AddServiceType />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="ViewScriptDetail/:id"
                element={
                  <RequireAuth>
                    <ViewScriptDetail />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="users/"
                element={
                  <RequireAuth>
                    <User />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="EditUser/:id"
                element={
                  <RequireAuth>
                    <EditUser />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="AddUser"
                element={
                  <RequireAuth>
                    <AddUser/>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="users/:id"
                element={
                  <RequireAuth>
                    <ViewUser />
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="rbac/"
                element={
                  <RequireAuth>
                    <RBAC />
                  </RequireAuth>
                }
              ></Route>
               <Route
                path="addrbac/:0"
                element={
                  <RequireAuth>
                    <AddRbac/>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="rbac/:id"
                element={
                  <RequireAuth>
                    <ListRbac/>
                  </RequireAuth>
                }
              ></Route>
              <Route
                path="viewRbac/:ids"
                element={
                  <RequireAuth>
                    <ViewRbac/>
                  </RequireAuth>
                }
              ></Route>
            </Route>
          </Routes>
        </BrowserRouter>
      </UserDetailsContextProvider>
    </div>
  );
}

export default App;
