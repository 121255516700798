import "./Rbac.css";

import React, { useEffect, useRef, useState } from "react";
import { useFetcher, useNavigate, useParams } from "react-router-dom";

import { AxiosInstance } from "../../axios/index";
import Footer from "../../hooks/Footer";
import Header from "../../hooks/Header";
import Logo from "../../assets/Group 12@2x.png";
import Swal from "sweetalert2";

function AddRbac() {
    const { ids } = useParams();
    const navigate = useNavigate();
    const userJSON = localStorage.getItem("userDetails");
    let userid = null;
  
    if (userJSON) {
      const user = JSON.parse(userJSON);
      // console.log(user , "ssafsafasfasf");
      if (user.data && user.data.user_id) {
        userid = user.data.user_id;
      }
    } else {
      console.log("UserId Not available");
    }
    // console.log(keyword,"*********");
    // console.log(id,"*******");

    const [formData, setFormData] = useState({
        rolename: '',
        status: '',
        desc: '',
        menu_list: []
    });
    // const [menu_list, setcompany_name] = useState([]);
    const textInputName = useRef(null);
    const textInputstatus = useRef(null);
    const textInputdes = useRef(null);

    const handleChange = (event) => {
        const { name, value, type, checked } = event.target;
        if (name === "input_name") {
            setFormData({ ...formData, rolename: value });
        } else if (name === "input_status") {
            setFormData({ ...formData, status: value });
        }
        else if (name === "input_des") {
            setFormData({ ...formData, desc: value });
        }
    };


    const handleCheckboxChange = (event, index, fieldName) => {
        const { name, value, type, checked } = event.target;
        setFormData(prevState => {
            const updatedMenuList = [...prevState.menu_list];

            // updatedMenuList[index][fieldName] = !updatedMenuList[index][fieldName];
            if (checked) {
                updatedMenuList[index][fieldName] = 1;
                return { ...prevState, menu_list: updatedMenuList };
            }
            else{
                updatedMenuList[index][fieldName] = 0;
                return { ...prevState, menu_list: updatedMenuList };
            }
        });
    };
    // useEffect(() => {
    //     const bb = {
    //         "method": 2,
    //         "id": ids
    //     };

    //     AxiosInstance.post("api/aam/master/role", bb)
    //         .then((res) => {
    //             // console.log(res);
    //             if (res.data.status) {
    //                 const roleId = res.data.data[0].id; // Extract the role ID
    //                 setFormData({
    //                     rolename: res.data.data[0].name,
    //                     status: res.data.data[0].status,
    //                     desc: res.data.data[0].description,
    //                     id: roleId
    //                 });
    //                 console.log(roleId, "Role_id");
    //                 // Use the extracted roleId in the second request
    //                 AxiosInstance.post("api/aam/master/menu", {
    //                     "method": 0
    //                 })
    //                     .then((res) => {
    //                         console.log(res); // Log the response from the second request
    //                         if (res.data.status === true) {
    //                             setFormData(prevFormData => ({
    //                                 ...prevFormData, // Preserve existing formData properties
    //                                 menu_list: res.data.data,
    //                             }));
    //                         }
    //                     })
    //                     .catch((error) => {
    //                         console.error(error);
    //                     });
    //             }
    //         })
    //         .catch((error) => {
    //             console.error(error);
    //         });
    // }, [ids]);
    useEffect(() => {
        AxiosInstance.post("api/aam/master/menu", {
            "method": 0
        })
            .then((res) => {
                console.log(res); // Log the response from the second request
                if (res.data.status === true) {
                    const initialMenuList = res.data.data.map(menuItem => ({
                        ...menuItem,
                        access: 0,
                        deletes: 0,
                        edit: 0,
                        export: 0,
                        import: 0,
                        list: 0,
                        view: 0,
                        mass_update: 0
                    }));
                    setFormData(prevFormData => ({
                        ...prevFormData, // Preserve existing formData properties
                        menu_list:initialMenuList,
                    }))
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);
    console.log(formData.menu_list, "Menu_list");
    const routeChange=()=>{

        const filteredMenuList = formData.menu_list.filter(menuItem => {
            return (
                menuItem.access === 1 ||
                menuItem.deletes === 1 ||
                menuItem.edit === 1 ||
                menuItem.update === 1 ||
                menuItem.mass_update === 1 ||
                menuItem.view === 1 ||
                menuItem.import === 1 ||
                menuItem.export === 1
            );
        });
        console.log(filteredMenuList,"filtered menulist.....")
        let role={
            method:4,
            name:formData.rolename,
            description:formData.desc,
            status:formData.status,
            created_by:userid,
            role_id:0,
            menu_access:filteredMenuList
        }
        AxiosInstance.post("api/aam/master/role", role)
        .then((res) => {
            if (res.data.status === true) {
                Swal.fire({
                    title: "Success",
                    text: res.data.message,
                    icon: "success",
                    confirmButtonText: "Ok",
                }).then((result) => {
                    if (result.isConfirmed) {
                        navigateToPreviousScreen();
                    }
                });
            }
        })
        .catch((error) => {
            return error;
        });

    }

    
    const navigateToPreviousScreen = () => {
        navigate(-1); // Navigate back by one step in the history
    };

    return (
        <div className="bodycontent">
            <div className="rbac_div">
                <p className="back_button" onClick={navigateToPreviousScreen}>
                    <span className="material-icons">keyboard_backspace</span>Back to Roles
                </p>
            </div>
            <div className="cld_div justify-content-between">
                <p className="topic_title">
                    {"Add Role"}
                </p>
            </div>
            <div className="rbac_content">
                <div className="row">
                    <div className="col-md-6 inp_grp">
                        <label for="input_name" className="rbac_labelText">
                            Role Name
                        </label>
                        <input className="rbac_InputText"
                            //   value={formData.rolename }
                            ref={textInputName}
                            onChange={handleChange}
                            name="input_name"
                        />
                    </div>
                    <div className="col-md-6 inp_grp">
                        <label for="select_status" className="rbac_labelText">
                            Status
                        </label>
                        <select
                            value={formData.status}
                            className="rbac_InputText"
                            ref={textInputstatus}
                            onChange={handleChange}
                            name="input_status"
                        // onChange={(e) => setFormData(prevFormData => ({ ...prevFormData, status: e.target.value }))}
                        >
                            <option value="" disabled>Please select</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>
                        {/* <input className="rbac_InputText" value={formData.status != "" && formData.status != null ? formData.status : "_"}/> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 inp_grp">
                        <label for="input_des" className="rbac_labelText">
                            Description
                        </label>
                        <input className="rbac_InputText"
                            // value={formData.desc}
                            ref={textInputdes}
                            onChange={handleChange}
                            name="input_des"
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 inp_grp">
                        <label for="input_permission" className="rbac_labelText">
                            Permissions
                        </label>
                        <div className="rbac_box_border">
                            <div className="col-md-12 table-responsive">
                                <table
                                    className="table table-striped  table-hover"
                                    id="script"
                                >
                                    <thead>
                                        <tr>
                                            <th id="th" style={{ width: "10%" }}></th>
                                            <th id="th">access</th>
                                            <th id="th">delete</th>
                                            <th id="th">edit</th>
                                            <th id="th">export</th>
                                            <th id="th">import</th>
                                            <th id="th">list</th>
                                            <th id="th">view</th>
                                            <th id="th">mass_update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.menu_list &&
                                            formData.menu_list.map((obj, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>{obj.name}</td>
                                                        <td>
                                                            <input
                                                                checked={obj.access === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "access")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"acc_" + index}
                                                                name={"acc_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.deletes === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "deletes")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"del_" + index}
                                                                name={"del_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.edit === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "edit")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"edit_" + index}
                                                                name={"edit_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.export === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "export")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"export_" + index}
                                                                name={"export_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.import === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "import")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"import_" + index}
                                                                name={"import_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.list === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "list")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"list_" + index}
                                                                name={"list_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.view === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "view")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"view_" + index}
                                                                name={"view_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.mass_update === 1}
                                                                onChange={(e) => handleCheckboxChange(e, index, "mass_update")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"mupdate_" + index}
                                                                name={"mupdate_" + index}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="rbac_submit_bg">
                <span class="rbac_save_buttons material-icons">save</span>
                <button className="rbac_save_button" onClick={routeChange}>
                    Save
                </button>
            </div>
        </div>



    );
}
export default AddRbac;
