import { Home } from "@mui/icons-material";
import React from "react";
import { Outlet } from "react-router-dom";

import Footer from "../hooks/Footer";
import Header from "../hooks/Header";

function Dashboard() {
  return (
    <div>
      <Header />
      <Outlet />
      <Footer />
    </div>
  );
}

export default Dashboard;
