import "./HomeLayout.css";

import React, { useEffect, useRef, useState } from "react";

import ReactApexChart from "react-apexcharts";

function HomeLayout() {

  const [series, setseries] = useState([44, 55, 41, 17, 15]);
  const [options, setoptions] = useState({
              chart: {
                width: 380,
                type: 'donut',
              },
              plotOptions: {
                pie: {
                  startAngle: -90,
                  endAngle: 270
                }
              },
              dataLabels: {
                enabled: false
              },
              fill: {
                type: 'gradient',
              },
              legend: {
                formatter: function(val, opts) {
                  return val + " - " + opts.w.globals.series[opts.seriesIndex]
                }
              },
              title: {
                text: 'Gradient Donut with custom Start-angle'
              },
              responsive: [{
                breakpoint: 480,
                options: {
                  chart: {
                    width: 200
                  },
                  legend: {
                    position: 'bottom'
                  }
                }
              }]
            });

  function updateStateData() {
    // filterStatus = status;
    // filterType = typeOn;
    // if (searchTerm.length > 0) {
    //   filterSearch = [{ name: "" + searchTerm, type: "search" }];
    // } else {
    //   filterSearch = [];
    // }
  }

  const handleFilter = (e, boxName) => {
    // if (boxName === "status") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     status: !prevState.status,
    //   }));
    // } else if (boxName === "category") {
    //   setIsOpen((prevState) => ({
    //     ...prevState,
    //     category: !prevState.category,
    //   }));
    // }
  };
  //sorting function
  const handleSort = (event, sortKey) => {
    // event.preventDefault();
    // let order = sortOrder === "asc" ? "desc" : "asc";
    // setSortOrder(order);
    // // setSortColumn(sortKey);
    // let sortedList = updateListData.sort((a, b) => {
    //   if (a[sortKey] < b[sortKey]) {
    //     return order === "asc" ? -1 : 1;
    //   }
    //   if (a[sortKey] > b[sortKey]) {
    //     return order === "asc" ? 1 : -1;
    //   }
    //   return 0;
    // });
    // setUpdateListData(sortedList);
    // setSortColumn(sortKey);
  };



  // handle search function
  const handleSearch = (event) => {
    if (event.key === "Enter") {
      // 👇 Get input value
      let val = event.target.value;
      // updateStateData();
      // setSearchTerm(val);
      // if (val.length > 0) {
      //   filterSearch = [{ name: "" + val, type: "search" }];
      // } else {
      //   filterSearch = [];
      // }
      // loadChipdata();
    }
  };

  // filter checkbox update

  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    // if (
    //   name === "Active" ||
    //   name === "InActive" ||
    //   name === "WaitingForApproval" ||
    //   name === "InviteSent"
    // ) {
    //   if (checked) {
    //     // setStatus([...status, value]);
    //     setStatus([...status, { id: value, name: name, type: "status" }]);
    //   } else {
    //     setStatus(status.filter((item) => item.id !== value));
    //   }
    // } else if (typename === "type") {
    //   if (checked) {
    //     setTypeOn([...typeOn, { name: value, type: "type" }]);
    //   } else {
    //     setTypeOn(typeOn.filter((item) => item.name !== value));
    //   }
    // }
  };


  //chips overall clear button
  const handleAllClear = () => {
    // setSearchTerm("");
    // setStatus([]);

    // setUpdateChipData([]);
    // setTypeOn([]);

    // setUpdateListData(listData);
  };

  // filter apply button

  const handleApplyFilter = (name) => {
    // updateStateData();
    // if (name === "status") {
    //   loadChipdata();
    //   setIsOpen({
    //     ...isOpen,
    //     status: false,
    //   });
    // } else if (name === "type") {
    //   loadChipdata();
    //   setIsOpen({
    //     ...isOpen,
    //     category: false,
    //   });
    // }
  };

  return (
    <>
      <div className="bodycontent">
        <p className="topic_title">Dashboard</p>
        <div id="chart">
          <div className="row">
            <div className="col-md-6 inp_grp">
              <ReactApexChart
                options={options}
                series={series}
                type="donut"
                width={380}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomeLayout