import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { AxiosInstance } from "../../axios";
import Swal from "sweetalert2";

export default function Addscript() {

    const navigate = useNavigate();

   const {id} = useParams();
    
    const textscript_name = useRef(null);
    const textcloud_name = useRef(null);
    const textProvisioner = useRef(null);
    const textCategory = useRef(null);
    const textSub_Category = useRef(null);
    const textServiceType = useRef(null);
    const textdownload_url = useRef(null);
    const textScriptVersion = useRef(null);

    
    const textdesc = useRef(null);
    const [error, setError] = useState({});


    const [cloud, setCloud] = useState([]);

    const [provisioner, setProvisioner] = useState([]);

    const [subCategory, setSubCategory] = useState([]);

    const [serviceType, setServiceType] = useState([]);

    const [version, setversion] = useState([]);
    const [category, setCategory] = useState([]);

    
  const userJSON = localStorage.getItem("userDetails");
  let userid = null;

  if (userJSON) {
    const user = JSON.parse(userJSON);
    // console.log(user , "ssafsafasfasf");
    if (user.data && user.data.user_id) {
      userid = user.data.user_id;
    }
  } else {
    console.log("UserId Not available");
  }

    const [formData, setFormData] = useState({
         script_name : "",
         cloud_name:"",
         Provisioner : "",
         Category:"",
         Sub_Category : "",
         ServiceType:"",
         download_url:"",
         ScriptVersion:"",
         desc:""
    })

    useEffect(() => {
      console.log(id,"sdhsjd");
        AxiosInstance.post(`api/aam/script`, {
          "method":2,
          "id":id
          }
          )
            .then((res) => {
                if(res.data.status === true){
                    setFormData({
                        script_name: res.data.data[0].script_name,
                        cloud_name: res.data.data[0].cloud,
                        Provisioner: res.data.data[0].provisioner,
                        Category :res.data.data[0].category,
                        Sub_Category: res.data.data[0].sub_category,
                        ServiceType: res.data.data[0].service_type,
                        download_url: res.data.data[0].script_download_url,
                        ScriptVersion: res.data.data[0].script_version,
                        desc: res.data.data[0].description,
                      });
                }
            })
            .catch((error) => {
              return error;
            });
      }, [id]); // id come means it edit view 

    const validateFunction = (data,useref_validation) => {

        const errors = {};

        if (data.script_name == "") {
            errors.script_name = "Please enter script name.";
            textscript_name.current.focus();
          }
        else if (data.cloud_name == "") {
            errors.cloud_name = "Please choose cloud name.";
            textcloud_name.current.focus();
          }
          else if (data.Provisioner == "") {
            errors.Provisioner = "Please choose Provisioner.";
            textProvisioner.current.focus();
          }
          else if (data.Category == "") {
            errors.Category = "Please choose Category.";
            textCategory.current.focus();
          }
          else if (data.Sub_Category == "") {
            errors.Sub_Category = "Please choose Sub Category.";
            textSub_Category.current.focus();
          }
          else if (data.ServiceType == "") {
            errors.ServiceType = "Please choose ServiceType.";
            textServiceType.current.focus();
          }
          else if (data.download_url == "") {
            errors.download_url = "Please enter download url.";
            textdownload_url.current.focus();
          }
          else if (data.ScriptVersion == "") {
            errors.ScriptVersion = "Please enter Script Version.";
            textScriptVersion.current.focus();
          }
          else if (data.desc == "") {
            errors.desc = "Please enter cloud name.";
            textdesc.current.focus();
          }
    return errors;
    }

const handleChange = (e) => {
    const {name , value} = e.target;
    setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
      setError({
        ...error,
        [name]: "",
      });
}
useEffect(() => {
  AxiosInstance.post("api/aam/master/code", {
    "method":0,	
     "group_name":"Cloud"
    })
  .then((res) => {
    let cloud = res.data.data.map((item, index) => (
      <option key={index} value={item.code_name}>
        {item.code_name}
      </option>
    ));
    setCloud(cloud);
  })
  .catch((error) => {
    return error;
  });
}, []);
useEffect(() => {
  AxiosInstance.post("api/aam/master/code", {
    "method":0,	
     "group_name":"Provisioner"
    })
  .then((res) => {
    let Provisioner = res.data.data.map((item, index) => (
      <option key={index} value={item.code_name}>
        {item.code_name}
      </option>
    ));
    setProvisioner(Provisioner);
  })
  .catch((error) => {
    return error;
  });
}, []);

useEffect(() => {
  AxiosInstance.post("api/aam/master/code", {
    "method":0,	
     "group_name":"Sub Category"
    })
  .then((res) => {
    let SubCategory = res.data.data.map((item, index) => (
      <option key={index} value={item.code_name}>
        {item.code_name}
      </option>
    ));
    setSubCategory(SubCategory);
  })
  .catch((error) => {
    return error;
  });
}, []);


useEffect(() => {
  AxiosInstance.post("api/aam/master/code", {
    "method":0,	
     "group_name":"Service Type"
    })
  .then((res) => {
    let serviceType = res.data.data.map((item, index) => (
      <option key={index} value={item.code_name}>
        {item.code_name}
      </option>
    ));
    setServiceType(serviceType);
  })
  .catch((error) => {
    return error;
  });
}, []);

useEffect(() => {
  AxiosInstance.post("api/aam/master/code", {
    "method":0,	
     "group_name":"version"
    })
  .then((res) => {
    let version = res.data.data.map((item, index) => (
      <option key={index} value={item.version}>
        {item.version}
      </option>
    ));
    setversion(version);
  })
  .catch((error) => {
    return error;
  });
}, []);


useEffect(() => {
  AxiosInstance.post("api/aam/master/code", {
    "method":0,	
     "group_name":"Category"
    })
  .then((res) => {
    let Category = res.data.data.map((item, index) => (
      <option key={index} value={item.code_name}>
        {item.code_name}
      </option>
    ));
    setCategory(Category);
  })
  .catch((error) => {
    return error;
  });
}, []);


const navigateToPreviousScreen = () => {
    navigate(-1); // Navigate back by one step in the history
  };
const handlesubmit = (e) => {

    e.preventDefault(); // Prevent the default form submission behavior
   
    const errors = validateFunction(formData);
    if (Object.keys(errors).length === 0) {
      try {
        if (id) { // edit means if otherwise else condition 
          console.log(userid,"userid");
          const ssd = {
            "method":1,
            "id":id,
            "script_name":formData.script_name,
            "cloud":formData.cloud_name,
            "provisioner":formData.Provisioner,
            "category":formData.Category,
            "sub_category":formData.Sub_Category,
            "service_type":formData.ServiceType,
            "script_download_url":formData.download_url,
            "script_version":formData.ScriptVersion,
            "description":formData.desc,
            "updated_by":userid
          }
          console.log(ssd ,"ssd"); 
          AxiosInstance.post("api/aam/script", ssd)
          .then((res) => {
              if(res.data.status === true){
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigateToPreviousScreen();
                  }
                });
              }
          })
          .catch((error) => {
            return error;
          });
        } 
        else {

          console.log(userid,"userid");
          const fdata = {
            "method":1,
            "id":0,
            "script_name":formData.script_name,
            "cloud":formData.cloud_name,
            "provisioner":formData.Provisioner,
            "category":formData.Category,
            "sub_category":formData.Sub_Category,
            "service_type":formData.ServiceType,
            "script_download_url":formData.download_url,
            "script_version":formData.ScriptVersion,
            "description":formData.desc,
            "created_by":userid
            
          }
          console.log(fdata ,"fdata"); 
          AxiosInstance.post("api/aam/script", fdata)
          .then((res) => {
              if(res.data.status === true){
                Swal.fire({
                  title: "Success",
                  text: res.data.message,
                  icon: "success",
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    navigateToPreviousScreen();
                  }
                });
              }
          })
          .catch((error) => {
            return error;
          });
        }
      } catch (error) {
        console.log(error);
      }
    } else {
      setError(errors);
    }
}

// console.log(id,"id");

  if(id == 0)
  {
  return (
    <div className="bodycontent">
      <div className="cld_div">
        <p className="back_button" onClick={navigateToPreviousScreen}>
          <span className="material-icons">keyboard_backspace</span>Back to
          Script
        </p>
      </div>
      <div className="cld_div">
        <h3 className="title_tag">Add New Script</h3>
      </div>
      <div className="container-fluid m-0 p-0">
        <div className="row">
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="script_name" className="cld_labelText">
                Script Name
              </label>
              <input
                ref={textscript_name}
                value={formData.script_name}
                onChange={handleChange}
                className="cld_InputText"
                name="script_name"
                id="script_name"
                placeholder="Script Name"
              />
              {error.script_name && (
                <small className="error">{error.script_name}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="cloud_name" className="cld_labelText">
                Cloud
              </label>
              <select
                ref={textcloud_name}
                value={formData.cloud_name}
                className="cld_InputText"
                placeholder="Cloud"
                name="cloud_name"
                onChange={handleChange}
                id="cloud_name"
              >
                <option value="" disabled>Please select</option>
                {cloud}
              </select>
              {error.cloud_name && (
                <small className="error">{error.cloud_name}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="Provisioner" className="cld_labelText">
                Provisioner
              </label>
              <select
                ref={textProvisioner}
                value={formData.Provisioner}
                className="cld_InputText"
                placeholder="Provisioner"
                name="Provisioner"
                onChange={handleChange}
                id="Provisioner"
              >
                <option value="" disabled selected hidden>
                  Please select
                </option>
                {provisioner}
              </select>
              {error.Provisioner && (
                <small className="error">{error.Provisioner}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="Category" className="cld_labelText">
                Category
              </label>
              <select
                ref={textCategory}
                onChange={handleChange}
                value={formData.Category}
                className="cld_InputText"
                placeholder="Category"
                name="Category"
                id="Category"
              >
                <option value="" disabled>Please select</option>
                {category}
              </select>
              {error.Category && (
                <small className="error">{error.Category}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="Sub_Category" className="cld_labelText">
                Sub Category
              </label>
              <select
                ref={textSub_Category}
                onChange={handleChange}
                value={formData.Sub_Category}
                className="cld_InputText"
                placeholder="Sub Category"
                name="Sub_Category"
                id="Sub_Category"
              >
                <option value="">Please select</option>
               {subCategory}
              </select>
              {error.Sub_Category && (
                <small className="error">{error.Sub_Category}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="ServiceType" className="cld_labelText">
                Service Type
              </label>
              <select
                ref={textServiceType}
                onChange={handleChange}
                value={formData.ServiceType}
                className="cld_InputText"
                placeholder="Service Type"
                name="ServiceType"
                id="ServiceType"
              >
                <option value="ServiceType">Please select</option>
                {serviceType}
              </select>
              {error.ServiceType && (
                <small className="error">{error.ServiceType}</small>
              )}
            </div>
          </div>
        </div>

        <div className="row mt-2">
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="download_url" className="cld_labelText">
                Script Download URL
              </label>
              <input
                ref={textdownload_url}
                value={formData.download_url}
                onChange={handleChange}
                className="cld_InputText"
                name="download_url"
                id="download_url"
                placeholder="Script Download URL"
              />
              {error.download_url && (
                <small className="error">{error.download_url}</small>
              )}
            </div>
          </div>
          <div className="col-md-6">
            <div className="inp_grp">
              <label for="input_name" className="cld_labelText">
                Script Version
              </label>
              <select
                ref={textScriptVersion}
                onChange={handleChange}
                value={formData.ScriptVersion}
                className="cld_InputText"
                placeholder="Script Version"
                name="ScriptVersion"
                id="ScriptVersion"
              >
                {/* <option value="ScriptVersion">Please select</option>
                <option>{version}</option> */}
                <option value="0.0.1">0.0.1</option>
                <option value="0.0.2">0.0.2</option>
                <option value="0.0.3">0.0.3</option>
              </select>
              {error.ScriptVersion && (
                <small className="error">{error.ScriptVersion}</small>
              )}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12">
            <div className="inp_grp">
              <label for="input_name" className="cld_labelText">
                Description
              </label>
              <textarea
                ref={textdesc}
                value={formData.desc}
                onChange={handleChange}
                className="cld_Textarea"
                name="desc"
                id="desc"
                placeholder="Description"
                rows="4"
                cols="50"
              ></textarea>
              {error.desc && <small className="error">{error.desc}</small>}
            </div>
          </div>
        </div>
        <div className="row mt-2">
          <div className="col-md-12 d-flex justify-content-end">
            <span className="save_buttons material-icons">save</span>
            <button className="save_button " onClick={handlesubmit}>
              <Link to="javascript:void(0)">Save</Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
  }
  else{
    return (
      <div className="bodycontent">
        <div className="cld_div">
          <p className="back_button" onClick={navigateToPreviousScreen}>
            <span className="material-icons">keyboard_backspace</span>Back to
            Script
          </p>
        </div>
        <div className="cld_div">
          <h3 className="title_tag">Edit Script</h3>
        </div>
        <div className="container-fluid m-0 p-0">
          <div className="row">
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="script_name" className="cld_labelText">
                  Script Name
                </label>
                <input
                  ref={textscript_name}
                  value={formData.script_name}
                  onChange={handleChange}
                  className="cld_InputText"
                  name="script_name"
                  id="script_name"
                  placeholder="Script Name"
                />
                {error.script_name && (
                  <small className="error">{error.script_name}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="cloud_name" className="cld_labelText">
                  Cloud
                </label>
                <select
                  ref={textcloud_name}
                  value={formData.cloud_name}
                  className="cld_InputText"
                  placeholder="Cloud"
                  name="cloud_name"
                  onChange={handleChange}
                  id="cloud_name"
                >
                  <option value="" disabled>Please select</option>
                  {cloud}
                </select>
                {error.cloud_name && (
                  <small className="error">{error.cloud_name}</small>
                )}
              </div>
            </div>
          </div>
  
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="Provisioner" className="cld_labelText">
                  Provisioner
                </label>
                <select
                  ref={textProvisioner}
                  value={formData.Provisioner}
                  className="cld_InputText"
                  placeholder="Provisioner"
                  name="Provisioner"
                  onChange={handleChange}
                  id="Provisioner"
                >
                  <option value="" disabled selected hidden>
                    Please select
                  </option>
                  {provisioner}
                </select>
                {error.Provisioner && (
                  <small className="error">{error.Provisioner}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="Category" className="cld_labelText">
                  Category
                </label>
                <select
                  ref={textCategory}
                  onChange={handleChange}
                  value={formData.Category}
                  className="cld_InputText"
                  placeholder="Category"
                  name="Category"
                  id="Category"
                >
                  <option value="" disabled>Please select</option>
                  {category}
                </select>
                {error.Category && (
                  <small className="error">{error.Category}</small>
                )}
              </div>
            </div>
          </div>
  
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="Sub_Category" className="cld_labelText">
                  Sub Category
                </label>
                <select
                  ref={textSub_Category}
                  onChange={handleChange}
                  value={formData.Sub_Category}
                  className="cld_InputText"
                  placeholder="Sub Category"
                  name="Sub_Category"
                  id="Sub_Category"
                >
                  <option value="">Please select</option>
                 {subCategory}
                </select>
                {error.Sub_Category && (
                  <small className="error">{error.Sub_Category}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="ServiceType" className="cld_labelText">
                  Service Type
                </label>
                <select
                  ref={textServiceType}
                  onChange={handleChange}
                  value={formData.ServiceType}
                  className="cld_InputText"
                  placeholder="Service Type"
                  name="ServiceType"
                  id="ServiceType"
                >
                  <option value="ServiceType">Please select</option>
                  {serviceType}
                </select>
                {error.ServiceType && (
                  <small className="error">{error.ServiceType}</small>
                )}
              </div>
            </div>
          </div>
  
          <div className="row mt-2">
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="download_url" className="cld_labelText">
                  Script Download URL
                </label>
                <input
                  ref={textdownload_url}
                  value={formData.download_url}
                  onChange={handleChange}
                  className="cld_InputText"
                  name="download_url"
                  id="download_url"
                  placeholder="Script Download URL"
                />
                {error.download_url && (
                  <small className="error">{error.download_url}</small>
                )}
              </div>
            </div>
            <div className="col-md-6">
              <div className="inp_grp">
                <label for="input_name" className="cld_labelText">
                  Script Version
                </label>
                <select
                  ref={textScriptVersion}
                  onChange={handleChange}
                  value={formData.ScriptVersion}
                  className="cld_InputText"
                  placeholder="Script Version"
                  name="ScriptVersion"
                  id="ScriptVersion"
                >
                  {/* <option value="ScriptVersion">Please select</option>
                  <option>{version}</option> */}
                  <option value="0.0.1">0.0.1</option>
                  <option value="0.0.2">0.0.2</option>
                  <option value="0.0.3">0.0.3</option>
                </select>
                {error.ScriptVersion && (
                  <small className="error">{error.ScriptVersion}</small>
                )}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12">
              <div className="inp_grp">
                <label for="input_name" className="cld_labelText">
                  Description
                </label>
                <textarea
                  ref={textdesc}
                  value={formData.desc}
                  onChange={handleChange}
                  className="cld_Textarea"
                  name="desc"
                  id="desc"
                  placeholder="Description"
                  rows="4"
                  cols="50"
                ></textarea>
                {error.desc && <small className="error">{error.desc}</small>}
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-md-12 d-flex justify-content-end">
              <span className="save_buttons material-icons">save</span>
              <button className="save_button " onClick={handlesubmit}>
                <Link to="javascript:void(0)">Save</Link>
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
