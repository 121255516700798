import "./Rbac.css";

import { Link, useNavigate, useParams } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { AxiosInstance } from '../../axios';

export default function ViewDetail() {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleEditView = (ids) => {

        console.log(ids, "------------");
        navigate(`/dashboard/viewRbac/${ids}`);

    }
    // console.log(keyword,"*********");
    // console.log(id,"*******");

    const [formData, setFormData] = useState({
        rolename: '',
        status: '',
        desc: '',
        menu_list: []
    });

    console.log(formData, "Menu_list");
    useEffect(() => {
        const bb = {
            "method": 2,
            "id": id
        };

        AxiosInstance.post("api/aam/master/role", bb)
            .then((res) => {
                // console.log(res);
                if (res.data.status) {
                    const roleId = res.data.data[0].id; // Extract the role ID
                    setFormData({
                        rolename: res.data.data[0].name,
                        status: res.data.data[0].status,
                        desc: res.data.data[0].description,
                        id: roleId
                    });
                    console.log(roleId, "Role_id");
                    // Use the extracted roleId in the second request
                    AxiosInstance.post("api/script/access", {
                        method: 4,
                        role_id: roleId
                    })
                        .then((res) => {
                            console.log(res); // Log the response from the second request
                            if (res.data.status === true) {
                                setFormData(prevFormData => ({
                                    ...prevFormData, // Preserve existing formData properties
                                    menu_list: res.data.data,
                                }));
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [id]);



    const navigateToPreviousScreen = () => {
        navigate(-1); // Navigate back by one step in the history
    };

    return (
        <div className="bodycontent">
            <div className="rbac_div">
                <p className="back_button" onClick={navigateToPreviousScreen}>
                    <span className="material-icons">keyboard_backspace</span>Back to Roles
                </p>
            </div>
            <div className="cld_div justify-content-between">
                <div><p className="topic_title">
                    {"View Role"}
                </p></div>
                <div className="d-flex justify-content-end align-item-center">
                    <button
                        className="back_buttons  me-3" onClick={() => handleEditView(id)}>
                        <span className="material-icons me-2">mode_edit</span>
                        <Link to='javascript:void(0)'>Edit</Link>
                    </button>
                </div>
            </div>
            <div className="rbac_content">
                <div className="row">
                    <div className="col-md-6 inp_grp">
                        <label for="input_name" className="rbac_labelText">
                            Role Name
                        </label>
                        <label className="rbac_InputText">{formData.rolename != "" && formData.rolename != null ? formData.rolename : "_"}</label>
                    </div>
                    <div className="col-md-6 inp_grp">
                        <label for="select_status" className="rbac_labelText">
                            Status
                        </label>
                        <label className="rbac_InputText">
                            {(formData.status === 1 ? "Active" : "Inactive")}
                        </label>
                        {/* <label className="rbac_InputText">{(formData.status === 1 ? "Active" : "Inactive")}</label> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 inp_grp">
                        <label for="input_des" className="rbac_labelText">
                            Description
                        </label>
                        <label className="rbac_InputText">{formData.desc != "" && formData.desc != null ? formData.desc : "_"}</label>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 inp_grp">
                        <label for="input_permission" className="rbac_labelText">
                            Permissions
                        </label>
                        <div className="rbac_box_border">
                            <div className="col-md-12 table-responsive">
                                <table
                                    className="table table-striped  table-hover"
                                    id="script"
                                >
                                    <thead>
                                        <tr>
                                            <th id="th" style={{ width: "10%" }}></th>
                                            <th id="th">access</th>
                                            <th id="th">delete</th>
                                            <th id="th">edit</th>
                                            <th id="th">export</th>
                                            <th id="th">import</th>
                                            <th id="th">list</th>
                                            <th id="th">view</th>
                                            <th id="th">mass_update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.menu_list &&
                                            formData.menu_list.map((obj, index) => {
                                                return (
                                                    <tr>
                                                        <td>{obj.menu_name}</td>
                                                        <td>
                                                            <input
                                                                checked={obj.access}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"acc_" + index}
                                                                name={"acc_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.deletes}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"del_" + index}
                                                                name={"del_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.edit}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"edit_" + index}
                                                                name={"edit_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.export}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"export_" + index}
                                                                name={"export_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.import}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"import_" + index}
                                                                name={"import_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.list}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"list_" + index}
                                                                name={"list_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.view}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"view_" + index}
                                                                name={"view_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.mass_update}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"mupdate_" + index}
                                                                name={"mupdate_" + index}
                                                                disabled
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>



    );
}
