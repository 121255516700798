import "./Header.css";

import React, { useState, useContext, useEffect } from "react";

import Logo from "../assets/Group 12@2x.png";
import img_avatar from "../assets/img_avatar.png";
import menu_grid from "../assets/menu.png";
import notification_normal from "../assets/bell.png";
import notification_red from "../assets/bell_red.png";

import { UserContext } from "../context/UserDetails";
import { useLocation } from "react-router-dom";

// import { getUser, removeUserSession, getToken } from '../Utils/Common';

function Header() {
  const location = useLocation();
  const { logout } = useContext(UserContext);
  const [activeLink, setActiveLink] = useState();

  const [user, setUser] = useState({});

  let Users = null;

  const handleNavLinkClick = (index) => {
    setActiveLink(index);
  };
  const userJSON = localStorage.getItem("userDetails");
  if (userJSON) {
    // setUser(JSON.parse(userJSON));
    Users = JSON.parse(userJSON);
  } else {
    console.log("User Id cannot available in localstorge");
    // setUser({});
    Users = null;
  }

  console.log("USER :", Users);

  const Logout = () => {
    logout();
  };
  // useEffect(() => {
  //   const userJSON = localStorage.getItem("userDetails");
  //   if (userJSON) {
  //     setUser(JSON.parse(userJSON));
  //   } else {
  //     console.log("User Id cannot available in localstorge");
  //     setUser({});
  //   }
  // }, [user]);

  return (
    <header className="fixed-header-hook">
      <div className="head_content">
        <div className="row">
          <div className="col-lg-5">
            <div className="topnav" id="myTopnav">
              <a
                href="/dashboard/script"
                className={
                  location.pathname === "/dashboard/script" ||
                    location.pathname === "/dashboard/AddScript"
                    ? "active"
                    : ""
                }
              >
                Script
              </a>
              <a href="/dashboard/Cockpit">Cockpit</a>
              <a href="/dashboard/Forum">Forum</a>
              <a className="topnav_a" href="/dashboard/Support">
                Support
              </a>
              <a href="/dashboard/Training">Training</a>
            </div>
          </div>
          <div className="col-lg-2 ">
            <a href="/dashboard">
              <img className="hook_logo" src={Logo} />
            </a>
          </div>
          <div className="col-lg-5 ">
            <div className="topnav" id="myTopnav">
              <a href="/dashboard/tube">Tube</a>
              <a href="/dashboard/environment">Environment</a>
              <div className="right_menus">
                <img className="img_bell" src={notification_red} />
                <div className="dropdown" disabled >
                  <img className="img_menu_grid" src={menu_grid} />
                  <div className="dropdown-height dropdown-contents">
                    {/* <a href="#" className="active">
                            Script
                          </a>
                          <a href="#">Cockpit</a>
                          <a href="#">Forum</a>
                          <a href="#">Support</a>
                          <a href="#">Training</a>
                          <a href="#">Tube</a> */}
                    {Users &&  Users.menu_list &&
                      Users.menu_list
                        .filter((item) => item.description === "step I")
                        .map((el, index) => {
                          const link = "/dashboard/" + el.name;
                          let headl = "Always Automate";
                          return (
                            <>
                              {index === 0 ? <label>{headl}</label> : <></>}
                              <a
                                href={link}
                                className={
                                  location.pathname + "" === link
                                    ? "active"
                                    : ""
                                }
                                id={location.pathname}
                              >
                                {el.name}
                              </a>
                            </>
                          );
                        })}
                    {Users &&  Users.menu_list &&
                      Users.menu_list
                        .filter((item) => item.description === "step II")
                        .map((el, index) => {
                          const link = "/dashboard/" + el.name;
                          let headl = "Org Admin";
                          return (
                            <>
                              {index === 0 ? <label>{headl}</label> : <></>}
                              <a
                                href={link}
                                className={
                                  location.pathname + "" === link
                                    ? "active"
                                    : ""
                                }
                                id={location.pathname}
                              >
                                {el.name}
                              </a>
                            </>
                          );
                        })}
                    {Users &&  Users.menu_list &&
                      Users.menu_list
                        .filter((item) => item.description === "step III")
                        .map((el, index) => {
                          const link = encodeURI(`/dashboard/${el.name}`);
                          let headl = "Super Admin";
                          return (
                            <>
                              {index === 0 ? <label>{headl}</label> : <></>}
                              <a
                                href={link}
                                title={el.name}
                                className={
                                  (location.pathname === "/dashboard/Service type" ||
                                    location.pathname === "/dashboard/Sub category")
                                    ? (location.pathname === link ? "active" : "")
                                    : location.pathname + "" === link
                                      ? "active"
                                      : ""
                                }
                                id={location.pathname}
                              >
                                {el.name}
                              </a>
                            </>
                          );
                        })}
                  </div>
                </div>
                <div className="dropdown">
                  <img className="img_user_profile" src={img_avatar} />
                  <div className="dropdown-contents">
                    <a href="#" onClick={Logout}>
                      Logout
                    </a>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="dropdown">
                <button className="dropbtn">
                  Dropdown
                  <i className="fa fa-caret-down"></i>
                </button>
                <div className="dropdown-contents">
                  <a href="#">Link 1</a>
                  <a href="#">Link 2</a>
                  <a href="#">Link 3</a>
                </div>
              </div> */}
          </div>
        </div>
        <div className="row">
          <div className="col-lg-5 head_parent_br_hook"></div>
          <div className="col-lg-2 "></div>
          <div className="col-lg-5 head_parent_br_right_hook"></div>
        </div>
      </div>
    </header>
  );

}

export default Header 