import "./Login.css";

import React, { useEffect, useRef, useState } from "react";

import { AxiosInstance } from "../../axios/index"
import Logo from "../../assets/Group 12@2x.png";
import img_eye from "../../assets/eye-fill.svg"
import img_eye_slash from "../../assets/eye-slash-fill.svg";
import { useNavigate } from "react-router-dom";

function Login() {

  let navigate = useNavigate();

  const [context, setContext] = useState({ email: "", password: "", eye: false });
  const textInputEmail = useRef(null);
  const textInputPassword = useRef(null);

   const handleChange = (event) => {
    const { name, value, type, checked } = event.target;
    if (name === "input_email") {
      setContext({ ...context, email: value });
     }
     else if (name === "input_password") {
      setContext({ ...context, password: value });
    }
  };
  
  const eyeChange = () =>
  {
    if (context.eye) {
      setContext({ ...context, eye: false });
    } else {
      setContext({ ...context, eye: true });
    }
  };

  const routeChange = () => {
   
    if (context.email === "")
    {
      textInputEmail.current.focus();
    }
    else if (context.password === "")
    {
      textInputPassword.current.focus();
    }
    else
    {
      let userData = {
        method: 2,
        email: context.email,
        password: context.password,
      };
      logiApi(userData);
    }
  };

  function logiApi(userData) {
    const header = {
      "Access-Control-Allow-Origin": "http://localhost:3000",
    };
    //AxiosInstance.post("api/aam/user", userData, { headers: header })
      AxiosInstance.post("api/aam/user", userData)
        .then((res) => {
          console.log(res);
          if (res.data.status) {
            localStorage.setItem("userDetails", JSON.stringify(res.data));
            localStorage.setItem("user", JSON.stringify(userData));
            let path = `/auth`;
            navigate(path);
          } else {
            textInputPassword.current.focus();
            alert(res.data.message);
          }
        })
        .catch((error) => {
          return error;
        });
  }

    return (
      // <div className="container">
      <div className="c_row">
        <div className="col-lg-4 col-md-12 left_banner">
          {/* <div className="left_banner"></div> */}
          <div className="right_img">
            <img className="right_img" src={Logo} alt="Banners" />
          </div>
          <div className="title_sec">
            <p className="title_banner">Start your </p>
            <p className="title_banner_sec">journey with us.</p>
            <p className="para_text">
              Explore automation solutions that can help you meet your goals.
              Automate your work and build something new.
            </p>
          </div>
        </div>
        <div className="col-lg-8 col-md-12">
          <div className="lg_parent">
            <p className="lg_title">Log in to Your Account</p>
            <div className="lg_sub_div">
              <p className="lg_sub_title">New to Always Automate?</p>
              <a href="/signup" className="lg_sub_title_create">
                Create Account
              </a>
            </div>
            <div className="row">
              <div className="col-md-4">
                <label for="fname" className="lg_labelText">
                  Email id
                </label>
                <input
                  ref={textInputEmail}
                  value={context.email}
                  onChange={handleChange}
                  className="lg_InputText"
                  name="input_email"
                  placeholder="Enter Email Id"
                />
              </div>
            </div>
            <div className="row">
              <div className="">
                <label for="lname" className="lg_labelText">
                  Password
                </label>
                <input
                  ref={textInputPassword}
                  value={context.password}
                  onChange={handleChange}
                  type={context.eye ? "text" : "password"}
                  className="lg_InputText"
                  name="input_password"
                  placeholder="Enter Password"
                />
                {context.eye === true ? (
                  <img
                    className="lg_password_eye"
                    src={img_eye_slash}
                    onClick={eyeChange}
                  />
                ) : (
                  <img
                    className="lg_password_eye"
                    src={img_eye}
                    onClick={eyeChange}
                  />
                )}
              </div>
            </div>
            <div className="lg_remember">
              <div>
                <input
                  type="checkbox"
                  className="lg_checkbox"
                  id="remember"
                  name="remember"
                />
                <label className="lg_check" for="remember">
                  Keep me signed in
                </label>
              </div>
              <a href="/forgotpassword" className="lg_forgot">
                Forgot Password?
              </a>
            </div>
            <div className="lg_submit_bg">
              <button className="lg_submit" onClick={routeChange}>
                Log in
              </button>
            </div>
          </div>
        </div>
      </div>
    );
};
export default Login