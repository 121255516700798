import "./Login.css";
import "./ForgotPassword.css";

import React, { useEffect, useRef, useState } from "react";

import ArrowLeft from "../../assets/arrow-left.svg"
import { AxiosInstance } from "../../axios/index";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Logo from "../../assets/Group 12@2x.png";
import Timer from "../../assets/stopwatch-fill.svg";
import { faStopwatch } from "@fortawesome/free-solid-svg-icons";

function ForgotPassword()
{

  const [context, setContext] = useState({ email:"", resend: false });
  const textInputEmail = useRef(null);

   const handleChange = (event) => {
     const { name, value, type, checked } = event.target;
     if (name === "input_email") {
       setContext({ ...context, email: value });
     } 
   };
  
  const routeChange = () => {
   
     if (context.email === "") {
       textInputEmail.current.focus();
     }
     else {
       let userData = {
         method: 3,
         email: context.email
       };
       logiApi(userData);
     }
  };
    function logiApi(userData) {
      AxiosInstance.post("api/aam/user", userData)
        .then((res) => {
          if (res.data.status) {
            setContext({ ...context, resend: true });
          }
          else { 
            textInputEmail.current.focus();
          }
        })
        .catch((error) => {
          return error;
        });
    }


  return (
    // <div className="container">
    <div className="c_row">
      <div className="col-lg-4 col-md-12 left_banner">
        {/* <div className="left_banner"></div> */}
        <div className="right_img">
          <img className="right_img" src={Logo} alt="Banners" />
        </div>
        <div className="title_sec">
          <p className="title_banner">Start your </p>
          <p className="title_banner_sec">journey with us.</p>
          <p className="para_text">
            Explore automation solutions that can help you meet your goals.
            Automate your work and build something new.
          </p>
        </div>
      </div>
      <div className="col-lg-8 col-md-12">
        <div className="fg_parent">
          <div className="fg_parent_div">
            <a href="/login">
              <img src={ArrowLeft} />
            </a>
            <p className="fg_title">Forgot Password</p>
          </div>

          {context.resend === false ? (
            <div className="fg_label_div">
              <p className="fg_label_normal">
                If you don't see your reset mail, be sure to check your spam
                folder for an email from{" "}
                <a href="#">support@alwaysautomate.com.</a>
              </p>
              <p className="fg_label_normal">
                To ensure delivery to inbox, add{" "}
                <a href="#">support@alwaysautomate.com.</a> to your address book
                and make it whitelisted.
              </p>{" "}
            </div>
          ) : (
            <div className="fg_label_div">
              <p className="fg_label_normal">
                Reset password link has been sent successfully to the email
                address you provided.
              </p>
            </div>
          )}

          {context.resend === false ? (
            <div className="fg_div_labelText">
              <label for="fname" className="fg_labelText">
                Email Id
              </label>
              <div className="fg_sub_div">
                <input
                  ref={textInputEmail}
                  value={context.email}
                  onChange={handleChange}
                  className="fg_InputText"
                  name="input_email"
                  placeholder="Enter Email Authentication Code"
                />
              </div>
            </div>
          ) : (
            <div></div>
          )}
          {context.resend === false ? (
            <div className="fg_submit_bg">
              <button className="fg_submit" onClick={routeChange}>
                Send Reset Password Link
              </button>
            </div>
          ) : (
            <div></div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;