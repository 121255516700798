import "./Rbac.css";

import React, { useEffect, useRef, useState } from "react";
import { useFetcher, useNavigate, useParams } from "react-router-dom";

import { AxiosInstance } from "../../axios/index";
import Footer from "../../hooks/Footer";
import Header from "../../hooks/Header";
import Logo from "../../assets/Group 12@2x.png";
import Swal from "sweetalert2";



function ViewRbac() {
    const { ids } = useParams();
    const navigate = useNavigate();

    // console.log(keyword,"*********");
    console.log(ids, "*******");
    const userJSON = localStorage.getItem("userDetails");
    let userid = null;

    if (userJSON) {
        const user = JSON.parse(userJSON);
        // console.log(user , "ssafsafasfasf");
        if (user.data && user.data.user_id) {
            userid = user.data.user_id;
        }
    } else {
        console.log("UserId Not available");
    }

    const textrolename = useRef(null);
    const textdesc = useRef(null);
    const [error, setError] = useState({});
    const validateFunction = (data) => {

        const errors = {};
        if (data.rolename == "") {
            errors.rolename = "Please enter rolename name.";
            textrolename.current.focus();
        }
        else if(data.desc == ""){
            errors.desc = "Please enter description name.";
            textdesc.current.focus();
        }
        return errors;
    }

    const [formData, setFormData] = useState({
        rolename: '',
        status: '',
        desc: '',
        menu_list: []
    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));
        setError({
            ...error,
            [name]: "",
        });
    }


    const handleCheckboxChange = (event, index, fieldName) => {
        const { name, value, type, checked } = event.target;
        // console.log(name,"result1");
        // console.log(value,"result2");
        // console.log(type,"result3");
        // console.log(checked,"result4");
        // console.log(index,"index");
        // console.log(fieldName,"fieldName");
        setFormData(prevState => {
            const updatedMenuList = [...prevState.menu_list];

            // updatedMenuList[index][fieldName] = !updatedMenuList[index][fieldName];
            if (checked) {
                updatedMenuList[index][fieldName] = 1;
                return { ...prevState, menu_list: updatedMenuList };
            }
            else {
                updatedMenuList[index][fieldName] = 0;
                return { ...prevState, menu_list: updatedMenuList };
            }
        });
    };

    const routeChange = (e) => {
        e.preventDefault();
        const errors = validateFunction(formData);
        // console.log(formData.menu_list, "menu data");
        if (Object.keys(errors).length === 0) {
            try {
                const ssd = {
                    method: 4,
                    created_by: userid,
                    name: formData.rolename,
                    description: formData.desc,
                    status: formData.status,
                    role_id: ids,
                    menu_access: formData.menu_list
                }
                console.log(ssd, "ssd");
                AxiosInstance.post("api/aam/master/role", ssd)
                    .then((res) => {
                        if (res.data.status === true) {
                            Swal.fire({
                                title: "Success",
                                text: res.data.message,
                                icon: "success",
                                confirmButtonText: "Ok",
                            }).then((result) => {
                                if (result.isConfirmed) {
                                    navigateToPreviousScreen();
                                }
                            });
                        }
                    })
                    .catch((error) => {
                        return error;
                    });
            }
            catch (error) {
                console.log(error);
            }
        }
        else {
            setError(errors);
        }

    }


    console.log(formData, "Menu_list");
    useEffect(() => {
        const bb = {
            "method": 2,
            "id": ids
        };

        AxiosInstance.post("api/aam/master/role", bb)
            .then((res) => {
                // console.log(res);
                if (res.data.status) {
                    const roleId = res.data.data[0].id; // Extract the role ID
                    setFormData({
                        rolename: res.data.data[0].name,
                        status: res.data.data[0].status,
                        desc: res.data.data[0].description,
                        id: roleId
                    });
                    console.log(roleId, "Role_id");
                    // Use the extracted roleId in the second request
                    AxiosInstance.post("api/script/access", {
                        method: 4,
                        role_id: roleId
                    })
                        .then((res) => {
                            console.log(res); // Log the response from the second request
                            if (res.data.status === true) {
                                setFormData(prevFormData => ({
                                    ...prevFormData, // Preserve existing formData properties
                                    menu_list: res.data.data,
                                }));
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                        });
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, [ids]);


    const navigateToPreviousScreen = () => {
        navigate(-1); // Navigate back by one step in the history
    };

    return (
        <div className="bodycontent">
            <div className="rbac_div">
                <p className="back_button" onClick={navigateToPreviousScreen}>
                    <span className="material-icons">keyboard_backspace</span>Back to Roles
                </p>
            </div>
            <div className="cld_div justify-content-between">
                <p className="topic_title">
                    {"Edit Role"}
                </p>
            </div>
            <div className="rbac_content">
                <div className="row">
                    <div className="col-md-6 inp_grp">
                        <label for="rolename" className="rbac_labelText">
                            Role Name
                        </label>
                        {/* <input className="rbac_InputText"
                            value={formData.rolename} /> */}
                        <input className="ur_InputText"
                            value={formData.rolename}
                            ref={textrolename}
                            name="rolename"
                            id="rolename"
                            onChange={handleChange}
                            placeholder="rolename" />
                        {error.rolename && (
                            <small className="error">{error.rolename}</small>
                        )}
                    </div>
                    <div className="col-md-6 inp_grp">
                        <label for="select_status" className="rbac_labelText">
                            Status
                        </label>
                        <select
                            value={formData.status}
                            className="rbac_InputText"
                            onChange={(e) => setFormData(prevFormData => ({ ...prevFormData, status: e.target.value }))}
                        >
                            <option value="" disabled>Please select</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select>

                        {/* <select
                            value={formData.status}
                            className="rbac_InputText"
                        >
                            <option value="" disabled>Please select</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                        </select> */}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 inp_grp">
                        <label for="input_des" className="rbac_labelText">
                            Description
                        </label>
                        {/* <input className="rbac_InputText" value={formData.desc != "" && formData.desc != null ? formData.desc : "_"} /> */}
                        <input className="ur_InputText"
                            value={formData.desc}
                            ref={textdesc}
                            name="desc"
                            id="desc"
                            onChange={handleChange}
                            placeholder="description" />
                        {error.desc && (
                            <small className="error">{error.desc}</small>
                        )}
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 inp_grp">
                        <label for="input_permission" className="rbac_labelText">
                            Permissions
                        </label>
                        <div className="rbac_box_border">
                            <div className="col-md-12 table-responsive">
                                <table
                                    className="table table-striped  table-hover"
                                    id="script"
                                >
                                    <thead>
                                        <tr>
                                            <th id="th" style={{ width: "10%" }}></th>
                                            <th id="th">access</th>
                                            <th id="th">delete</th>
                                            <th id="th">edit</th>
                                            <th id="th">export</th>
                                            <th id="th">import</th>
                                            <th id="th">list</th>
                                            <th id="th">view</th>
                                            <th id="th">mass_update</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {formData.menu_list &&
                                            formData.menu_list.map((obj, index) => {
                                                return (
                                                    <tr>
                                                        <td>{obj.menu_name}</td>
                                                        <td>
                                                            <input
                                                                checked={obj.access}
                                                                onChange={(e) => handleCheckboxChange(e, index, "access")}
                                                                // onChange={handleCheckboxChange(index, "access")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"acc_" + index}
                                                                name={"acc_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.deletes}
                                                                onChange={(e) => handleCheckboxChange(e, index, "deletes")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"del_" + index}
                                                                name={"del_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.edit}
                                                                onChange={(e) => handleCheckboxChange(e, index, "edit")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"edit_" + index}
                                                                name={"edit_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.export}
                                                                onChange={(e) => handleCheckboxChange(e, index, "export")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"export_" + index}
                                                                name={"export_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.import}
                                                                onChange={(e) => handleCheckboxChange(e, index, "import")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"import_" + index}
                                                                name={"import_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.list}
                                                                onChange={(e) => handleCheckboxChange(e, index, "list")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"list_" + index}
                                                                name={"list_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.view}
                                                                onChange={(e) => handleCheckboxChange(e, index, "view")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"view_" + index}
                                                                name={"view_" + index}
                                                            />
                                                        </td>
                                                        <td>
                                                            <input
                                                                checked={obj.mass_update}
                                                                onChange={(e) => handleCheckboxChange(e, index, "mass_update")}
                                                                type="checkbox"
                                                                className="rbac_checkbox"
                                                                id={"mupdate_" + index}
                                                                name={"mupdate_" + index}
                                                            />
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="rbac_submit_bg">
                    <span class="rbac_save_buttons material-icons">save</span>
                    <button className="rbac_save_button" onClick={routeChange}>
                        Save
                    </button>
                </div>
            </div>
        </div>



    );
}
export default ViewRbac;
