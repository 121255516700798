import React, { useState } from 'react';

import Logo from "../assets/Group 12@2x.png";

// import { getUser, removeUserSession, getToken } from '../Utils/Common';


  
function Header()
{

  const [activeLink, setActiveLink] = useState();

const handleNavLinkClick = (index) => {

  setActiveLink(index);
};
        return (
          <header className="fixed-header">
            {/* <div className="header-flex-box">
              <div className="col-lg-5 head_parent">
                <a href="#aboutUs" className="head_menu">About Us</a>
                <span className="head_menu">Product Features</span>
                <a href="#pricing" className="head_menu">Pricing</a>
                <span className="head_menu">Contact Us</span>
              </div>
              <div className="col-lg-2 head_parent">
                <img className="head_logo" src={Logo} />
              </div>
              <div className="col-lg-5 head_parent head_parent_right">
                <span className="head_bt">Get Started</span>
                <span className="head_bt">Login</span>
              </div>
            </div> */}
            <nav className="navbar navbar-expand-lg navbar-light">
              <div className="container">
                <a className="navbar-brand" href="#">
                  <img className="head_logo" src={Logo} alt="Logo" />
                </a>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarNav"
                  aria-controls="navbarNav"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarNav">
                  <ul className="navbar-nav">
                    <li
                      className={`nav-item ${
                        activeLink === 0 ? "active show-link" : ""
                      }`}
                    >
                      <a
                        className="nav-link"
                        href="#aboutUs"
                        onClick={() => handleNavLinkClick(0)}
                      >
                        About Us
                      </a>
                    </li>
                    <li
                      className={`nav-item ${
                        activeLink === 1 ? "active show-link" : ""
                      }`}
                    >
                      <a
                        className="nav-link"
                        href="#productFeatures"
                        onClick={() => handleNavLinkClick(1)}
                      >
                        Product Features
                      </a>
                    </li>
                    <li
                      className={`nav-item ${
                        activeLink === 2 ? "active show-link" : ""
                      }`}
                    >
                      <a
                        className="nav-link"
                        href="#pricing"
                        onClick={() => handleNavLinkClick(2)}
                      >
                        Pricing
                      </a>
                    </li>
                    <li
                      className={`nav-item ${
                        activeLink === 3 ? "active show-link" : ""
                      }`}
                    >
                      <a
                        className="nav-link"
                        href="#contactUs"
                        onClick={() => handleNavLinkClick(3)}
                      >
                        Contact Us
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="navbar-right">
                  <button className="btn btnStart">
                    <a href="/signup">Get Started</a>
                  </button>
                  <button className="btn btn_end">
                    <a href="/login">Login</a>
                  </button>
                </div>
              </div>
            </nav>

            <div className="header-flex-box">
              <div className="col-lg-5 head_parent_br"></div>
              <div className="col-lg-2 "></div>
              <div className="col-lg-5 head_parent_br_right"></div>
            </div>
          </header>
        );
      
}  
  
export default Header 