import './Script.css';

import { Link, Navigate, useNavigate } from 'react-router-dom';
import React, { useEffect, useState } from "react";

import { AxiosInstance } from '../../axios';
import SearchIcon from "@mui/icons-material/Search";
import add from '../../assets/add.png';
import tbl_download from "../../assets/table_download.svg";
import tbl_upload from "../../assets/table_upload.svg";
import tbl_view from "../../assets/table_view.svg";
import Rating from '../../utils/Rating';
import Paper from "@mui/material/Paper";
import Chip from "@mui/material/Chip";
import { styled } from "@mui/material/styles";

export default function Script() {

  const navigate = useNavigate();


  const [script_list, setscript_list] = useState([]);
  // console.log(script_list, "script_list");
  let Users = null;
  const userJSON = localStorage.getItem("userDetails");
  if (userJSON) {
    // setUser(JSON.parse(userJSON));
    Users = JSON.parse(userJSON);
  } else {
    console.log("User Id cannot available in localstorge");
    // setUser({});
    Users = null;
  }


  const [searchTerm, setSearchTerm] = useState("");
  const [cloud, setCloud] = useState([]);
  const [provisioner, setProvisioner] = useState([]);
  const [category, setCategory] = useState([]);
  const [sub_category, setsub_Category] = useState([]);
  const [service_type, setService_type] = useState([]);
  const [no_of_downloads, setNo_of_downloads] = useState([]);
  const [rating, setRating] = useState([]);
  const [sortOrder, setSortOrder] = useState("");

  const [totalRecords, setTotalRecords] = useState(0);
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(10);
  // const totalPages = Math.ceil(totalRecords / itemsPerPage);

  const [isOpen, setIsOpen] = useState({
    cloud: false,
    provisioner: false,
    category: false,
    sub_category: false,
    service_type: false,
    no_of_downloads: false,
    rating: false,

  });
  const [updateChipData, setUpdateChipData] = useState([]);
  const [cloudon, setCloudon] = useState([]);
  const [provisioneron, setProvisioneron] = useState([]);
  const [categoryon, setCategoryon] = useState([]);
  const [sub_categoryon, setsub_Categoryon] = useState([]);
  const [service_typeon, setService_typeon] = useState([]);
  const [no_of_downloadson, setNo_of_downloadson] = useState([]);
  const [ratingon, setRatingon] = useState([]);

  function loadListdata() {
    let tempcoludeon = [];
    cloudon.map((obj) => {
      tempcoludeon.push(obj.id);
    });
    let tempprovisioner = [];
    provisioneron.map((obj) => {
      tempprovisioner.push(obj.id);
    });
    let tempcategory = [];
    categoryon.map((obj) => {
      tempcategory.push(obj.id);
    });
    let tempsub_category = [];
    sub_categoryon.map((obj) => {
      tempsub_category.push(obj.id);
    });
    let tempservice_type = [];
    service_typeon.map((obj) => {
      tempservice_type.push(obj.id);
    });
    let tempno_of_downloads = [];
    no_of_downloadson.map((obj) => {
      tempno_of_downloads.push(obj.id);
    });
    let temprating = [];
    ratingon.map((obj) => {
      temprating.push(obj.id);
    });


    let statusFilter = {
      method: 3,
      cloud: cloudon.length > 0 ? tempcoludeon : "",
      provisioner: provisioneron.length > 0 ? tempprovisioner : "",
      category: categoryon.length > 0 ? tempcategory : "",
      sub_category: sub_categoryon.length > 0 ? tempsub_category : "",
      service_type: service_typeon.length > 0 ? tempservice_type : "",
      no_of_downloads: no_of_downloadson.length > 0 ? tempno_of_downloads : "",
      rating: ratingon.length > 0 ? temprating : "",
    };
    // console.log(statusFilter,"status");
    AxiosInstance.post("api/aam/script", statusFilter)
      .then((res) => {
        // console.log("success ", res);
        if (res.data.status === true) {
          const fiata = res.data.data;
          setscript_list(fiata);
        } else if (res.data.status === false) {
          setscript_list([]);
        }
      })
      .catch((error) => {
        // console.log("Error res: ", error.response.data);
        setscript_list([]);
        return error;
      });
  }
  useEffect(() => {
    filtercloud = cloudon;
  }, [cloudon]);
  useEffect(() => {
    filterprovisioner = provisioneron;
  }, [provisioneron]);
  useEffect(() => {
    filtercategory = categoryon;
  }, [categoryon]);
  useEffect(() => {
    filtersub_category = sub_categoryon;
  }, [sub_categoryon]);
  useEffect(() => {
    filterservice_type = service_typeon;
  }, [service_typeon]);
  useEffect(() => {
    filterno_of_downloads = no_of_downloadson;
  }, [no_of_downloadson]);
  useEffect(() => {
    filterrating = ratingon;
  }, [ratingon]);

  let filtercloud = [];
  let filterprovisioner = [];
  let filtercategory = [];
  let filtersub_category = [];
  let filterservice_type = [];
  let filterno_of_downloads = [];
  let filterrating = [];

  function updateStateData() {
    filtercloud = cloudon;
    filterprovisioner = provisioneron;
    filtercategory = categoryon;
    filtersub_category = sub_categoryon;
    filterservice_type = service_typeon;
    filterno_of_downloads = no_of_downloadson;
    filterrating = ratingon;
  }
  useEffect(() => {
    loadListdata();
  }, [updateChipData]);
  const ListItem = styled("li")(({ theme }) => ({
    margin: theme.spacing(0.5),
  }));

  const handleApplyFilter = (name) => {
    updateStateData();
    if (name === "cloud") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        cloud: false,
      });
    } else if (name === "provisioner") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        provisioner: false,
      });
    } else if (name === "category") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "sub_category") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        sub_category: false,
      });
    } else if (name === "service_type") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        service_type: false,
      });
    } else if (name === "no_of_downloads") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        no_of_downloads: false,
      });
    } else if (name === "rating") {
      loadChipdata();
      setIsOpen({
        ...isOpen,
        rating: false,
      });
    }

  };
  function loadChipdata() {
    //setUpdateChipData([]);
    let tempArr = [];
    // setUpdateChipData(status);
    filtercloud.map((obj) => {
      tempArr.push(obj);
    });
    filterprovisioner.map((obj) => {
      tempArr.push(obj);
    });
    filtercategory.map((obj) => {
      tempArr.push(obj);
    });
    filtersub_category.map((obj) => {
      tempArr.push(obj);
    });
    filterservice_type.map((obj) => {
      tempArr.push(obj);
    });
    filterno_of_downloads.map((obj) => {
      tempArr.push(obj);
    });
    filterrating.map((obj) => {
      tempArr.push(obj);
    });
    setUpdateChipData(tempArr);
  }


  const handleClear = (name) => {
    updateStateData();
    if (name === "cloud") {
      setCloudon([]);
      filtercloud = [];

      setIsOpen({
        ...isOpen,
        cloud: false,
      });
    } else if (name === "provisioner") {
      setProvisioneron([]);
      filterprovisioner = [];

      setIsOpen({
        ...isOpen,
        provisioner: false,
      });
    } else if (name === "category") {
      setCategoryon([]);
      filtercategory = [];

      setIsOpen({
        ...isOpen,
        category: false,
      });
    } else if (name === "sub_category") {
      setsub_Categoryon([]);
      filtersub_category = [];

      setIsOpen({
        ...isOpen,
        sub_category: false,
      });
    } else if (name === "service_type") {
      setService_typeon([]);
      filterservice_type = [];

      setIsOpen({
        ...isOpen,
        service_type: false,
      });
    } else if (name === "no_of_downloads") {
      setNo_of_downloadson([]);
      filterno_of_downloads = [];

      setIsOpen({
        ...isOpen,
        no_of_downloads: false,
      });
    } else if (name === "rating") {
      setRating([]);
      filterrating = [];

      setIsOpen({
        ...isOpen,
        rating: false,
      });
    }
  }

  const handleFilter = (e, boxName) => {
    if (boxName === "cloud") {
      setIsOpen((prevState) => ({
        ...prevState,
        cloud: !prevState.cloud,
      }));

    };
    if (boxName === "provisioner") {
      setIsOpen((prevState) => ({
        ...prevState,
        provisioner: !prevState.provisioner,
      }));
    }
    if (boxName === "category") {
      setIsOpen((prevState) => ({
        ...prevState,
        category: !prevState.category,
      }));
    }
    if (boxName === "sub_category") {
      setIsOpen((prevState) => ({
        ...prevState,
        sub_category: !prevState.sub_category,
      }));
    }
    if (boxName === "service_type") {
      setIsOpen((prevState) => ({
        ...prevState,
        service_type: !prevState.service_type,
      }));
    }
    if (boxName === "no_of_downloads") {
      setIsOpen((prevState) => ({
        ...prevState,
        no_of_downloads: !prevState.no_of_downloads,
      }));
    }
    if (boxName === "rating") {
      setIsOpen((prevState) => ({
        ...prevState,
        rating: !prevState.rating,
      }));
    }
  }

  const handleSearch = (event) => {
    // if (event.key === "Enter") {
    // 👇 Get input value
    // let val = event.target.value;
    // updateStateData();
    // setSearchTerm(val);
    // if (val.length > 0) {
    //   filterSearch = [{ name: "" + val, type: "search" }];
    // } else {
    //   filterSearch = [];
    // }
    // loadChipdata();
    // }
    if (event.key === "Enter") {
      let val = event.target.value;
      setSearchTerm(val); // Update the search term state
      getCloudList(); // Fetch user list based on the search term
    }
  };


  const handleCheckboxChange = (event, names, typename) => {
    const { value, checked, name } = event.target;
    console.log(name, "name........");
    console.log(value, "value........");
    if (names === "cloud") {
      if (checked) {
        setCloudon([...cloudon, { id: value, name: name, cloud: "cloud" }]);
        // setProvisioneron([...provisioneron, { id: value, name: name, provisioner: "provisioner" }]);
      } else {
        setCloudon(cloudon.filter((item) => item.id !== value));
        // setProvisioneron(provisioneron.filter((item) => item.id !== value));
      }
    }
    else if (names === "provisioner") {
      if (checked) {
        setProvisioneron([...provisioneron, { id: value, name: name, provisioner: "provisioner" }]);
      } else {
        setProvisioneron(provisioneron.filter((item) => item.id !== value));
      }
    }
    else if (names === "category") {
      if (checked) {
        setCategoryon([...categoryon, { id: value, name: name, category: "category" }]);
      } else {
        setCategoryon(categoryon.filter((item) => item.id !== value));
      }
    }
    else if (names === "sub_category") {
      if (checked) {
        setsub_Categoryon([...sub_categoryon, { id: value, name: name, sub_category: "sub_category" }]);
      } else {
        setsub_Categoryon(sub_categoryon.filter((item) => item.id !== value));
      }
    }
    else if (names === "service_type") {
      if (checked) {
        setService_typeon([...service_typeon, { id: value, name: name, service_type: "service_type" }]);
      } else {
        setService_typeon(service_typeon.filter((item) => item.id !== value));
      }
    }
    else if (names === "no_of_downloads") {
      if (checked) {
        setNo_of_downloadson([...no_of_downloadson, { id: value, name: name, no_of_downloads: "no_of_downloads" }]);
      } else {
        setNo_of_downloadson(no_of_downloadson.filter((item) => item.id !== value));
      }
    }
    else if (names === "rating") {
      if (checked) {
        setRatingon([...ratingon, { id: value, name: name, rating: "rating" }]);
      } else {
        setRatingon(ratingon.filter((item) => item.id !== value));
      }
    }
  };

  const handleAllClear = () => {
    setscript_list([]);
  };

  const handleSort = (sortKey) => {
    setSortOrder((prevSortOrder) => ({
      ...prevSortOrder,
      [sortKey]: prevSortOrder[sortKey] === "asc" ? "desc" : "asc",
    }));
    // Now you can call the sorting function with the updated sortOrder
    sortScriptList(sortKey, sortOrder[sortKey]);
  };
  const sortScriptList = (sortKey, order) => {
    let sortedList = [...script_list];

    sortedList.sort((a, b) => {
      if (sortKey === "no_of_downloads" || sortKey === "rating") {
        const valueA = a[sortKey];
        const valueB = b[sortKey];
        return order === "asc" ? valueA - valueB : valueB - valueA;
      } else {
        if (order === "asc") {
          return a[sortKey] < b[sortKey] ? -1 : 1;
        } else {
          return b[sortKey] < a[sortKey] ? -1 : 1;
        }
      }
    });

    setscript_list(sortedList);
  };

  const handleDelete = (chipToDelete, index) => () => {
    updateStateData();
    if (chipToDelete.cloud === "cloud") {
      setCloudon(cloudon.filter((item) => item.id !== chipToDelete.id));
      filtercloud = cloudon.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.provisioner === "provisioner") {
      setProvisioneron(provisioneron.filter((item) => item.id !== chipToDelete.id));
      filterprovisioner = provisioneron.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.category === "category") {
      setCategoryon(categoryon.filter((item) => item.id !== chipToDelete.id));
      filtercategory = categoryon.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.sub_category === "sub_category") {
      setsub_Categoryon(sub_categoryon.filter((item) => item.id !== chipToDelete.id));
      filtersub_category = sub_categoryon.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.service_type === "service_type") {
      setService_typeon(service_typeon.filter((item) => item.id !== chipToDelete.id));
      filterservice_type = service_typeon.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.no_of_downloads === "no_of_downloads") {
      setNo_of_downloadson(no_of_downloadson.filter((item) => item.id !== chipToDelete.id));
      filterno_of_downloads = no_of_downloadson.filter((item) => item.id !== chipToDelete.id);
    } else if (chipToDelete.rating === "rating") {
      setRatingon(ratingon.filter((item) => item.id !== chipToDelete.id));
      filterrating = ratingon.filter((item) => item.id !== chipToDelete.id);
    }
    loadChipdata();
  }

  const handlePrev = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNext = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  useEffect(() => {
    getCloudList();
  }, [currentPage, itemsPerPage]);
  const handlePageCount = (event) => {
    const newItemsPerPage = parseInt(event.target.value);
    setItemsPerPage(newItemsPerPage);
    setCurrentPage(1);
  };


  function getCloudList() {
    let tempcoludeon = [];
    cloudon.map((obj) => {
      tempcoludeon.push(obj.id);
    });
    let tempprovisioner = [];
    provisioneron.map((obj) => {
      tempprovisioner.push(obj.id);
    });
    let tempcategory = [];
    categoryon.map((obj) => {
      tempcategory.push(obj.id);
    });
    let tempsub_category = [];
    sub_categoryon.map((obj) => {
      tempsub_category.push(obj.id);
    });
    let tempservice_type = [];
    service_typeon.map((obj) => {
      tempservice_type.push(obj.id);
    });
    let tempno_of_downloads = [];
    no_of_downloadson.map((obj) => {
      tempno_of_downloads.push(obj.id);
    });
    let temprating = [];
    ratingon.map((obj) => {
      temprating.push(obj.id);
    });
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const data = {
      method: 3,
      cloud: cloudon.length > 0 ? tempcoludeon : "",
      provisioner: provisioneron.length > 0 ? tempprovisioner : "",
      category: categoryon.length > 0 ? tempcategory : "",
      sub_category: sub_categoryon.length > 0 ? tempsub_category : "",
      service_type: service_typeon.length > 0 ? tempservice_type : "",
      no_of_downloads: no_of_downloadson.length > 0 ? tempno_of_downloads : "",
      rating: ratingon.length > 0 ? temprating : "",
      search: searchTerm,
    };
    AxiosInstance.post("api/aam/script", data)
      .then((res) => {
        // console.log(res);
        if (res.data.status) {
          const dataList = res.data.data.slice(startIndex, endIndex);
          setscript_list(dataList);
          const uniqueCloudValues = new Set();
          dataList.forEach((item) => {
            uniqueCloudValues.add(item.cloud);
          });
          const uniqueCloudArray = Array.from(uniqueCloudValues);
          setCloud(uniqueCloudArray);

          const uniqueprovisionerValues = new Set();
          dataList.forEach((item) => {
            uniqueprovisionerValues.add(item.provisioner);
          });
          const uniqueprovisionerArray = Array.from(uniqueprovisionerValues);
          setProvisioner(uniqueprovisionerArray);

          const uniquecategoryValues = new Set();
          dataList.forEach((item) => {
            uniquecategoryValues.add(item.category);
          });
          const uniquecategoryArray = Array.from(uniquecategoryValues);
          setCategory(uniquecategoryArray);

          const uniquesub_categoryValues = new Set();
          dataList.forEach((item) => {
            uniquesub_categoryValues.add(item.sub_category);
          });
          const uniquesub_categoryArray = Array.from(uniquesub_categoryValues);
          setsub_Category(uniquesub_categoryArray);

          const uniqueservice_typeValues = new Set();
          dataList.forEach((item) => {
            uniqueservice_typeValues.add(item.service_type);
          });
          const uniqueservice_typeArray = Array.from(uniqueservice_typeValues);
          setService_type(uniqueservice_typeArray);

          const uniqueno_of_downloadsValues = new Set();
          dataList.forEach((item) => {
            uniqueno_of_downloadsValues.add(item.no_of_downloads);
          });
          const uniqueno_of_downloadsArray = Array.from(uniqueno_of_downloadsValues);
          setNo_of_downloads(uniqueno_of_downloadsArray);

          const uniqueratingValues = new Set();
          dataList.forEach((item) => {
            uniqueratingValues.add(item.rating);
          });
          console.log(uniqueCloudArray, "uniqueCloudArray");
          const uniqueratingArray = Array.from(uniqueratingValues);
          setRating(uniqueratingArray);

          setTotalRecords(res.data.data.length);
          setTotalPages(Math.ceil(res.data.data.length / itemsPerPage));
          // setscript_list(res.data.data);
        } else {
          setscript_list([]);
          setTotalRecords(0);
          setTotalPages(1);
        }
      })
      .catch((error) => {
        return error;
        setscript_list([]);
        setTotalRecords(0);
        setTotalPages(1);
      });
  }

  const handleView = (id) => {

    console.log(id, "id");
    navigate(`/dashboard/ViewScriptDetail/${id}`);
  }
  return (
    <div className="bodycontent script">
      <p className="topic_title">Script</p>
      <div className="table_parent_div">
        <div className="row">
          <div className="col-md-8">
            <div className="table_serach">
              <input
                type="text"
                placeholder="Search by Script Name"
                onKeyDown={handleSearch}
              />

              <SearchIcon className="table_serach_img" />
            </div>
          </div>
          <div className="col-md-4 d-flex justify-content-end">
            <button
              className="add_new me-3"
              style={{
                display: Users && Users.data.user_type == 3 ? "" : "none",
              }}
            >
              <img src={add} alt="add new" />
              <Link to="/dashboard/AddScript/0">Add New</Link>
            </button>
          </div>
        </div>
        {updateChipData.length > 0 ? (
          <div className="row mt-3">
            <div className="col-md-12 col-sm-12 col-xs-12 " id="paperil">
              <Paper
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  flexWrap: "wrap",
                  listStyle: "none",
                  p: 0.5,
                  m: 0,
                }}
                component="ul"
              >
                {updateChipData.map((data, index) => {
                  // console.log(data, " - data - ", index);
                  let icon;
                  let key = "" + data.name;
                  return (
                    <ListItem key={data}>
                      <Chip
                        icon={icon}
                        label={key}
                        onDelete={handleDelete(data, index)}
                      />
                    </ListItem>
                  );
                })}

                <ListItem>
                  {/* <Chip
                      label="clear all filter"
                      onClick={handleAllClear}
                    /> */}
                  <a className="allclear" href="./script" onClick={handleAllClear}>
                    clear all filter
                  </a>
                </ListItem>
              </Paper>
            </div>
          </div>
        ) : (
          <div></div>
        )}

        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12 table-responsive">
            <table className="table table-striped  table-hover" id="script">
              <thead>
                <tr>
                  <th id="th">
                    Script Name
                    <span
                      className="material-icons align-middle"
                      onClick={() => handleSort("script_name")}
                    >
                      {sortOrder["script_name"] === "asc"
                        ? "import_export"
                        : "import_export"}
                    </span>
                  </th>
                  <th id="th">
                    Description
                  </th>
                  <th id="th">
                    Cloud{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("cloud")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${cloudon.length > 0 ? "active_icons" : ""}`}
                      onClick={(e) => handleFilter(e, "cloud")}
                    >
                      filter_list
                    </span>
                    {isOpen.cloud === true && (
                      <div className="filter-popup">
                        <div className="row mt-2">
                          {cloud.map((el) => (
                            <div className="col-md-12">
                              <div class="filter-option">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  style={{ cursor: "pointer" }}
                                  checked={cloudon.some((element) => {
                                    if (element.name === el) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "cloud")
                                  }

                                />
                                <label
                                  class="form-check-label YellowText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clbutton "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("cloud")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8">
                            <button
                              className="apbutton"
                              disabled={cloudon.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("cloud")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    Provisioner{" "}
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("provisioner")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${provisioneron.length > 0 ? "active_icons" : ""}`}
                      onClick={(e) => handleFilter(e, "provisioner")}
                    >
                      filter_list
                    </span>
                    {isOpen.provisioner === true && (
                      <div className="filter-popup">
                        <div className="row mt-2">
                          {provisioner.map((el) => (
                            <div className="col-md-12">
                              <div class="filter-option">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  style={{ cursor: "pointer" }}
                                  checked={provisioneron.some((element) => {
                                    if (element.name === el) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "provisioner")
                                  }

                                />
                                <label
                                  class="form-check-label YellowText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clbutton "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("provisioner")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8">
                            <button
                              className="apbutton"
                              disabled={provisioneron.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("provisioner")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    Category
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("category")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${categoryon.length > 0 ? "active_icons" : ""}`}
                      onClick={(e) => handleFilter(e, "category")}
                    >
                      filter_list
                    </span>
                    {isOpen.category === true && (
                      <div className="filter-popup">
                        <div className="row mt-2">
                          {category.map((el) => (
                            <div className="col-md-12">
                              <div class="filter-option">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  style={{ cursor: "pointer" }}
                                  checked={categoryon.some((element) => {
                                    if (element.name === el) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "category")
                                  }

                                />
                                <label
                                  class="form-check-label YellowText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clbutton "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("category")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8">
                            <button
                              className="apbutton"
                              disabled={categoryon.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("category")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    Sub Category
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("sub_category")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${sub_categoryon.length > 0 ? "active_icons" : ""}`}
                      onClick={(e) => handleFilter(e, "sub_category")}
                    >
                      filter_list
                    </span>
                    {isOpen.sub_category === true && (
                      <div className="filter-popup">
                        <div className="row mt-2">
                          {sub_category.map((el) => (
                            <div className="col-md-12">
                              <div class="filter-option">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  style={{ cursor: "pointer" }}
                                  checked={sub_categoryon.some((element) => {
                                    if (element.name === el) {
                                      return true;
                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "sub_category")
                                  }

                                />
                                <label
                                  class="form-check-label YellowText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clbutton "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("sub_category")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8">
                            <button
                              className="apbutton"
                              disabled={sub_categoryon.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("sub_category")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    Versions
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("script_version")}
                    >
                      import_export
                    </span>
                  </th>
                  <th id="th">
                    Service Type
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("service_type")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${service_typeon.length > 0 ? "active_icons" : ""}`}
                      onClick={(e) => handleFilter(e, "service_type")}
                    >
                      filter_list
                    </span>
                    {isOpen.service_type === true && (
                      <div className="filter-popup">
                        <div className="row mt-2">
                          {service_type.map((el) => (
                            <div className="col-md-12">
                              <div class="filter-option">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  style={{ cursor: "pointer" }}
                                  checked={service_typeon.some((element) => {
                                    if (element.name === el) {
                                      // console.log(el,"element");
                                      return true;

                                    }
                                    return false;
                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "service_type")
                                  }

                                />
                                <label
                                  class="form-check-label YellowText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clbutton "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("service_type")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8">
                            <button
                              className="apbutton"
                              disabled={service_typeon.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("service_type")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th" style={{ width: "140px" }}>
                    Number of Downloads
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("no_of_downloads")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${no_of_downloadson.length > 0 ? "active_icons" : ""}`}
                      onClick={(e) => handleFilter(e, "no_of_downloads")}
                    >
                      filter_list
                    </span>
                    {isOpen.no_of_downloads === true && (
                      <div className="filter-popup">
                        <div className="row mt-2">
                          {no_of_downloads.map((el) => (
                            <div className="col-md-12">
                              <div class="filter-option">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  style={{ cursor: "pointer" }}
                                  checked={no_of_downloadson.some((element) => {
                                    if (element.name == el) {

                                      return true;
                                    }
                                    console.log(el, "element");
                                    return false;

                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "no_of_downloads")
                                  }

                                />
                                <label
                                  class="form-check-label YellowText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clbutton "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("no_of_downloads")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8">
                            <button
                              className="apbutton"
                              disabled={no_of_downloadson.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("no_of_downloads")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th">
                    Rating
                    <span
                      class="material-icons align-middle"
                      onClick={() => handleSort("rating")}
                    >
                      import_export
                    </span>
                    <span
                      class={` align-middle material-icons  ${ratingon.length > 0 ? "active_icons" : ""}`}
                      onClick={(e) => handleFilter(e, "rating")}
                    >
                      filter_list
                    </span>
                    {isOpen.rating === true && (
                      <div className="filter-popup">
                        <div className="row mt-2">
                          {rating.map((el) => (
                            <div className="col-md-12">
                              <div class="filter-option">
                                <input
                                  class="form-check-input"
                                  type="checkbox"
                                  name={el}
                                  value={el}
                                  id={el}
                                  style={{ cursor: "pointer" }}
                                  checked={ratingon.some((element) => {
                                    if (element.name == el) {

                                      return true;
                                    }
                                    console.log(el, "element");
                                    return false;

                                  })}
                                  onChange={(e) =>
                                    handleCheckboxChange(e, "rating")
                                  }

                                />
                                <label
                                  class="form-check-label YellowText"
                                  for="defaultCheck1"
                                >
                                  {el}
                                </label>
                              </div>
                            </div>
                          ))}

                        </div>
                        <hr />
                        <div className="row mt-3">
                          <div className="col-md-4">
                            <span
                              className="clbutton "
                              style={{ cursor: "pointer" }}
                              onClick={() => handleClear("rating")}
                            >
                              Clear
                            </span>
                          </div>
                          <div className="col-md-8">
                            <button
                              className="apbutton"
                              disabled={ratingon.length === 0 ? true : false}
                              onClick={() => handleApplyFilter("rating")}
                            >
                              Apply Filter
                            </button>
                          </div>
                        </div>
                      </div>
                    )}
                  </th>
                  <th id="th" style={{ width: "130px" }}></th>
                </tr>
              </thead>
              <tbody>
                {script_list &&
                  script_list.map((obj, index) => {
                    return (
                      <tr className="td_div_action">
                        <td>{obj.script_name}</td>
                        <td>{obj.description}</td>
                        <td>{obj.cloud}</td>
                        <td>{obj.provisioner}</td>
                        <td>{obj.category}</td>
                        <td>{obj.sub_category}</td>
                        <td>{obj.script_version}</td>
                        <td>{obj.service_type}</td>
                        <td>{obj.no_of_downloads}</td>
                        <td>
                          {/* <span class="material-icons-outlined td_rating_start small">
                            star
                          </span>
                          <span class="material-icons-outlined td_rating_start small">
                            star
                          </span>
                          <span class="material-icons-outlined td_rating_start small">
                            star
                          </span>
                          <span class="material-icons-outlined td_rating_start small">
                            star
                          </span> */}
                          <Rating rating={obj.rating} />
                        </td>
                        <td>
                          <div className="td_div_action_div">
                            <a href="javascript:void(0)" className="td_div_action_a">
                              <img onClick={() => handleView(obj.id)} src={tbl_view} />
                            </a>
                            <a href="#" className="td_div_action_a">
                              <img src={tbl_download} />
                            </a>
                            <a href="#" className="td_div_action_a">
                              <img src={tbl_upload} />
                            </a>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
              </tbody>
            </table>
          </div>
        </div>
        <div className="row mt-3 ms-1 me-1">
          <div className="col-md-12">
            <div className="pag w-100 d-flex justify-content-end">
              <span className="ipage">Items Per page:</span>
              <select
                name="pageCount"
                id="pageCount"
                value={itemsPerPage}
                onChange={handlePageCount}
                className="ms-2 select_drop"
              >
                {/* <option value="5">5</option> */}
                <option value="10">10</option>
                <option value="20">20</option>
                <option value="50">50</option>
                <option value="100">100</option>
              </select>
              <span className="pagenumber ms-2">
                {totalRecords === 0
                  ? "No records found"
                  : `${Math.min(
                    currentPage * itemsPerPage - itemsPerPage + 1,
                    totalRecords
                  )} - ${Math.min(currentPage * itemsPerPage, totalRecords)} of ${totalRecords}`}
              </span>
              <button className="prev_button ms-2 me-2" onClick={handlePrev} disabled={currentPage === 1}>
                <span class="material-icons">chevron_left</span>
              </button>
              <button className="prev_button" onClick={handleNext} disabled={currentPage === totalPages}>
                <span class="material-icons">chevron_right</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
